import React, { useEffect, useState, } from "react";
import "antd/dist/antd.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Api from "services/Api";

import Main from "./component/Main/Main"
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "component/store";
import useSelection from "antd/lib/table/hooks/useSelection";
import { Redirect, useHistory } from "react-router-dom/cjs/react-router-dom.min";

function App(props) {
  const dispatch = useDispatch();
  let params = new URL(window.location);
  const { token } = useSelector(state => state.auth);
  const history = useHistory();
  let a1 = params.search.slice(7, 20);
  let a2 = params.search.slice(25, 33);
  // console.log("ameer",window.location.pathname)
  //   useEffect(() =>{
  //     if(localStorage.getItem("control_token")){
  //       return ;
  //     }

  //     Api.get("/auth/signin")
  //     .params({token:a1,uid:a2})
  //     .send((response,error) =>{
  // if(response?.accessToken!==undefined)
  //  { 
  //   localStorage.setItem("control_token",response?.accessToken)
  //     // setToken(response?.accessToken)
  //     // props.history.push("/")
  //     window.location='/'


  //  }else{
  //  }
  //       // if(error!==undefined)
  //       // {
  //       //   window.open("https://www.brigosha.com","_self")
  //       //   return;
  //       // }

  //     })
  //   },[localStorage.getItem("control_token")])
  useEffect(() => {
    if (token) {
      Api.header(token);
    }
  }, [token])




  return (
    <>
      <Router>
        <Switch>
          <Route path="/">
            <Main></Main>
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
