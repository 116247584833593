import React, { useState, useEffect } from "react";
import classes from "./Login.module.css";
import Logo from "../../Images/Logo.svg";
import BrigoshaLogoblue from '../../Images/BrigoshaLogoblue.svg'
import { Button, Form, Input, Spin } from "antd";
import { useHistory } from "react-router-dom";
import Api from "services/Api";
import { useDispatch } from "react-redux";
import { authActions } from "component/store";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Sider from "./Sider";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import ControlImage from "../../Images/ControlImage.svg"
import { Col, Row, } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}
function BrigoshaLogin() {
    const { height, width } = useWindowDimensions();
    let history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const [loader, setLoader] = useState(true);
    // console.log("location  location location location location ",location);

    useEffect(() => {
        console.log("insideuseeffect");
        const urlParams = new URLSearchParams(window.location.search);
        const hash = urlParams.get('hash');
        console.log('window', window.location, hash, urlParams)
        if (hash) {
            setLoader(true);
            const hashArray = hash.split('_');
            const passwordToken = hashArray[0];
            const uid = hashArray[1];
            Api.post("/auth/forceLogin").params({ passwordToken: passwordToken, uid: uid }).send((res) => {
                console.log("log in", res)
                setLoader(false);
                if (res) {
                    dispatch(authActions.updateToken(res?.accessToken));
                    dispatch(authActions.updateUser(res?.user));
                    if (res?.user?.privilege === "SuperAdmin" || (res?.user?.privilege === "Admin" && res?.user?.platform === "Brigosha")) {
                        history.push("/");
                    }
                    else if (res?.user?.privilege === "Blogger" && res?.user?.platform === "Brigosha") {
                        history.push("/brigosha-blog");
                    }
                } else {
                    console.log('bad credentials!!!')
                }
            })
        }
        else {
            setLoader(false);
        }

    }, [])


    const SignInHandler = (val) => {
        console.log("sign in ", val)
        Api.post("/auth/login").params({ email: val?.email, password: val?.password }).send((res) => {
            console.log("log in", res)
            dispatch(authActions.updateToken(res?.accessToken));
            dispatch(authActions.updateUser(res?.user));
            if (res?.user?.privilege === "SuperAdmin" || (res?.user?.privilege === "Admin" && res?.user?.platform === "Brigosha")) {
                history.push("/");
            }
            else if (res?.user?.privilege === "Blogger" && res?.user?.platform === "Brigosha") {
                history.push("/brigosha-blog");
            }
        })
    }
    return (
        <>
            {loader ?
                <Row style={{ height: '100vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>

                : <>{
                    width > 700 && (<>
                        <div className={classes.loginContainer}>
                            <div className={classes.loginMain}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><img style={{ width: '5vw', height: '7vh' }} src={BrigoshaLogoblue} ></img></div>
                                <div style={{ font: "normal normal bold 26px/38px Open Sans", color: '#0086FF', width: '100%', display: 'flex', justifyContent: 'center' }}>Control Panel</div>
                                <div style={{ fontSize: '18px', fontFamily: 'sans-serif', fontWeight: '600', color: "#0086FF", padding: '5px', display: 'flex', justifyContent: 'center' }}> Customize Control and Conquer</div>
                                <div style={{ font: "normal normal bold 22px/36px Open Sans", }} >Sign In</div>
                                <div style={{ font: "normal normal normal 14px/22px Open Sans", color: "#B3B3B3", marginBottom: '16px' }}>Sign in with your data that you entered during your registration</div>
                                <Form layout="vertical" onFinish={SignInHandler}>
                                    <Form.Item label=<b>E-mail</b> name="email">
                                        <Input type="email" placeholder="enter email"></Input>
                                    </Form.Item>
                                    {/* <Form.Item label=<b>Phone</b> name="phone">
                    <Input placeholder="enter phone number"></Input>
                    </Form.Item> */}
                                    <Form.Item label=<b>Password</b> name="password">
                                        <Input.Password placeholder="enter password" required></Input.Password>
                                    </Form.Item>
                                    <Form.Item >
                                        <button style={{ border: 'none', width: '100%', marginTop: "16px" }} className="buttonopen" type="submit" >Sign In</button>
                                    </Form.Item>
                                </Form>
                                <div>
                                    <div className={classes.loginOptionDiv}>
                                        {/* <div>Don't have an account?</div> */}
                                        {/* <div onClick={()=>{history.push("/sign-up")}}>Sign up as Guest</div> */}
                                    </div>
                                    {/* <div>Forgot Password?</div> */}
                                </div>
                            </div>
                            <Sider></Sider>
                        </div>
                    </>)
                }
                    {width < 700 && (<>
                        <div style={{ padding: '15px' }}>
                            <div >
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><img style={{ width: '12vw', height: '8vh' }} src={BrigoshaLogoblue} ></img></div>
                                <div style={{ font: "normal normal bold 18px/30px Open Sans", color: '#0086FF', width: '100%', display: 'flex', justifyContent: 'center' }}>Control Panel</div>
                                <Row style={{ width: "100%", display: 'flex', justifyContent: 'center', }}> <div style={{ width: '38vw', height: '20vh', }}><img src={ControlImage} style={{ width: "100%", height: '100%' }} /></div></Row>
                                <div style={{ fontWeight: '600', width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>Access Brigosha Website</div>

                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', color: '#0086FF', fontSize: '13px' }}>Empowering users to effortlessly manage</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', color: '#0086FF', fontSize: '13px' }}> dynamic content and witness real-time updates  </div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', color: '#0086FF', fontSize: '13px' }}>on the website updates on the website</div>


                                <div style={{ font: "normal normal bold 16px/32px Open Sans", }} >Sign in</div>
                                <div style={{ fontSize: '13px', color: "#B3B3B3", marginBottom: '16px' }}>Sign in with your data that you entered during your registration</div>
                                <Form layout="vertical" onFinish={SignInHandler}>
                                    <Form.Item label=<b style={{ fontSize: '13px' }}>E-mail</b> name="email">
                                        <Input style={{ height: '5vh' }} type="email" placeholder="enter email"></Input>
                                    </Form.Item>

                                    <Form.Item label=<b style={{ fontSize: '13px' }}>Password</b> name="password">
                                        <Input.Password style={{ height: '5vh' }} placeholder="enter password" required></Input.Password>
                                    </Form.Item>
                                    <Form.Item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <button style={{ border: 'none', width: '40vw', marginTop: "16px", height: '30px' }} className="buttonopen" type="submit" >Sign In</button>
                                    </Form.Item>
                                </Form>
                                <div>
                                    <div className={classes.loginOptionDiv}>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.poweredBy}>
                                <div style={{ fontSize: '13px', color: "#B3B3B3" }}>Powered by</div>
                                <div>
                                    <div style={{ fontSize: '14px', }}>Brigosha Technologies</div>
                                </div>
                            </div>
                        </div>
                    </>)}
                </>
            }
        </>
    )
}
export default BrigoshaLogin;