import { Button, Col, Form, Input, Modal, Progress, Row, Table, Tree, Upload, message, Typography, Menu, Dropdown, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined, UploadOutlined, ApiOutlined, ArrowDownOutlined } from '@ant-design/icons';
import Api from "services/Api";
import { compose } from "redux";
import Notification from "services/Notification";
// import { doc } from "prettier";
import { CloseOutlined, ArrowLeftOutlined, EyeOutlined, CloudUploadOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { authActions } from "component/store";
import addIcon from "../../Images/addIcon.svg";
import trashIcon from "../../Images/trashIcon.svg";
// import Icon from "@ant-design/icons/lib/components/Icon";
import moreVertical from "../../Images/moreVertical.svg";
import trashnew from "../../Images/trashnew.svg";
import leftIcon from "../../Images/leftIcon.svg";
import chevronRight from "../../Images/chevronRight.svg";
import chevronsRight from "../../Images/chevronsRight.svg";
import classes from '../Styles.module.css';
import x from "../../Images/x.svg"

import { LoadingOutlined } from '@ant-design/icons';
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}
function Gallery() {
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    dispatch(authActions.updatePageHighlight(window.location.pathname))
    const [modal, setModal] = useState(false)
    const [edit, setEdit] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [editData, setEditData] = useState({})
    const [deleteData, setDeleteData] = useState({})
    const [deleteImg, setDeleteImg] = useState([]);
    const [deleteVideo, setDeleteVideo] = useState([])
    const [loader, setLoader] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [progress, setProgress] = useState(0);
    const [galleryData, setGalleryData] = useState([])
    const [image, setImage] = useState(true)
    const [fileUrl, setFileUrl] = useState([]);
    const [uploadedFileCount, setUploadedFileCount] = useState(0);
    const [fileList, setFileList] = useState(new Map());
    // const [fileList, setFileList] = useState([]);
    const [update, setUpdate] = useState(false);
    const [publish, setPublish] = useState(false);
    const [deleteDataList, setDeleteDataList] = useState([]);
    const [totalImagesCount, setTotalImagesCount] = useState(0);
    const [previewFile, setPreviewFile] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => {
        setShowModal(!showModal);
    };
    // const [image, setImage] = useState(true)
    const { Paragraph, Text } = Typography;
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    const handleFilePreview = (file) => {
        console.log("fileeeee", file)
        setPreviewFile(file);
        setPreviewVisible(true);
    }

    const handleChange = (info) => {
        setFileList((prevFileList) => {
            console.log("111100", prevFileList)
            const updatedFileList = [...prevFileList, ...info.fileList];
            console.log("1111", updatedFileList)
            const limitedFileList = updatedFileList.slice(-10); // Limit the number of files to 10
            return limitedFileList;
        });

        setUploadedFileCount((prevUploadedFileCount) => {
            const newUploadedFileCount = prevUploadedFileCount + info.fileList.length;
            return newUploadedFileCount <= 10 ? newUploadedFileCount : 10;
        });
    };


    // const handleRemove = (index) => {
    //     setFileList((prevFileList) => {
    //         const updatedFileList = [...prevFileList];
    //         updatedFileList.splice(index, 1);
    //         return updatedFileList;
    //     });
    //     setUploadedFileCount((prevUploadedFileCount) => prevUploadedFileCount - 1);

    // };

    const handleRemove = (index) => {
        setFileList((prevFileList) => {
            const updatedFileList = [...prevFileList];
            updatedFileList.splice(index, 1);
            return updatedFileList;
        });

        setUploadedFileCount((prevUploadedFileCount) => {
            const newUploadedFileCount = prevUploadedFileCount - 1;
            return newUploadedFileCount >= 0 ? newUploadedFileCount : 0;
        });
    };
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const handlePreview = (file) => {
        setPreviewImage(file.url || file.thumbUrl);
        setPreviewVisible(true);
    };

    const handleCancelPreview = () => {
        setPreviewVisible(false);
    };
    const props = {
        // action: '//jsonplaceholder.typicode.com/posts/',
        listType: 'picture',
        previewFile(file) {
            console.log('Your upload file:', file);
            return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
                method: 'POST',
                body: file,
            })
                .then(res => res.json())
                .then(({ thumbnail }) => thumbnail);
        },
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    useEffect(() => {
        // let pg=document.getElementById("progress")
        // if(pg){
        //     pg.style.display="none"
        // }

    }, [addModal])
    const removeHandler = (val, ee) => {
        console.log("val", val, "ee", ee)
    }
    const ChangeHandler = (val) => {
        console.log("ameeri", val);
        val?.fileList?.forEach(async (val, id) => {
            await getBase64(val?.originFileObj, (url) => {
                setFileUrl([...fileUrl, { url: url, name: val?.originFileObj?.name }]);
            });
        })
    }
    //    const getSpecificData=(id)=>{

    //     Api.get("/gallery/specificFieldData").params({eventId:id,isImage:false}).send((res,err)=>{
    //         console.log("shubham bsdkaaaa",res,err)
    //         setSpecificData(res)
    //     })
    //    }
    const blockSize = 1048576 * 20;
    let currentBlockNumber = null
    let fileId = null
    let totalBlocksCount = null
    let blockStart = null
    let blockEnd = null
    let fileSize = null
    const uuidv4 = (value, id) => {
        console.log("pppp", Math.floor(Math.random() * 1000) + value)
        // return Math.floor(Math.random() * 1000) + value;
        return value;
    }

    useEffect(() => {
        setLoader(true)
        Api.get("/gallery/allGalleryData").send((res, err) => {
            // console.log(res, err)
            console.log("images count:");
            res?.map((item) => {
                console.log("jjjjimagescount", item?.images?.length);
                let totalcount = item?.images?.length - 1;
                setTotalImagesCount(totalcount)
            });

            setGalleryData(res);
            setLoader(false)
            if (editData?.id) {
                res?.forEach((ele) => {
                    if (ele?.id === editData?.id) {
                        setEditData(ele)
                        return;
                    }
                })
            }
        })
    }, [toggle])

    const itemspop = (record) => (
        <Menu>
            <Menu.Item key="1">
                <div className="editBtn" onClick={() => { setEditData(record); setEdit(true) }}> <EditOutlined />Edit</div>
            </Menu.Item>
            <Menu.Item key="2">
                <div className="deleteBtn" onClick={() => { setDeleteData({ id: record?.id, delete: "all" }); setDeleteModal(true); }}>
                    <img src={trashnew} style={{ width: '15px', height: '15px' }} /> Delete</div>
            </Menu.Item>
        </Menu>
    )


    const ImageColumn = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: "image",
            width: '35vw',
            render: (text, record) => {
                return (
                    <div style={{ display: "flex", gap: "10px", alignItems: "center", width: '100%' }}>
                        <img src={record?.locationPath} style={{ width: "8vw", height: "12vh" }} ></img>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "22vw" }}>{record?.fileName}</Typography.Text>
                    </div >
                )
            }

        },
        {
            title: 'Size of file',
            dataIndex: 'file',
            key: 'file',
            render: (text, record) => {
                return (
                    <div>
                        {record?.filesize + " KB"}
                        {console.log("filesize", record)}</div>
                );
            }
        },
        {
            title: 'Upload Date',
            dataIndex: 'Upload Date',
            key: 'Upload Date',
            render: (text, record) => {
                return (
                    <div>
                        {moment(record?.updated_at).format("DD-MM-YYYY")}
                    </div>
                )
            }

        },
        {
            title: 'Publisher',
            dataIndex: 'Publisher',
            key: 'Publisher',
            render: (text, record) => {
                return (
                    <div>
                        {record?.user?.name}
                    </div>
                )
            }

        },
        // {
        //     title: 'Actions',
        //     dataIndex: 'actions',
        //     key: "actions",
        //     render: (text, record) => {

        //         return (
        //             <div  style={{'display':'flex','gap':'24px'}}>
        //                 {/* <div className="editBtn" style={{backgroundColor:"white",border:"1px solid #eeeeee",padding:"5px 10px",boxShadow:"0px 1px 4px rgba(0, 0, 0, 0.08)",borderRadius:"4px"}} > <EyeOutlined />Preview</div> */}
        //                 {/* <div className="divider"></div> */}
        //                 {/* <div className="deleteBtn" id="clickBtn" onClick={()=>{setDeleteData({id:editData?.id,delete:"selected"});setDeleteModal(true);setDeleteImg([record?.id])}} ><DeleteOutlined />Delete</div> */}
        //               </div>
        //         )
        //     }
        // }

    ];
    const VideoColumn = [
        {
            title: 'Video',
            dataIndex: 'video',
            key: 'video',
            width: '35vw',

            render: (text, record) => {
                return (
                    <div style={{ display: "flex", gap: "10px", alignItems: "center", width: '100%', height: '12vh', }}>
                        <div style={{ width: '25%', height: '100%', display: 'flex' }}> <video src={record?.locationPath} style={{ width: "100%", height: "100%" }} controls></video></div>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '70%', height: '100%', display: 'flex', alignItems: 'center' }}>{record?.fileName}</Typography.Text>
                    </div >
                )
            }

        },
        {
            title: 'Size of file',
            dataIndex: 'file',
            key: 'file',
            width: '8vw',
            render: (text, record) => {
                const fileSizeMB = record?.filesize ? (record.filesize / (1024 * 1024)).toFixed(2) + ' MB' : '';
                return (
                    <div >
                        {fileSizeMB}
                    </div>
                );
            }

        },
        {
            title: 'Upload Date',
            dataIndex: 'Upload Date',
            key: 'Upload Date',
            width: '10vw',
            render: (text, record) => {
                return (
                    <div >
                        {moment(record?.updated_at).format("DD-MM-YYYY")}
                    </div>
                )
            }

        },
        {
            title: 'Publisher',
            dataIndex: 'Publisher',
            key: 'Publisher',
            width: '8vw',
            render: (text, record) => {
                return (
                    <div >
                        {record?.user?.name}
                    </div>
                )
            }

        },
        // {
        //     title: 'Actions',
        //     dataIndex: 'actions',
        //     key: "actions",
        //     render: (text, record) => {

        //         return (
        //             <div  style={{'display':'flex','gap':'24px',}}  >
        //                 {/* <div className="editBtn" style={{backgroundColor:"white",border:"1px solid #eeeeee",padding:"5px 10px",boxShadow:"0px 1px 4px rgba(0, 0, 0, 0.08)",borderRadius:"4px"}} > <EyeOutlined />Preview</div> */}
        //                 {/* <div className="divider"></div> */}
        //                 {/* <div className="deleteBtn" id="clickBtn" onClick={()=>{setDeleteData({id:editData?.id,delete:"selected"});setDeleteModal(true);setDeleteImg([record?.id])}}  ><DeleteOutlined />Delete</div> */}
        //               </div>
        //         )
        //     }
        // }

    ];
    const columns = [
        {
            title: 'Event Name',
            dataIndex: 'Event Name',
            key: 'Event Name',
            width: '13vw',
            render: (text, record) => {
                return (
                    <Row style={{ width: '13vw', display: 'flex' }}>
                        {record?.title}
                    </Row>
                )
            }
        },
        // {
        //     title: 'Images',
        //     dataIndex: 'images',
        //     key: 'images',
        //     render: (text, record) => {
        //         return (
        //             <div style={{ 'display': 'flex', 'alignItems': 'center', gap: "5px", minWidth: '12vw', cursor: "pointer" }} onClick={() => { setEditData(record); setEdit(true) }} >
        //                 {record?.images?.map((val, index) => {
        //                     if (index < 2) {
        //                         return (
        //                             <img src={val.locationPath} style={{ width: "90px", height: '70px', borderRadius: "4px    ", }} key={index}></img>
        //                         )
        //                     }
        //                 }
        //                 )}
        //                 <div style={{ 'fontWeight': '600', width: 'max-content' }}>{(record?.images?.length > 1 ? `+ ${record?.images?.length - 1}` : null)}</div>
        //             </div>
        //         )
        //     }

        // },
        {
            title: 'Images',
            dataIndex: 'images',
            key: 'images',
            width: '27vw',
            render: (text, record) => {
                return (
                    <Row style={{ 'display': 'flex', 'alignItems': 'center', gap: "5px", width: '27vw' }} >
                        {record?.images?.map((val, index) => {
                            if (index < 4) {
                                return (
                                    <div style={{ width: '5vw', height: '10vh', }}> <img src={val.locationPath} style={{ width: "100%", height: '100%', borderRadius: '4px', }} key={index}></img></div>
                                )
                            }
                        }
                        )
                        }

                        {/* <div style={{ 'fontWeight': '600', marginLeft: '10px' }}>{(record?.images?.length > 2 ? `+ ${record?.images?.length - 2}` : null)}</div> */}
                        <div style={{ 'fontWeight': '600', marginLeft: '10px' }}>{(record?.images?.length > 4 ? `+ ${record?.images?.length - 4}` : null)}</div>


                    </Row >
                )
            }

        },
        {
            title: 'Videos',
            dataIndex: 'videos',
            key: 'videos',
            width: '30vw',
            render: (text, record) => {
                return (
                    <Row style={{ 'display': 'flex', 'alignItems': 'center', gap: "5px", width: '30vw', }} >
                        <Row style={{ display: 'flex', width: '90%' }}>
                            {record?.videos?.map((val, index) => {
                                if (index < 4) {
                                    return (
                                        <div style={{ width: '85px', height: '65px', borderRadius: '4px', position: 'relative' }}>
                                            <PlayCircleOutlined style={{ position: "absolute", top: "50%", left: '50%', fontSize: "20px", color: "gray", transform: 'translate(-50%,-50%)' }} />
                                            <video src={val.locationPath} style={{ width: "98%", height: '100%', borderRadius: '4px', objectFit: 'none', }} key={index} controls={false}></video>
                                        </div>
                                    )
                                }
                            }
                            )}

                            <div style={{ 'fontWeight': '600', marginLeft: '10px', width: '4vw' }}>{(record?.videos?.length > 4 ? `+ ${record?.videos?.length - 4}` : null)}</div>
                        </Row>
                        <Row style={{ display: 'flex', width: '5%', justifyItems: 'end', alignItems: 'center' }}>
                            <Dropdown overlay={itemspop(record)} trigger={["click"]} >
                                <div style={{ color: '#707070', display: 'flex', alignItems: 'center' }}>
                                    <img src={moreVertical} style={{ width: '15px', height: '15px', cursor: "pointer", color: "#707070" }} alt="More Vertical" />
                                </div>
                            </Dropdown>
                        </Row>
                    </Row>
                )
            }

        },

    ];
    const deleteHandler = (val) => {
        console.log("jjjdeleteHandler", val, deleteData)
        let id = deleteData?.id;
        if (deleteData?.delete === "all") {
            console.log("jjjdeleteHandlerALL", val, deleteData)
            Api.delete(`/gallery/deleteImage?id=${id}`).send((res) => {
                // console.log(res)
                setToggle(!toggle)
                setDeleteModal(false)
                setDeleteImg([]);
            })
        } else if (deleteData?.delete === "selected") {
            console.log("jjjdeleteHandlerSELECTED", val, deleteData)
            let ids = { pics: deleteImg }
            console.log("mai gareeb hoo", ids)
            Api.delete(`/gallery/deleteImage?id=${editData?.id}`).params(ids).send((res) => {
                // console.log(res)
                setToggle(!toggle)
                setDeleteModal(false)
                setDeleteImg([]);
            })
        }
    }

    const uploadBlock2 = (params, callback) => {
        setLoader(true);
        const blockUploadUrl = `/gallery/uploadChunk`;
        console.log("parameter beofre upload api", params)
        return new Promise((resolve, reject) => {
            Api.post(blockUploadUrl).upload(params, (percent, response) => {
                if (percent === -1) {
                    console.log("when percent is -1", percent)
                }


                if (response !== undefined) {
                    console.log("when response is not undefined", percent);
                    if (percent === 100) {
                        callback(response);
                        resolve();
                        setLoader(false);
                    }
                }

            });
        });
    }
    const MergeChunk = (fileId, callback, succesCallback) => {
        let params = {
            fileName: fileId,
            eventId: editData?.id,
            fileSize: fileSize
        }
        console.log('object', params)
        Api.post("/gallery/MergeChunks")
            .params(params)
            .send((response) => {
                if (response != undefined) {
                    setProgress(0)
                    if (response?.fileKey) {
                        setAddModal(false);
                        setToggle(!toggle);
                    } else {
                        console.log("merge chunk 2222")
                        if (callback) {
                            callback();
                            // }
                        }
                    }
                    Notification.success("Success", image ? "Images Added Successfully" : "Video Added Successfully")
                }
            });
    };

    const uploadBlock = async (currentBlock, callback, successCallback) => {
        console.log("jjj", currentBlock)
        try {
            var params = {
                file: currentBlock,
                orderId: currentBlockNumber,
                fileName: fileId
            }
            console.log("current block number ", currentBlockNumber, "params", params);
            await uploadBlock2(params, async (response) => {
                console.log("response in ")
                if (response != undefined) {
                    console.log("resData", response);
                    if (response?.data?.progress?.message === "uploaded") {
                        blockStart = blockEnd;
                        blockEnd += blockSize;

                        console.log("block uploaded", currentBlockNumber, totalBlocksCount);
                        if (currentBlockNumber === (totalBlocksCount)) {
                            console.log("mergeChunk")
                            var percent = Math.round((currentBlockNumber / totalBlocksCount) * 100);
                            // setProgress(percent)
                            MergeChunk(fileId, callback, successCallback)

                        } else {
                            var percent = Math.round((currentBlockNumber / totalBlocksCount) * 100);

                            // setProgress(percent)
                        }
                    }
                }
            })
            // const response = await axios.post(
            //     blockUploadUrl,formData, {headers: { 'Content-Type': 'multipart/form-data' }}
            // );
        } catch (error) {
            console.log("error in block upload", error);
        }
    }

    const uploadVideo = async (videoFile, callback, successCallback) => {
        console.log("video file", videoFile);
        // document.getElementById("progress").style.display="inline"
        if (videoFile?.type?.slice(0, 5) !== "video") {
            console.log("it's not video");
        }
        fileSize = videoFile?.size;
        console.log('aaa', fileSize)
        totalBlocksCount = Math.ceil(videoFile?.size / blockSize);
        fileId = uuidv4(videoFile?.name);
        console.log("llll",)
        blockStart = 0;
        blockEnd = blockSize;
        console.log("blockSize", blockSize, "total size", videoFile?.size, "total block count", totalBlocksCount, "fileId", fileId)
        for (currentBlockNumber = 1; currentBlockNumber <= totalBlocksCount; currentBlockNumber += 1) {
            var block = videoFile.slice(blockStart, blockEnd);
            await uploadBlock(block, callback, successCallback);
        }
    }

    const uploadImage = (imgFile) => {
        // document.getElementById("progress").style.display="inline"
        Api.patch(`/gallery/uploadImages?id=${editData?.id}`).uploadAndPatch({ files: imgFile }, (per, res) => {
            //    setProgress(per)
            if (per === 100 && res !== undefined) {
                setAddModal(false)
                setToggle(!toggle)
            }
        })
    }

    const addHandler = async (val, event) => {
        setUploadButtonDisabled(true);
        try {
            console.log("jjj111, val", val?.uploadFile?.fileList)
            let data = {};
            val?.uploadFile?.fileList.forEach((val) => {
                console.log("juneee", val?.type)
                if (!(val?.type === "image/jpeg" || val?.type === "image/png" || val?.type === "video/mp4")) {
                    console.log("jjjimg", val?.type)
                    Notification.error("error", `Please upload ${image ? 'jpg/png' : 'video'} file only`);
                    throw new Error(`Invalid file type. Please upload ${image ? 'jpg/png' : 'video'} file only.`);
                }
            });

            if (image === false) {
                console.log("video add", val);
                val?.uploadFile?.fileList?.forEach(video => {
                    uploadVideo(video?.originFileObj);
                });
            } else if (image === true) {
                let files = [];
                val?.uploadFile?.fileList.forEach((val) => {
                    return files.push(val?.originFileObj);
                });
                console.log("image add", val, files);
                uploadImage(files);
            }

            document.getElementById('editForm').reset();
            setUploadButtonDisabled(false);
            setAddModal(false)
        } catch (error) {
            setUploadButtonDisabled(false);
            console.error(error);
        }
    };

    return (
        <div className="container">
            {edit ? <>
                {width > 700 &&
                    <div className="editGallery-container">
                        <div className="editGallery-heading">
                            <img style={{ width: '30px', height: '30px', cursor: 'pointer', }} src={leftIcon} onClick={() => { setEdit(false) }}></img>
                            <b style={{ fontFamily: 'inherit', fontSize: '20px', }}>All Media Files </b>
                            <div style={{ fontFamily: 'inherit', fontSize: '18px' }}>
                                <img style={{ width: '40px', height: '30px', }} src={chevronsRight} />
                                <b>{editData?.title}</b>
                            </div>
                        </div>

                        <div className="editGallery-tab-container">
                            <div className="editGallery-tab">
                                <div onClick={() => { setImage(true) }} style={{ color: image ? "#0086FF" : "#000000", }}>All Images</div>
                                <div onClick={() => { setImage(false) }} style={{ color: image ? "#000000" : "#0086FF", }} >All Videos</div>
                            </div>

                            <div style={{ display: "flex", gap: '5px' }}>
                                <Button className="deleteButton" style={{ backgroundColor: "red", color: 'white', width: "fit-content", height: '2vh', width: "100%", padding: "18px", font: "normal normal bold 16px/22px Open Sans" }}
                                    disabled={deleteImg.length > 0 || deleteVideo.length > 0 ? false : true}
                                    onClick={() => {
                                        setDeleteData({ id: editData?.id, delete: "selected" }); setDeleteModal(true);
                                    }}
                                >
                                    DELETE
                                </Button>
                                <Button
                                    className="addbtn"
                                    onClick={() => { setAddModal(true); }}
                                >
                                    ADD {image ? "IMAGE" : "VIDEO"}
                                </Button>
                            </div>
                        </div>
                        <div >
                            {
                                image ? (
                                    <div className="Imagesdiv" style={{ height: '70vh' }}>
                                        <input
                                            type="checkbox"
                                            checked={deleteImg.length === editData?.images.length}
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                if (checked) {
                                                    const allImageIds = editData?.images.map((image) => image.id);
                                                    setDeleteImg(allImageIds);
                                                } else {
                                                    setDeleteImg([]);
                                                }
                                            }}
                                        />
                                        <span style={{ marginLeft: '8px', fontWeight: '500', fontFamily: 'inherit' }}>Select All Images</span>
                                        <Row className={classes.DashboardListRowGallery}>
                                            <Col style={{ width: '59%', marginLeft: '15px', marginRight: '5px' }} className={classes.Heading}>
                                                Images
                                            </Col>
                                            <Col style={{ width: '18%', marginRight: '14px' }} className={classes.Heading}>
                                                File Size
                                            </Col>
                                            <Col style={{ width: '18%', }} className={classes.Heading}>Publisher</Col>
                                            {loader ? (
                                                <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (
                                                <>
                                                    {editData?.images.map((image, index) => (
                                                        <Col className={classes.DashboardMapDivGallery} key={index}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <input type="checkbox" checked={deleteImg.includes(image.id)}
                                                                    onChange={(e) => {
                                                                        const checked = e.target.checked;
                                                                        if (checked) {
                                                                            setDeleteImg((prev) => [...prev, image.id]);
                                                                        } else {
                                                                            setDeleteImg((prev) => prev.filter((id) => id !== image.id));
                                                                        }
                                                                    }} />

                                                            </div>
                                                            <Row style={{ width: '60%', height: '100%', display: 'flex', alignItems: 'center', gap: '6px', marginRight: '10px', marginLeft: '10px' }}>
                                                                <Col style={{ width: '45px', height: '45px', display: 'flex', marginRight: '10px' }}>
                                                                    <img src={image?.locationPath} style={{ width: "100%", height: '100%', borderRadius: '5px' }} />
                                                                </Col>
                                                                <Col style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '85%' }}>
                                                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ fontFamily: 'inherit', fontWeight: '600', maxWidth: "90%", }}>{image?.fileName}</Typography.Text>
                                                                    {/* <b style={{ display: 'flex' }}>{image?.fileName}</b> */}
                                                                    <div style={{ display: 'flex', color: '#7D7D7D', fontFamily: 'inherit', fontWeight: '500' }}>Upload Date: {moment(image?.updated_at).format("DD-MM-YYYY")}</div>
                                                                </Col>
                                                            </Row>

                                                            <Row style={{ width: '18%', marginLeft: '10px', display: 'flex', marginRight: '20px', alignItems: "center" }}>
                                                                <div style={{ fontWeight: '500' }}>{image?.filesize} KB</div>
                                                            </Row>

                                                            <Row style={{ width: '18%', marginLeft: '10px', display: 'flex', marginRight: '20px', alignItems: "center" }}>
                                                                <div style={{ fontWeight: '500' }}>{image?.user?.name}</div>
                                                            </Row>
                                                        </Col>
                                                    ))}
                                                </>)}
                                        </Row >

                                    </div>) : (
                                    <div className="Videosdiv" style={{ height: '70vh', width: '100%', }} >
                                        <input
                                            type="checkbox"
                                            checked={deleteVideo.length === editData?.videos.length && deleteImg.length === 0} // Check if all videos are selected and no images are selected
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                if (checked) {
                                                    const allVideoIds = editData?.videos.map((video) => video.id);
                                                    setDeleteVideo(allVideoIds);
                                                    console.log("jjjALLselect", allVideoIds)
                                                    setDeleteDataList(allVideoIds);
                                                    setDeleteImg([]);
                                                } else {
                                                    setDeleteVideo([]);
                                                    setDeleteDataList([])
                                                }
                                            }}
                                        />
                                        <span style={{ marginLeft: '8px', fontWeight: '500', fontFamily: 'inherit' }}>Select All Videos</span>
                                        <Row className={classes.DashboardListRowGallery}>
                                            <Col style={{ width: '59%', marginLeft: '15px', marginRight: '9px' }} className={classes.Heading}>
                                                Videos
                                            </Col>
                                            <Col style={{ width: '18%', marginRight: '11px' }} className={classes.Heading}>
                                                File Size
                                            </Col>
                                            <Col style={{ width: '18%', }} className={classes.Heading}>Publisher</Col>
                                            {loader ? (
                                                <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (

                                                <>
                                                    {editData?.videos.map((video, index) => (
                                                        <Col className={classes.DashboardMapDivGallery} key={index}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={deleteVideo.includes(video.id)}
                                                                    onChange={(e) => {
                                                                        const checked = e.target.checked;
                                                                        if (checked) {
                                                                            setDeleteVideo((prev) => [...prev, video.id]);
                                                                            setDeleteDataList((prev) => [...prev, video.id])
                                                                            setDeleteImg((prev) => [...prev, video.id])
                                                                            console.log("jjjINDselect", video?.id)
                                                                        } else {
                                                                            console.log("jjjINDselectelse", video?.id)
                                                                            setDeleteVideo((prev) => prev.filter((id) => id !== video.id));
                                                                            setDeleteDataList((prev) => prev.filter((id) => id !== video.id))
                                                                            setDeleteImg((prev) => prev.filter((id) => id !== video.id))
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <Row style={{ width: '60%', height: '100%', display: 'flex', alignItems: 'center', gap: '6px', marginRight: '10px', marginLeft: '10px' }}>
                                                                <Col style={{ width: '45px', height: '45px', display: 'flex', marginRight: '10px' }}>
                                                                    <video src={video?.locationPath} style={{ width: "100%", height: "100%", borderRadius: "5px" }} controls></video>
                                                                </Col>
                                                                <Col style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '85%' }}>
                                                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ fontFamily: 'inherit', fontWeight: '600', maxWidth: "90%", }}>{video?.fileName}</Typography.Text>
                                                                    {/* <b style={{ display: 'flex' }}>{video?.fileName}</b> */}
                                                                    <div style={{ display: 'flex', color: '#7D7D7D', fontFamily: 'inherit', fontWeight: '500' }}>Upload Date : {moment(video?.updated_at).format("DD-MM-YYYY")}</div>
                                                                </Col>

                                                            </Row>
                                                            <Row style={{ width: '18%', marginLeft: '10px', display: 'flex', marginRight: '20px', alignItems: "center" }}>
                                                                <div style={{ fontWeight: '500' }}>{video?.filesize ? (video.filesize / (1024 * 1024)).toFixed(2) + ' MB' : ''}</div>
                                                            </Row>
                                                            <Row style={{ width: '18%', marginLeft: '10px', display: 'flex', marginRight: '20px', alignItems: "center" }}>
                                                                <div style={{ fontWeight: '500' }}>{video?.user?.name}</div>
                                                            </Row>
                                                        </Col>
                                                    ))}</>)}
                                            {console.log("1122", editData?.videos)}
                                        </Row>
                                    </div>
                                )
                            }

                        </div>

                    </div>}

                {width < 700 &&
                    <Row style={{ width: '100%', display: 'flex', height: '85vh', }}>
                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ width: '76%', display: 'flex', marginLeft: '-9px' }}>
                                <img style={{ width: '25px', height: '25px', cursor: 'pointer', display: 'flex', alignItems: 'center' }} src={leftIcon} onClick={() => { setEdit(false) }}></img>
                                <Typography.Text ellipsis={{ tooltip: true }} style={{ fontFamily: 'poppins', fontSize: '16px', fontWeight: '600', maxWidth: '85%' }}>{editData?.title} </Typography.Text >
                            </div>
                            <Button style={{ height: '40%' }} className="addnewbtngallery" onClick={toggleModal} >  ADD {image ? "IMAGE" : "VIDEO"}   </Button>

                        </div>
                        <div className="editGallery-tabMob">
                            <div style={{ width: '60%', display: 'flex', gap: '10px' }}>
                                <div onClick={() => { setImage(true) }} style={{ color: image ? "#0086FF" : "#000000", fontFamily: 'poppins', marginBottom: '5px', fontWeight: '600' }}>All Images</div>
                                <div onClick={() => { setImage(false) }} style={{ color: image ? "#000000" : "#0086FF", fontFamily: 'poppins', marginBottom: '5px', fontWeight: '600' }} >All Videos</div>
                            </div>
                            <div style={{ width: '40%', display: 'flex', justifyContent: 'flex-end', }}>
                                <Button style={{ height: '40%', font: 'normal normal bold 12px/16px Open Sans', backgroundColor: "#FF6969", color: 'white', width: "25vw", padding: "4vw 4vw", justifyContent: 'center', display: 'flex', alignItems: 'center', borderRadius: '4px' }} disabled={deleteImg.length > 0 || deleteVideo.length > 0 ? false : true} onClick={() => { setDeleteData({ id: editData?.id, delete: "selected" }); setDeleteModal(true); }} > DELETE </Button>
                            </div>
                        </div>
                        <div style={{}}>
                            {
                                image ? (
                                    <div className="Imagesdiv" style={{ height: '70vh', width: '92vw' }}>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', height: '7%', alignItems: 'center', }}>
                                            <div style={{ width: '100%', display: 'flex' }}>
                                                <input style={{ marginLeft: '10px', }}
                                                    type="checkbox"
                                                    checked={deleteImg.length === editData?.images.length}
                                                    onChange={(e) => {
                                                        const checked = e.target.checked;
                                                        if (checked) {
                                                            const allImageIds = editData?.images.map((image) => image.id);
                                                            setDeleteImg(allImageIds);
                                                        } else {
                                                            setDeleteImg([]);
                                                        }
                                                    }}
                                                />

                                                <span style={{ marginLeft: '21px', fontWeight: '500', fontFamily: 'poppins' }}>Select All</span>
                                            </div>
                                        </div>
                                        <Row className={classes.DashboardListRowGalleryMob}>
                                            {loader ? (
                                                <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (
                                                <Row style={{ width: '100%', display: 'flex', height: '100%', }}>
                                                    {editData?.images.map((image, index) => (
                                                        <div className={classes.GalleryDivMobile} key={index}>
                                                            <div style={{ display: 'flex', width: '100%' }}>
                                                                <Col style={{ display: 'flex', alignItems: 'center', width: '10%' }}>
                                                                    <input type="checkbox" checked={deleteImg.includes(image.id)}
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            if (checked) {
                                                                                setDeleteImg((prev) => [...prev, image.id]);
                                                                            } else {
                                                                                setDeleteImg((prev) => prev.filter((id) => id !== image.id));
                                                                            }
                                                                        }} />

                                                                </Col>
                                                                <Col style={{ width: '90%', display: 'flex', alignItems: 'center', gap: '35px', height: '40%', marginBottom: '5px' }}>
                                                                    <Col style={{ width: '10%', height: '100%' }}>
                                                                        <img src={image?.locationPath} style={{ width: '50px', height: '50px', borderRadius: '5px' }} />
                                                                    </Col>
                                                                    <Col style={{ width: '80%', height: '100%' }}>
                                                                        <Typography.Text ellipsis={{ tooltip: true }} style={{ fontFamily: 'poppins', fontWeight: '600', maxWidth: "90%" }}>
                                                                            {image?.fileName} ({image?.filesize} KB){console.log("44444444", image?.fileName)}
                                                                        </Typography.Text>
                                                                        <div style={{ display: 'flex', color: '#7D7D7D', fontFamily: 'poppins', fontWeight: '500' }}>Upload Date: {moment(image?.updated_at).format("DD-MM-YYYY")}</div>
                                                                    </Col>
                                                                </Col>
                                                            </div>

                                                            <div style={{ marginLeft: '28px', marginTop: '7px', height: '20%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                                                <div style={{ fontWeight: '500', fontFamily: 'poppins' }}>Publisher - </div><div style={{ fontFamily: 'poppins', color: '#7D7D7D', fontWeight: '500' }}>{image?.user?.name}</div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Row>)}

                                        </Row >

                                    </div>) : (
                                    <div className="Videosdiv" style={{ height: '70vh', width: '92vw' }} >
                                        <input style={{ marginLeft: '10px', }}
                                            type="checkbox"
                                            checked={deleteVideo.length === editData?.videos.length && deleteImg.length === 0}
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                if (checked) {
                                                    const allVideoIds = editData?.videos.map((video) => video.id);
                                                    setDeleteVideo(allVideoIds);
                                                    console.log("jjjALLselect", allVideoIds)
                                                    setDeleteDataList(allVideoIds);
                                                    setDeleteImg([]);
                                                } else {
                                                    setDeleteVideo([]);
                                                    setDeleteDataList([])
                                                }
                                            }}
                                        />
                                        <span style={{ marginLeft: '21px', fontWeight: '500', fontFamily: 'poppins', }}>Select All </span>
                                        <Row className={classes.DashboardListRowGalleryMob}>
                                            {loader ? (
                                                <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (
                                                <Row style={{ width: '100%', display: 'flex', height: '100%', }}>
                                                    {editData?.videos.map((video, index) => (
                                                        <div className={classes.DashboardDivMobile} key={index}>
                                                            <div style={{ display: 'flex', width: '100%' }}>
                                                                <Col style={{ display: 'flex', alignItems: 'center', width: '10%' }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={deleteVideo.includes(video.id)}
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            if (checked) {
                                                                                setDeleteVideo((prev) => [...prev, video.id]);
                                                                                setDeleteDataList((prev) => [...prev, video.id])
                                                                                setDeleteImg((prev) => [...prev, video.id])
                                                                                console.log("jjjINDselect", video?.id)
                                                                            } else {
                                                                                console.log("jjjINDselectelse", video?.id)
                                                                                setDeleteVideo((prev) => prev.filter((id) => id !== video.id));
                                                                                setDeleteDataList((prev) => prev.filter((id) => id !== video.id))
                                                                                setDeleteImg((prev) => prev.filter((id) => id !== video.id))
                                                                            }
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col style={{ width: '90%', display: 'flex', alignItems: 'center', gap: '15px', height: '45%', marginBottom: '5px' }}>
                                                                    <div style={{ position: 'relative', width: '65px', borderRadius: '4px', marginRight: '5px', height: '8vh', cursor: 'pointer' }}>
                                                                        <PlayCircleOutlined style={{ position: "absolute", top: "50%", left: '50%', fontSize: "20px", color: "gray", transform: 'translate(-50%,-50%)' }} />
                                                                        <video src={video?.locationPath} style={{ width: "98%", borderRadius: '4px', objectFit: 'cover', marginRight: '5px', height: '100%' }} onClick={() => handleFilePreview(video?.locationPath)} ></video>
                                                                    </div>
                                                                    {/* <Col style={{ width: '50px', height: '50px' }}>
                                                                            <video src={video?.locationPath} style={{ width: "50px", height: "50px", borderRadius: "5px" }} onClick={() => handleFilePreview(video?.locationPath)}></video>
                                                                        </Col> */}
                                                                    {previewVisible && (
                                                                        <Modal open={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                                                            {/* {previewFile && <iframe title="Preview" style={{ width: '98%', height: '80vh' }} src={previewFile} />} */}
                                                                            {previewFile && <video title="Preview" style={{ width: '98%', height: '50vh' }} controls>
                                                                                <source src={previewFile} type="video/mp4" />
                                                                            </video>}
                                                                        </Modal>
                                                                    )}
                                                                    <Col style={{ width: '75%', height: '100%' }}>
                                                                        <Paragraph style={{ fontWeight: '600', fontFamily: 'poppins', fontSize: '14px' }} ellipsis={true ? { rows: 2, symbol: '...', fontSize: 16, } : false}>{video?.fileName}   {video?.filesize ? ` (${(video.filesize / (1024 * 1024)).toFixed(2)} MB)` : ''}</Paragraph>
                                                                        <div style={{ display: 'flex', color: '#7D7D7D', fontFamily: 'poppins', fontWeight: '500' }}> Upload Date : {moment(video?.updated_at).format("DD-MM-YYYY")} </div>
                                                                    </Col>
                                                                </Col>
                                                            </div>

                                                            <div style={{ marginLeft: '28px', marginTop: '7px', height: '20%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                                                <div style={{ fontWeight: '600', fontFamily: 'poppins' }}>Publisher - </div><div style={{ fontFamily: 'poppins', color: '#7D7D7D', fontWeight: '500' }}>{video?.user?.name}</div>
                                                            </div>
                                                        </div>
                                                    ))}</Row>)}
                                            {console.log("1122", editData?.videos)}
                                        </Row>
                                    </div>
                                )
                            }

                        </div>
                    </Row>

                    // <Col style={{ width: '100vw', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', height: '10%', gap: '5px' }}>
                    //     <Button className="deleteButton" style={{ backgroundColor: "#FF6969", color: 'white', width: "40%", height: '100%', padding: "5vw 4vw", justifyContent: 'center' }} disabled={deleteImg.length > 0 || deleteVideo.length > 0 ? false : true} onClick={() => { setDeleteData({ id: editData?.id, delete: "selected" }); setDeleteModal(true); }} >
                    //         DELETE
                    //     </Button>
                    //     <Button className="addnewbtn" onClick={toggleModal} >  ADD {image ? "IMAGE" : "VIDEO"}   </Button>
                    // </Col> 
                }
            </> :
                <>
                    {width > 700 && (
                        <Row className="firstRowDash">
                            <Col style={{ width: '45%', }} >
                                <Row>
                                    <Col><div style={{ fontSize: "24px", fontWeight: 'bolder', marginBottom: '10px', fontFamily: 'inherit' }}> Gallery <div></div></div> </Col>
                                </Row>
                            </Col>
                        </Row>)}
                    {width > 700 && (
                        <div>
                            <Row className={classes.DashboardListRowfit}>
                                <Row className="HeadingRow" style={{ backgroundColor: '#F8F8F8', width: '100%', display: 'flex', position: 'sticky', top: '0', zIndex: '1', }}>
                                    <Col style={{ width: '26%', marginLeft: '15px', marginRight: '5px' }} className={classes.Heading}>
                                        Event Name
                                    </Col>
                                    <Col style={{ width: '36%', }} className={classes.Heading}>
                                        Images
                                    </Col>
                                    <Col style={{ width: '22%', }} className={classes.Heading}>Videos</Col>
                                </Row>
                                {loader ? (
                                    <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (

                                    <Row style={{ width: '100%', display: 'flex' }}>
                                        {galleryData?.map((data, index) => {
                                            return (
                                                <div className={classes.DashboardMapDivGallery} key={index}>
                                                    <Col style={{ marginLeft: '5px', display: 'flex', width: '25%', marginRight: '20px', alignItems: 'center' }}>
                                                        <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "90%", fontWeight: '600' }}> {data?.title}</Typography.Text>
                                                    </Col>

                                                    <Row style={{ width: '35%', marginLeft: '10px', display: 'flex', marginRight: '20px' }}>
                                                        {data?.images?.map((val, index) => {
                                                            if (index < 3) {
                                                                return (
                                                                    <div style={{ width: '4vw', height: '100%', marginRight: '5px' }}> <img src={val.locationPath} style={{ width: "100%", height: '100%', borderRadius: '4px', }} key={index}></img></div>
                                                                )
                                                            }
                                                            else if (index === 3 && data?.images?.length > 3) {
                                                                const remainingImages = data.images.length - 4;
                                                                return (
                                                                    <div style={{ position: 'relative', display: 'inline-block', width: '4vw', height: '100%' }}>
                                                                        <img src={val.locationPath} alt="" style={{ width: '4vw', height: '100%', borderRadius: '5px', filter: 'brightness(45%)' }} />
                                                                        {remainingImages > 0 && (
                                                                            <div
                                                                                style={{
                                                                                    position: "absolute", bottom: "15px", right: "15px", backgroundColor: "#000000", color: "#FFFFFF", borderRadius: "5px", width: "20px", height: "20px",
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                +{remainingImages}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                        )
                                                        }
                                                    </Row >
                                                    <Row style={{ width: '35%', marginLeft: '10px', display: 'flex', marginRight: '20px', height: "9vh", alignItems: 'center' }}>
                                                        <Row style={{ display: 'flex', width: '90%', alignItems: 'center', }}>
                                                            {data?.videos?.map((val, index) => {
                                                                if (index < 3) {
                                                                    return (
                                                                        <div style={{ position: 'relative', width: '65px', borderRadius: '4px', marginRight: '5px', height: '8vh' }}>
                                                                            <PlayCircleOutlined style={{ position: "absolute", top: "50%", left: '50%', fontSize: "20px", color: "gray", transform: 'translate(-50%,-50%)' }} />
                                                                            <video src={val.locationPath} style={{ width: "98%", borderRadius: '4px', objectFit: 'cover', marginRight: '5px', height: '100%' }} key={index} controls={false}></video>
                                                                        </div>
                                                                    )
                                                                }
                                                                else if (index === 3 && data?.videos?.length > 3) {
                                                                    const remainingVideos = data.videos.length - 4;
                                                                    return (
                                                                        <div style={{ width: '65px', height: '95%', borderRadius: '4px', position: 'relative' }}>
                                                                            <video src={val.locationPath} style={{ width: "98%", height: '100%', borderRadius: '4px', objectFit: 'none', marginRight: '5px', filter: 'brightness(55%)' }} key={index} controls={false}></video>
                                                                            {remainingVideos > 0 && (
                                                                                <div
                                                                                    style={{
                                                                                        position: "absolute", bottom: "15px", right: "15px", backgroundColor: "#000000", color: "#FFFFFF", borderRadius: "5px", width: "20px", height: "20px",
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                    }}
                                                                                >
                                                                                    +{remainingVideos}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                }
                                                            }
                                                            )}
                                                        </Row>
                                                        <Row style={{ display: 'flex', width: '5%', justifyItems: 'end', alignItems: 'center' }}>
                                                            <Dropdown overlay={itemspop(data)} trigger={["click"]} >
                                                                <div style={{ color: '#707070', display: 'flex', alignItems: 'center' }}>
                                                                    <img src={moreVertical} style={{ width: '15px', height: '15px', cursor: "pointer", color: "#707070" }} alt="More Vertical" />
                                                                </div>
                                                            </Dropdown>
                                                        </Row>
                                                    </Row>
                                                </div >
                                            );
                                        })}
                                    </Row>)}
                            </Row >
                        </div>)}

                    {width < 700 && (
                        <div>
                            <Row className={classes.DashboardListRow}>
                                {loader ? (
                                    <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (
                                    <Row style={{ width: '100%', display: 'flex', height: '86vh', overflowY: 'scroll', }}>
                                        <Row className="firstRowDash">
                                            <Col style={{ display: 'flex', alignItems: 'center' }}><div style={{ fontSize: "20px", fontWeight: '500', fontFamily: 'poppins' }}> Gallery</div> </Col>
                                        </Row>
                                        {galleryData?.map((data, index) => {
                                            return (
                                                <div className={classes.MapGalleryMob} key={index}>
                                                    <div>{console.log("juuudata", data)}</div>
                                                    <div style={{ width: '100%', height: '45%', display: 'flex' }}>
                                                        <Typography.Text ellipsis={{ tooltip: true }} style={{ fontFamily: 'poppins', fontWeight: '600', maxWidth: "90%", }}> {data?.title}</Typography.Text>
                                                    </div>
                                                    <div style={{ width: '100%', height: '50%', display: 'flex', alignItems: 'center', gap: '1px', }}>
                                                        <Col style={{ width: '25%', display: 'flex', color: '#7D7D7D', fontFamily: 'poppins', fontWeight: '500', fontSize: '12px', gap: '5px' }}>
                                                            Images:  {data?.images?.length}
                                                        </Col>
                                                        <Col style={{ width: '25%', display: 'flex', color: '#7D7D7D', fontFamily: 'poppins', fontWeight: '500', fontSize: '12px' }}>
                                                            <span style={{ marginRight: '9px' }}>|</span>   Videos:  {data?.videos?.length}
                                                        </Col>
                                                        <Col style={{ width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', }} >
                                                            <Dropdown overlay={itemspop(data)} trigger={['click']}>
                                                                <div style={{ color: '#707070', display: "flex", flexShrink: 0 }}> <img src={moreVertical} style={{ width: '20px', height: '20px', cursor: 'pointer', color: '#707070' }} alt="More Vertical" />
                                                                </div>
                                                            </Dropdown>
                                                        </Col>
                                                    </div>
                                                </div >
                                            );
                                        })}
                                    </Row>)}
                            </Row >
                        </div>)}
                </>
            }
            {width > 700 && <Modal bodyStyle={{ height: '60vh', }} open={addModal} onCancel={() => { setAddModal(false) }} className="galleryModal" footer={false} key={'mode' + Math.random()}>
                {width > 700 && <div className="headline">Add {image ? "Images" : "Video"}</div>}
                {width < 700 && <div className="headline" style={{ fontFamily: 'poppins' }}>Add {image ? "Images" : "Video"}</div>}
                <div style={{ color: 'black', fontWeight: '500' }} > {image ? "You can upload only upto 10 images" : " "}</div>
                <div style={{ color: "black", fontWeight: '500' }}>{!image ? "You can upload only one video at a time" : null}</div>
                <Form layout='vertical'
                    onSubmit={() => { setAddModal(false) }}
                    onFinish={addHandler} id="editForm" key={'form' + Math.random()} style={{ overflow: 'scroll', display: "flex", flexDirection: "column", height: "40vh", justifyContent: "space-between" }}>
                    <Form.Item name="uploadFile" className="UploadDragger-formItem" rules={[
                        {
                            required: true,
                            message: image ? "Please upload an image" : "Please upload a video",
                        }, ({ getFieldValue }) => ({
                            validator(_, value) {
                                const fileList = getFieldValue('uploadFile');
                                if (fileList?.fileList?.length === 0) {
                                    return (Promise.reject(new Error(image ? 'Please upload an image' : 'Please upload a video')));
                                }
                                if (fileList?.fileList?.length >= 11) {
                                    console.log("lenghtsss", fileList?.fileList?.length - 10)
                                    return Promise.reject(new Error('You have added ' + fileList?.fileList?.length + ' images. Please delete the ' + fileList?.fileList?.length + 'th image'));
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]} >
                        <Upload.Dragger
                            accept={image ? "image/png, image/jpeg" : "video/mp4, video/mov"}
                            listType="picture"
                            beforeUpload={(file) => {
                                const isImg = file.type === "image/jpeg" || file.type === "image/png";
                                const isVideo = file.type === "video/mp4" || file.type === "video/mov";
                                if (image && !isImg) {
                                    Notification.error("Please upload an image file")
                                    return isImg || Upload.LIST_IGNORE
                                }
                                if (!image && !isVideo) {
                                    Notification.error("Please upload a video file")
                                    return isVideo || Upload.LIST_IGNORE;
                                }
                                return true;
                            }}
                            multiple={true}
                            className="UploadDragger upload-list-inline"
                            maxCount={image ? 11 : 1}
                        >
                            <div className="galleryUploadDrager" >
                                <CloudUploadOutlined style={{ width: "40px", height: "40px", fontSize: "34px" }} />
                                <div style={{ color: '#0086FF' }}>Click or drag file to this area to upload</div>
                            </div>
                        </Upload.Dragger>
                    </Form.Item>
                    <Form.Item>
                        <Row id="progress" justify="center" style={{ padding: '10px' }}>
                        </Row>

                    </Form.Item>
                    {width > 700 &&
                        <Form.Item style={{ marginBottom: '0px', display: "flex", justifyContent: 'flex-start', }}>
                            <Button key={2} htmlType="submit" style={{ borderRadius: '5px', color: 'white', marginRight: "16px", backgroundColor: "#0086FF", width: "8vw" }} disabled={uploadButtonDisabled} onCancel={() => { setAddModal(false) }}>Upload</Button>
                            <Button style={{ width: "8vw", borderRadius: '5px', height: '6vh' }} key={3} onClick={() => {
                                setAddModal(false);
                                document.getElementById('editForm').reset();
                            }} >Discard</Button>
                        </Form.Item>}
                    {width < 700 &&
                        <Form.Item style={{ width: '55vw', display: 'flex', marginBottom: '1px' }}>
                            <Button key={2} htmlType="submit" style={{ padding: '2px', marginBottom: '10px', backgroundColor: '#0086FF', color: 'white', borderRadius: '3px', width: '30vw', marginRight: '5px' }} disabled={uploadButtonDisabled} onCancel={() => { setAddModal(false) }}>Upload</Button>
                            <Button style={{ width: '20vw', padding: '2px', }} key={3} onClick={() => {
                                setAddModal(false);
                                document.getElementById('editForm').reset();
                            }} >Discard</Button>
                        </Form.Item>}
                </Form>
            </Modal>}

            {showModal && width < 700 && (
                <div className="custom-modal-container" style={{ padding: '0px 20px 0px 20px', width: '90%', height: '60vh', zIndex: '1000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', justifyContent: 'center', alignItems: 'center', background: 'white', display: 'flex', boxShadow: 'rgb(22 23 23 / 77%) 5px 0px 48px 137px', borderRadius: '4px' }}>
                    <div className="modal-content" style={{ width: '100%' }}>
                        <div style={{ borderBottom: ' 1px solid #CCCCCC', marginTop: '-15px', marginBottom: '12px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ fontFamily: 'poppins', fontSize: '18px', fontWeight: '600', }}>Add {image ? "Images" : "Video"}</div>
                            <img src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={toggleModal} />
                        </div>
                        <div className="modal-body" style={{ overflowY: 'scroll', height: '40vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div style={{ color: 'black', fontWeight: '500' }} > {image ? "You can upload only upto 10 images" : " "}</div>
                            <div style={{ color: "black", fontWeight: '500' }}>{!image ? "You can upload only one video at a time" : null}</div>
                            <Form layout='vertical' onFinish={addHandler} id="editForm">
                                <Form.Item name="uploadFile" className="UploadDragger-formItem" rules={[
                                    {
                                        required: true,
                                        message: image ? "Please upload an image" : "Please upload a video",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            const fileList = getFieldValue('uploadFile');
                                            if (fileList?.fileList?.length === 0) {
                                                return (Promise.reject(new Error(image ? 'Please upload an image' : 'Please upload a video')));
                                            }
                                            if (fileList?.fileList?.length >= 11) {
                                                return Promise.reject(new Error('You have added ' + fileList?.fileList?.length + ' images. Please delete the ' + fileList?.fileList?.length + 'th image'));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}>
                                    <Upload.Dragger
                                        accept={image ? "image/png, image/jpeg" : "video/mp4, video/mov"}
                                        listType="picture"
                                        beforeUpload={(file) => {
                                            const isImg = file.type === "image/jpeg" || file.type === "image/png";
                                            const isVideo = file.type === "video/mp4" || file.type === "video/mov";
                                            if (image && !isImg) {
                                                Notification.error("Please upload an image file");
                                                return isImg || Upload.LIST_IGNORE;
                                            }
                                            if (!image && !isVideo) {
                                                Notification.error("Please upload a video file");
                                                return isVideo || Upload.LIST_IGNORE;
                                            }
                                            return true;
                                        }}
                                        multiple={true}
                                        className="UploadDragger upload-list-inline"
                                        maxCount={image ? 11 : 1}
                                    >
                                        <div className="galleryUploadDrager">
                                            <CloudUploadOutlined style={{ width: "40px", height: "40px", fontSize: "34px" }} />
                                            <div style={{ color: '#0086FF' }}>Click or drag file to this area to upload</div>
                                        </div>
                                    </Upload.Dragger>
                                </Form.Item>
                                <Row id="progress" justify="center" style={{ padding: '10px' }}></Row>
                                <Form.Item style={{ width: '55vw', display: 'flex', marginBottom: '1px' }}>
                                    <Button htmlType="submit" style={{ padding: '2px', marginBottom: '10px', backgroundColor: '#0086FF', color: 'white', borderRadius: '3px', width: '30vw', marginRight: '5px' }} disabled={uploadButtonDisabled}>Upload</Button>
                                    <Button style={{ width: '20vw', padding: '2px' }} onClick={() => { setAddModal(false); document.getElementById('editForm').reset(); }}>Discard</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            )}


            <Modal open={deleteModal} closable={false} onCancel={() => { setDeleteModal(false); }} footer={false} key={Math.random()} bodyStyle={{ width: window.innerWidth < 350 ? `${window.innerWidth - 20}px` : window.innerWidth < 700 ? 'fit-content' : '600px', backgroundColor: 'white', borderRadius: '5px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CloseOutlined size="large" onClick={() => { setDeleteModal(false); }} style={{ font: 20, cursor: 'pointer', fontSize: 16, opacity: '0.7' }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <div className="modelTitle">Are you Sure?</div>
                    {console.log("jjjjdddd", deleteDataList, "jjjjttt", totalImagesCount)}
                    <div style={{ fontSize: '13px' }} className="modelInfo">
                        Do you really want to delete{' '}
                        {deleteDataList?.length === 0
                            ? 'the file'
                            : deleteDataList?.length === 1
                                ? 'this file'
                                : deleteDataList?.length === totalImagesCount
                                    ? 'all the files'
                                    : `${deleteDataList?.length} files`}{' '}
                        in this event? This process cannot be undone.
                    </div>
                    {deleteDataList?.length === totalImagesCount && deleteDataList?.length > 0 && (
                        <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '600', }}>
                            This will empty the Events gallery.
                        </div>
                    )}

                    <div className="modelBtn"> <Button className="modelCancelBtnGallery" onClick={() => { setDeleteModal(false); setDeleteDataList([]); }}>  Cancel </Button>
                        <Button onClick={() => { deleteHandler(); setDeleteDataList([]); setDeleteModal(false) }} className="modelDeleteBtn">  Delete </Button>
                    </div>
                </div>
            </Modal>
        </div >
    )
};
export default Gallery;