import { Button, Col, DatePicker, Form, Input, Modal, Row, Table, Typography, Upload, Dropdown, Menu, Pagination, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import Api from "services/Api";
import { CloseOutlined } from '@ant-design/icons';
import Notification from "services/Notification";
import { useDispatch } from "react-redux";
import { authActions } from "component/store";
import addIcon from "../../Images/addIcon.svg";
import moreVertical from "../../Images/moreVertical.svg";
import trashnew from "../../Images/trashnew.svg";
import classes from '../Styles.module.css';
import { LoadingOutlined } from '@ant-design/icons';
import x from "../../Images/x.svg";
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}
function EMagazine() {
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    dispatch(authActions.updatePageHighlight(window.location.pathname))
    const [modal, setModal] = useState(false)
    const [edit, setEdit] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [editData, setEditData] = useState({})
    const [deleteData, setDeleteData] = useState({})
    const [loader, setLoader] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [magazineData, setMagazineData] = useState([])
    const [fileList, setFileList] = useState([]);
    const [pdfList, setPdfList] = useState([]);
    // const [btnLoader,setBtnLoader]=useState(false)

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [publish, setPublish] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => {
        setShowModal(!showModal);
    };
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );
    // const [currentPage, setCurrentPage] = useState(1);
    // const itemsPerPage = 10;

    // // Calculate the indexes of the items to be displayed on the current page
    // const indexOfLastItem = currentPage * itemsPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems = magazineData.slice(indexOfFirstItem, indexOfLastItem);

    // const handlePageChange = (page) => {
    //     setCurrentPage(page);
    // };

    const handlePreview = (file) => {
        setPreviewImage(file.url || file.thumbUrl);
        setPreviewVisible(true);
    };

    const handleCancelPreview = () => {
        setPreviewVisible(false);
    };

    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };
    const props = {
        // action: '//jsonplaceholder.typicode.com/posts/',
        listType: 'picture',
        previewFile(file) {
            console.log('Your upload file:', file);
            return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
                method: 'POST',
                body: file,
            })
                .then(res => res.json())
                .then(({ thumbnail }) => thumbnail);
        },
    };
    const getMagazineData = () => {
        setLoader(true)
        Api.get("/emagazine/allEmagazine").send((res) => {
            // console.log(res);
            setLoader(false);
            setMagazineData(res);
        })
    }

    useEffect(() => {
        getMagazineData()
    }, [toggle])


    const deleteHandler = (val) => {
        // console.log("deleteHandler",val,deleteData)
        let id = deleteData?.id;
        Api.delete(`/emagazine/deleteEmagazine?id=${id}`).send((res) => {
            // console.log(res)
            setToggle(!toggle)
            setDeleteModal(false)
        })
    }
    const submitHandler = (val) => {
        let str = String(val?.date?.format("ll"))
        if (val?.title.trim().length === 0) {
            Notification.error("error", "please give a title ")
            return;
        }
        if (val?.coverImage?.fileList?.length === 0) {
            Notification.error("error", "please add cover Image")
            return;
        }
        let type = val?.coverImage?.fileList[0]?.type;
        if (!(type === "image/jpeg" || type === "image/png")) {
            Notification.error("error", "please upload a jpg/png file  ");
            return;
        }

        if (val?.emagazine?.fileList?.length === 0) {
            Notification.error("error", "please add E-Magazine file")
            return;
        }
        let fileType = val?.emagazine?.fileList[0]?.type;
        if (!(fileType === "application/pdf")) {
            Notification.error("error", "please upload pdf file only ");
            return;
        }
        // console.log(str);
        let data = { title: val?.title, emagazine: val?.emagazine?.file?.originFileObj, coverImage: val?.coverImage?.file?.originFileObj, yearMonth: str }

        Api.post("/emagazine/saveEmagazine").upload(data, (percentComplete, response) => {
            // console.log(percentComplete,response);
            if (percentComplete === 100 && response !== undefined) {
                if (response?.data?.show?.type === 'error') {
                    setModal(true);
                }
                else {
                    setToggle(!toggle);
                    setModal(false);
                    toggleModal()
                    document?.getElementById("addMagazineForm")?.reset();
                }

            }

        })

    }
    const editHandler = (val) => {
        let data = {};
        let id = editData?.id;
        let coverImageId = editData?.coverImageId
        let emagazineId = editData?.emagazineId
        if (val?.title.trim().length === 0) {
            Notification.error("error", "please give a title ")
            return;
        }
        if (val?.coverImage?.fileList?.length === 0) {
            Notification.error("error", "please add cover Image")
            return;
        }

        if (val?.emagazine?.fileList?.length === 0) {
            Notification.error("error", "please add E-Magazine file")
            return;
        }

        if (val?.title !== undefined && val?.title !== editData?.title) {
            data = { ...data, title: val?.title }
        }
        if (val?.date !== undefined) {
            let str = String(val?.date?.format("ll"))
            data = { ...data, yearMonth: str }
        }
        if (val?.coverImage !== undefined && val?.coverImage !== editData?.coverImage) {
            let type = val?.coverImage?.fileList[0]?.type;
            if (!(type === "image/jpeg" || type === "image/png")) {
                Notification.error("error", "please upload a jpg/png file ");
                return;
            }
            data = { ...data, coverImage: val?.coverImage?.file?.originFileObj, coverImageId: coverImageId }
        }
        if (val?.emagazine !== undefined && val?.emagazine !== editData?.emagazine) {
            let fileType = val?.emagazine?.fileList[0]?.type;
            console.log(fileType, val)
            if (!(fileType === "application/pdf")) {
                Notification.error("error", "please upload pdf file only ");
                return;
            }
            data = { ...data, emagazine: val?.emagazine?.file?.originFileObj, emagazineId: emagazineId }
        }

        // console.log("editHandler",data,val);
        if (Object.keys(data).length === 0) {
            Notification.success("No change", "")
            setEdit(false);
            return;

        }
        else {
            if (data.coverImage !== undefined || data.emagazine !== undefined) {
                Api.patch(`/emagazine/updateEmagazine?id=${id}`).uploadAndPatch(data, (p, r) => {
                    if (p === 100 && r !== undefined) {
                        setToggle(!toggle);
                        setEdit(false);
                    }
                })
            }
            else {
                Api.patch(`/emagazine/updateEmagazine?id=${id}`).params(data).send((res) => {
                    if (res !== undefined) {
                        setToggle(!toggle);
                        setEdit(false);
                    }
                })
            }

        }

    }
    const itemspop = (record) => (
        <Menu>
            <Menu.Item key="1">

                <div className="editBtn" onClick={() => {
                    setEditData(record); setEdit(true);
                    let obj = [{ url: record?.coverImage }]; setFileList(obj)
                    let obj1 = [{ url: record?.emagazine, name: record?.emagazine?.split("/")[4] }]; setPdfList(obj1)
                }}><EditOutlined />Edit</div>
            </Menu.Item>
            <Menu.Item key="2">
                <div className="deleteBtn" onClick={() => { setDeleteData(record); setDeleteModal(true); }}>
                    <img src={trashnew} style={{ width: '15px', height: '15px' }} /> Delete</div>
            </Menu.Item>
        </Menu>
    )
    // const columns = [
    //     {
    //         title: () => <b style={{ color: 'black' }}>Magazine Title</b>,
    //         dataIndex: 'Magazine Title',
    //         key: "Magazine Title",
    //         // width: '10vw',
    //         fontFamily: 'inherit',
    //         render: (text, record) => {
    //             return (
    //                 <Row style={{ width: '25vw', height: 'fit-content', marginRight: '25px' }} >
    //                     <Col style={{ width: '45px', height: '45px', marginRight: '5px', marginRight: '20px' }}>
    //                         <img style={{ borderRadius: '4px', width: '100%', height: '100%' }} src={record?.coverImage} alt=""></img>
    //                     </Col>
    //                     <Col style={{ display: 'block', width: '75%' }}>
    //                         <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal normal 16px/22px Open Sans", maxWidth: "100%", }}>   {record?.title}</Typography.Text>
    //                         <div style={{ color: 'grey' }}>Date
    //                             {record?.yearMonth}
    //                         </div>
    //                     </Col>
    //                 </Row>
    //             )
    //         }
    //     },

    //     {
    //         title: () => <b style={{ color: 'black' }}>File</b>,
    //         dataIndex: 'File',
    //         key: 'File',
    //         fontFamily: 'inherit',
    //         // width: '15vw',
    //         render: (text, record) => {
    //             return (
    //                 <Row style={{ width: '30vw', marginRight: '35px' }}>
    //                     <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal normal 16px/22px Open Sans", maxWidth: "100%", }}>   {record?.emagazine.split("/")[4]} </Typography.Text>
    //                 </Row>
    //             )
    //         }

    //     },

    //     {
    //         title: () => <b style={{ color: 'black' }}>Publish/Unpublish</b>,
    //         dataIndex: 'publish',
    //         key: 'publish',
    //         fontFamily: 'inherit',
    //         // width: '14vw',
    //         render: (text, record) => {
    //             const isPublished = record?.publish;
    //             console.log("record", record)
    //             const handleClick = () => {
    //                 setEditData(record);
    //                 const newPublishStatus = !isPublished;
    //                 setPublish(newPublishStatus);
    //                 Api.patch(`/emagazine/updateEmagazine?id=${record?.id}`).params({ publish: newPublishStatus }).send((p, r) => {
    //                     if (p === 100 && r !== undefined) {
    //                         setToggle(!toggle);
    //                         setEdit(false);
    //                     }
    //                 })
    //                 getMagazineData()
    //             };
    //             console.log("jjjj", publish)
    //             return (
    //                 <Row style={{ width: '12vw', display: 'flex', gap: '38px', height: '45px', }}>
    //                     {
    //                         isPublished ?
    //                             <div style={{ padding: '14px', width: '7vw', height: '5vh', cursor: 'pointer', background: '#0086FF', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: 'white', }
    //                             }
    //                                 onClick={handleClick} >
    //                                 UNPUBLISH
    //                             </div >
    //                             :
    //                             <div style={{ border: '1px solid #0086FF', padding: '14px', width: '7vw', height: '5vh', cursor: 'pointer', background: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: '#0086FF', }}
    //                                 onClick={handleClick}>
    //                                 PUBLISH
    //                             </div>
    //                     }
    //                     <Dropdown overlay={itemspop(record)} trigger={["click"]} >
    //                         <div style={{ color: '#707070' }}>
    //                             <img src={moreVertical} style={{ width: '15px', height: '15px', cursor: "pointer", color: "#707070" }} alt="More Vertical" />
    //                         </div>
    //                     </Dropdown>
    //                 </Row>
    //             );
    //         },
    //     },

    // ];
    return (
        <div className="container" >
            {width > 700 && (
                <Row className="firstRowDash">
                    <Col style={{ width: '45%', }} >
                        <Row>
                            <Col><div style={{ fontSize: "24px", fontWeight: '600', marginBottom: '10px', fontFamily: 'inherit' }}> E-Magazines</div> </Col>
                        </Row>
                        {/* <div style={{ fontSize: "16px", fontWeight: '600', fontFamily: "sans-serif", }}>A Quarterly Showcase of Excellence: Brigosha's E-Magazine Chronicles</div> */}
                    </Col>
                    <Col style={{ width: '50%', display: 'flex', justifyContent: 'end' }}>
                        <Button className="addbtn" onClick={() => { setModal(true) }}>ADD MAGAZINES</Button>
                    </Col>
                </Row>)}

            {width > 700 && (
                <Row className={classes.DashboardListRow}>
                    <Row className="HeadingRow" style={{ backgroundColor: '#F8F8F8', width: '100%', display: 'flex', position: 'sticky', top: '0', zIndex: '1', }}>
                        <Col style={{ width: '29%', marginLeft: '15px' }} className={classes.Heading}>
                            Title
                        </Col>
                        <Col style={{ width: '45%', marginLeft: '30px' }} className={classes.Heading}>
                            File
                        </Col>
                        <Col style={{ width: '15%', marginLeft: '25px' }} className={classes.Heading}>Publish/Unpublish</Col>
                    </Row>
                    {loader ? (
                        <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (
                        <Row style={{ width: '100%', display: 'flex' }}>
                            {magazineData?.map((data, index) => {
                                const isPublished = data?.publish;
                                console.log("record", data);
                                let dashobj = {};
                                const handleClick = () => {
                                    setEditData(data);
                                    const newPublishStatus = !isPublished;
                                    setPublish(newPublishStatus);
                                    Api.patch(`/emagazine/updateEmagazine?id=${data?.id}`).params({ publish: newPublishStatus }).send((p, r) => {
                                        if (p === 100 && r !== undefined) {
                                            setToggle(!toggle);
                                            setEdit(false);
                                        }
                                    })
                                    getMagazineData()
                                };
                                return (
                                    <div className={classes.DashboardMapDiv} key={index}>
                                        <Row style={{ width: '32%', height: '100%', display: 'flex', alignItems: 'center', gap: '6px', }} >
                                            <Col style={{ width: '45px', height: '45px', display: 'flex', marginRight: '10px', marginLeft: '5px', }}>
                                                <img src={data?.coverImage} style={{ width: '100%', height: '100%', borderRadius: '5px' }} alt="Profile" />
                                            </Col>
                                            <Col style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '70%' }}>
                                                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "90%", fontWeight: '600' }}>{data?.title}</Typography.Text>
                                                <div style={{ fontFamily: 'inherit', width: "100%", color: '#7D7D7D', fontWeight: '500', fontSize: '13px' }}>Date: {data?.yearMonth}</div>
                                            </Col>
                                        </Row>
                                        <Col style={{ width: '48%', marginLeft: '10px', display: 'flex' }}>
                                            <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "90%", fontWeight: '500' }}>  {data?.emagazine.split("/")[4]}</Typography.Text>
                                        </Col>
                                        <Col style={{ width: '10%', display: 'flex', gap: "10px", alignItems: 'center', }}>
                                            {isPublished ? (
                                                <div
                                                    style={{
                                                        padding: '14px',
                                                        width: '90%',
                                                        height: '5vh',
                                                        cursor: 'pointer',
                                                        background: '#0086FF',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        borderRadius: '4px',
                                                        opacity: '1',
                                                        color: 'white',
                                                    }}
                                                    onClick={() => handleClick()}
                                                >
                                                    UNPUBLISH
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        border: '1px solid #0086FF',
                                                        // padding: '14px',
                                                        paddingLeft: '25px',
                                                        paddingRight: '25px',
                                                        width: '90%',
                                                        height: '5vh',
                                                        cursor: 'pointer',
                                                        background: 'white',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        borderRadius: '4px',
                                                        opacity: '1',
                                                        color: '#0086FF',
                                                    }}
                                                    onClick={() => handleClick()}
                                                >
                                                    PUBLISH
                                                </div>
                                            )}
                                            <Dropdown overlay={itemspop(data)} trigger={['click']}>
                                                <div style={{ color: '#707070', marginLeft: '12px', marginRight: '12px', display: 'flex', alignItems: 'center' }}>
                                                    <img
                                                        src={moreVertical}
                                                        style={{ width: '15px', height: '15px', cursor: 'pointer', color: '#707070' }}
                                                        alt="More Vertical"
                                                    />
                                                </div>
                                            </Dropdown>
                                        </Col>
                                    </div >
                                );
                            })}
                        </Row>)}
                </Row >
            )}

            {width < 700 && (
                <Row style={{ width: '100%', gap: '10px', display: 'flex', alignItems: 'center', borderBottom: '1px solid #DBDBDB', justifyContent: 'space-between', marginTop: '5px', paddingBottom: '5px' }} >
                    <div style={{ fontSize: "20px", fontWeight: '500', fontFamily: 'poppins' }}> E-Magazines</div>
                    <Button className="addnewbtnmag" onClick={toggleModal}>ADD MAGAZINES</Button>
                </Row>
            )}

            {width < 700 && (
                <Row className={classes.DashboardListRow}>
                    {loader ? (
                        <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (
                        <Row style={{ width: '100%', display: 'flex', height: '80vh', overflowY: 'scroll' }}>
                            {magazineData?.map((data, index) => {
                                const isPublished = data?.publish;
                                console.log("record", data);
                                let dashobj = {};
                                const handleClick = () => {
                                    setEditData(data);
                                    const newPublishStatus = !isPublished;
                                    setPublish(newPublishStatus);
                                    Api.patch(`/emagazine/updateEmagazine?id=${data?.id}`).params({ publish: newPublishStatus }).send((p, r) => {
                                        if (p === 100 && r !== undefined) {
                                            setToggle(!toggle);
                                            setEdit(false);
                                        }
                                    })
                                    getMagazineData()
                                };
                                return (
                                    <div className={classes.MagDivMobile} key={index}>
                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '15px', height: '45%', marginBottom: '5px' }}>
                                            <Col style={{ width: '20%', height: '100%' }}>
                                                <img src={data?.coverImage} style={{ width: '60px', height: '60px', borderRadius: '5px' }} alt="Profile" />
                                            </Col>
                                            <Col style={{ width: '80%', height: '100%' }}>
                                                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "90%", fontWeight: '600', fontFamily: 'poppins' }}>{data?.title}</Typography.Text>
                                                <div style={{ display: 'flex', color: '#7D7D7D', fontFamily: 'poppins', fontWeight: '500', fontSize: '12px' }}>Date: {data?.yearMonth}</div>
                                            </Col>
                                        </div>
                                        <div style={{ height: '25%', }}>
                                            <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "90%", fontWeight: '600', fontFamily: 'poppins', fontSize: '12px' }}>  {data?.emagazine.split("/")[4]}</Typography.Text>
                                        </div>
                                        <div style={{ width: '100%', height: '19%', display: 'flex', }} >
                                            <Col style={{ width: '85%', display: 'flex', justifyContent: 'space-between' }}>
                                                {isPublished ? (
                                                    <div style={{ height: '100%', border: '1px solid #0086FF', padding: '4vw 5vw', width: '40%', cursor: 'pointer', background: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: '#0086FF', }} onClick={() => handleClick()}> UNPUBLISH
                                                    </div>
                                                ) : (
                                                    <div style={{ height: '100%', padding: '4vw 5vw', width: '40%', cursor: 'pointer', background: '#0086FF', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: 'white', }} onClick={() => handleClick()}
                                                    > PUBLISH
                                                    </div>
                                                )}
                                            </Col>
                                            <Col style={{ width: '12%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} >
                                                <Dropdown overlay={itemspop(data)} trigger={['click']}>
                                                    <div style={{ color: '#707070', display: "flex", flexShrink: 0 }}> <img src={moreVertical} style={{ width: '20px', height: '20px', cursor: 'pointer', color: '#707070' }} alt="More Vertical" />
                                                    </div>
                                                </Dropdown>
                                            </Col>
                                        </div>
                                    </div >
                                );
                            })}
                        </Row>)}
                    {/* <Col style={{ width: '100vw', display: 'flex', justifyContent: 'center', height: '10%', }}>
                        <Button className="addnewbtn" onClick={toggleModal}>ADD MAGAZINES</Button>
                    </Col> */}
                </Row >
            )}

            {width > 700 && <Modal bodyStyle={{ height: '75vh', }} open={modal} onCancel={() => { setModal(false); }} footer={false} key={Math.random()}>
                <div className="headline">Add Magazine</div>
                <Form style={{ overflowY: 'scroll', height: '90%' }} layout='vertical' onFinish={submitHandler} id="addMagazineForm">
                    <Form.Item label="Title" name="title" rules={[{ required: true, message: "Please enter title", },]}>
                        <Input placeholder="enter title "></Input>
                    </Form.Item>
                    {/* <Form.Item label="Calendar Quarter" name="date" rules={[{ required: true, message: "Please enter quarter date", },]}>
                        <DatePicker block picker="month" allowClear={false}></DatePicker>
                    </Form.Item> */}
                    <Form.Item label="Date" name="date"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the Date",
                                validator: (_, value) => {
                                    if (!value && !editData?.yearMonth) {
                                        return Promise.reject(new Error("Please enter the Date"));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <DatePicker onChange={onChange}
                            // defaultValue={editData?.yearMonth ? moment(editData.yearMonth, "MMM D, YYYY") : undefined}
                            allowClear={false} />
                    </Form.Item>
                    <Form.Item className={classes.uploadFiles} label="Upload cover image" name="coverImage" rules={[{ required: true, message: "Please upload an image", },]}>
                        <Upload
                            accept=".jpg, .png"
                            action="https://testassociate.brigosha.com"
                            // listType="picture"
                            maxCount={1}
                            {...props}
                        >
                            <Button icon={<UploadOutlined />}>Upload cover image</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item className={classes.uploadFiles} label="Upload File" name="emagazine" rules={[{ required: true, message: "Please upload a pdf file", },]}>
                        <Upload
                            type="file"
                            action="https://testassociate.brigosha.com"
                            accept=".pdf"
                            maxCount={1}
                        >
                            <Button icon={<UploadOutlined />}>Upload Magazine File</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '-7px', }}>
                        <Button block htmlType="submit" style={{ justifyContent: 'center', width: '25%', display: 'flex', alignItems: 'center', backgroundColor: '#0086FF', font: 'normal normal bold 14px/18px Open Sans', color: 'white', borderRadius: '5px', height: '2vh', cursor: 'pointer', padding: '18px', }}>Add</Button>
                    </Form.Item>
                </Form>
            </Modal>}

            {showModal && width < 700 && (
                <div className="custom-modal-container" style={{ padding: '0px 20px 0px 20px', width: '90%', height: '80vh', zIndex: '1000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', justifyContent: 'center', alignItems: 'center', background: 'white', display: 'flex', boxShadow: 'rgb(22 23 23 / 77%) 5px 0px 48px 137px', borderRadius: '4px' }}>
                    <div className="modal-content" style={{ width: '100%' }}>
                        <div style={{ borderBottom: ' 1px solid #CCCCCC', marginTop: '-15px', marginBottom: '12px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ fontFamily: 'poppins', fontSize: '18px', fontWeight: '600', }}>Add Magazine</div>
                            <img src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={toggleModal} />
                        </div>
                        <div className="modal-body" style={{ overflowY: 'scroll', height: '64vh', padding: '7px' }}>
                            <Form layout='vertical' onFinish={submitHandler} id="addMagazineForm">
                                <Form.Item label="Title" name="title" rules={[{ required: true, message: "Please enter title" }]}>
                                    <Input placeholder="Enter title" />
                                </Form.Item>
                                <Form.Item
                                    label="Date"
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter the Date",
                                            validator: (_, value) => {
                                                if (!value && !editData?.yearMonth) {
                                                    return Promise.reject(new Error("Please enter the Date"));
                                                }
                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <DatePicker onChange={onChange} allowClear={false} />
                                </Form.Item>
                                <Form.Item label="Upload cover image" name="coverImage" rules={[{ required: true, message: "Please upload an image" }]}>
                                    <Upload accept=".jpg, .png" action="https://testassociate.brigosha.com" maxCount={1} {...props}>
                                        <Button icon={<UploadOutlined />}>Upload cover image</Button>
                                    </Upload>
                                </Form.Item>
                                <Form.Item label="Upload File" name="emagazine" rules={[{ required: true, message: "Please upload a pdf file" }]}>
                                    <Upload type="file" action="https://testassociate.brigosha.com" accept=".pdf" maxCount={1}>
                                        <Button icon={<UploadOutlined />}>Upload Magazine File</Button>
                                    </Upload>
                                </Form.Item>
                                <Form.Item style={{ marginBottom: '-7px' }}>
                                    <Button block htmlType="submit" style={{ padding: '2px', marginBottom: '10px', backgroundColor: '#0086FF', color: 'white', borderRadius: '3px', width: '30vw', marginRight: '5px' }}>Add</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            )}

            {width > 700 && <Modal bodyStyle={{ height: '90vh', }} open={edit} onCancel={() => { setEdit(false); setEditData({}); }} footer={false} key={Math.random()} >
                <div className="headline">Edit Magazine</div>
                <Form style={{ overflowY: 'scroll', height: '93%', padding: '7px' }}
                    layout="vertical"
                    onFinish={editHandler}
                    id="editFormMagazine"
                    initialValues={editData}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "Please enter a title",
                            },
                        ]}
                    >
                        <Input placeholder="Enter title" />
                    </Form.Item>
                    <Form.Item
                        label="Date"
                        name="date"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the Date",
                                validator: (_, value) => {
                                    if (!value && !editData?.yearMonth) {
                                        return Promise.reject(new Error("Please enter the Date"));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <DatePicker
                            onChange={onChange}
                            defaultValue={editData?.yearMonth ? moment(editData.yearMonth, "MMM D, YYYY") : undefined}
                            allowClear={false}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Upload cover image"
                        name="coverImage"
                        rules={[
                            {
                                required: true,
                                message: "Please upload a cover image",
                            },
                        ]}
                    >
                        <Upload
                            accept=".jpg, .png"
                            action="https://testassociate.brigosha.com"
                            listType="picture"
                            maxCount={1}
                            defaultFileList={[...fileList]}
                        >
                            <Button icon={<UploadOutlined />}>Upload cover image</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        label="Upload File"
                        name="emagazine"
                        rules={[
                            {
                                required: true,
                                message: "Please upload a pdf file",
                            },
                        ]}
                    >
                        <Upload
                            listType="picture"
                            type="file"
                            action="https://testassociate.brigosha.com"
                            accept=".pdf"
                            maxCount={1}
                            defaultFileList={[...pdfList]}
                        >
                            <Button icon={<UploadOutlined />}>Upload Magazine File</Button>
                        </Upload>
                    </Form.Item>
                    {width > 700 &&
                        <Form.Item style={{ width: '22vw', display: 'flex', marginBottom: '-5px' }}>
                            <Button
                                block
                                htmlType="submit"
                                style={{ marginBottom: '10px', width: '9vw', backgroundColor: '#0086FF', color: 'white', borderRadius: '5px', width: 'fit-content', marginRight: '5px' }}
                            >
                                Save Changes
                            </Button>
                            <Button style={{ width: 'fit-content' }}
                                block
                                onClick={() => {
                                    document.getElementById("editFormMagazine").reset();
                                    setEdit(false);
                                }}
                            >
                                Discard
                            </Button>
                        </Form.Item>}
                    {width < 700 &&
                        <Form.Item style={{ width: '55vw', display: 'flex', marginBottom: '1px' }}>
                            <Button block htmlType="submit" style={{ padding: '2px', marginBottom: '10px', backgroundColor: '#0086FF', color: 'white', borderRadius: '3px', width: '30vw', marginRight: '5px' }} >Save Changes</Button>
                            <Button style={{ width: '20vw', padding: '2px', }} block
                                onClick={() => { document.getElementById("editFormMagazine").reset(); setEdit(false); }} > Discard </Button>
                        </Form.Item>}
                </Form>
            </Modal>}

            {width < 700 && edit && (
                <div className="custom-modal-container" style={{ padding: '0px 20px 0px 20px', width: '90%', height: '80vh', zIndex: '1000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', justifyContent: 'center', alignItems: 'center', background: 'white', display: 'flex', boxShadow: 'rgb(22 23 23 / 77%) 5px 0px 48px 137px', borderRadius: '4px' }}>
                    <div className="modal-content" style={{ width: '100%' }}>
                        <div style={{ borderBottom: ' 1px solid #CCCCCC', marginTop: '-15px', marginBottom: '12px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ fontFamily: 'poppins', fontSize: '18px', fontWeight: '600', }}>Edit Magazine</div>
                            <img src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={() => { setEdit(false); setEditData({}); }} />
                        </div>
                        <div className="modal-body" style={{ overflow: 'auto', maxHeight: 'calc(80vh - 2 * 20px - 45px)' }}>
                            <Form layout="vertical" onFinish={editHandler} id="editFormMagazine" initialValues={editData}>
                                <Form.Item label="Title" name="title" rules={[{ required: true, message: "Please enter a title" }]}>
                                    <Input placeholder="Enter title" />
                                </Form.Item>
                                <Form.Item label="Date" name="date" rules={[
                                    {
                                        required: true,
                                        message: "Please enter the Date",
                                        validator: (_, value) => {
                                            if (!value && !editData?.yearMonth) {
                                                return Promise.reject(new Error("Please enter the Date"));
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}>
                                    <DatePicker
                                        onChange={onChange}
                                        defaultValue={editData?.yearMonth ? moment(editData.yearMonth, "MMM D, YYYY") : undefined}
                                        allowClear={false}
                                    />
                                </Form.Item>
                                <Form.Item label="Upload cover image" name="coverImage" rules={[{ required: true, message: "Please upload a cover image" }]}>
                                    <Upload
                                        accept=".jpg, .png"
                                        action="https://testassociate.brigosha.com"
                                        listType="picture"
                                        maxCount={1}
                                        defaultFileList={[...fileList]}
                                    >
                                        <Button icon={<UploadOutlined />}>Upload cover image</Button>
                                    </Upload>
                                </Form.Item>
                                <Form.Item label="Upload File" name="emagazine" rules={[{ required: true, message: "Please upload a pdf file" }]}>
                                    <Upload
                                        listType="picture"
                                        type="file"
                                        action="https://testassociate.brigosha.com"
                                        accept=".pdf"
                                        maxCount={1}
                                        defaultFileList={[...pdfList]}
                                    >
                                        <Button icon={<UploadOutlined />}>Upload Magazine File</Button>
                                    </Upload>
                                </Form.Item>
                                <Form.Item style={{ width: '55vw', display: 'flex', marginBottom: '1px' }}>
                                    <Button block htmlType="submit" style={{ padding: '2px', marginBottom: '10px', backgroundColor: '#0086FF', color: 'white', borderRadius: '3px', width: '30vw', marginRight: '5px' }}>Save Changes</Button>
                                    <Button style={{ width: '20vw', padding: '2px' }} block onClick={() => { document.getElementById("editFormMagazine").reset(); setEdit(false); }}>Discard</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            )}


            <Modal open={deleteModal} closable={false} onCancel={() => { setDeleteModal(false) }} footer={false} key={Math.random()} bodyStyle={{ width: window.innerWidth < 350 ? `${window.innerWidth - 20}px` : window.innerWidth < 700 ? 'fit-content' : '600px', backgroundColor: 'white', borderRadius: '5px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}><CloseOutlined size="large" onClick={() => { setDeleteModal(false) }} style={{ font: 20, cursor: "pointer", fontSize: 16, opacity: '0.7' }} /></div>
                <div style={{ 'display': 'flex', 'flexDirection': 'column', 'gap': '10px' }}>
                    <div className="modelTitle">Are you Sure ?</div>
                    <div className="modelInfo">Do you really want to delete this magazine? This process cannot be undone.</div>
                    <div className="modelBtn">
                        <Button className="modelCancelBtn" onClick={() => { setDeleteModal(false) }}>Cancel</Button>
                        <Button onClick={() => { deleteHandler() }} className='modelDeleteBtn'>Delete</Button>
                    </div>  </div>
            </Modal>

        </div >
    )
};
export default EMagazine;