import classes from "./ModalCompo.module.css";
import closeIcon from "../Images/closeIcon.svg"
function ModalCompo({ open = false, onCancel, children, contentStyle, closableLeft = false, closableRight = false, header, childrenModStyle }) {

    // 

    if (open) {
        return (
            <div >
                <div className={classes.maskModal}></div>
                <div className={classes.ModalWrap}>
                    <div className={classes.modal} style={contentStyle} >

                        <div className={classes.header}>

                            <div>
                                {closableLeft === false ? <div></div> : <div onClick={onCancel} >
                                    <img src={closeIcon}></img>
                                </div>}
                            </div>

                            <div>
                                {header}
                            </div>

                            <div>
                                {closableRight === false ? <div></div> : <div onClick={onCancel} >
                                    <img src={closeIcon}></img>
                                </div>}

                            </div>


                        </div>


                        <div className={classes.modalContent} style={childrenModStyle}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default ModalCompo;


/*
This modal component is designed by kaushal kesharvani.
in case of any modification please contact him.

API's
open : boolean , modal will be visible if open = true;
onCancel : a function will trigger when clicked on "close" on the modal to close it
contentStyle: inLine css to give modal size and style
closable: boolean, to show the close button 




*/