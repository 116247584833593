import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

// add all reducers here
import counterReducer from "./pages/counter/redux";
import authReducer  from "component/store";
import { saveToLocalStorage,loadFromLocalStorage } from "services/Storage";
const preloadedState = loadFromLocalStorage();
const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth:authReducer,
    
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),preloadedState,
});
console.log(store)
store.subscribe(()=>saveToLocalStorage({auth:store.getState().auth}))
window.store=store;
export default store;
