import React from "react";
import classes from "./Login.module.css";
import Logo from "../../Images/Logo.svg"
import { Button, Form, Input } from "antd";
import { useHistory } from "react-router-dom";
import Api from "services/Api";
import Sider from "./Sider";

function SignUp() {
    let history = useHistory();

    const SignUpHandler = (val) => {
        console.log("sing up ", val)

        Api.post("/auth/signup").params({ name: val?.name, phone: val?.phone, email: val?.email, password: val?.cnfmPassword }).send((res) => {
            console.log("res on signup", res)
        })
    }
    return (

        <div className={classes.loginMain}>
            <img src={Logo} ></img>
            <div style={{ font: "normal normal bold 28px/38px Open Sans" }} >Sign Up</div>
            <div style={{ font: "normal normal normal 16px/22px Open Sans" }}>Create an account and publish Blogs.</div>
            <Form layout="vertical" onFinish={SignUpHandler}>
                <Form.Item label=<b>Full Name</b> name="name">
                    <Input placeholder="enter full name"></Input>
                </Form.Item>
                <Form.Item label=<b>E-mail</b> name="email">
                    <Input placeholder="enter email"></Input>
                </Form.Item>
                <Form.Item label=<b>Phone</b> name="phone">
                    <Input placeholder="enter phone number"></Input>
                </Form.Item>
                <Form.Item label=<b>Password</b> name="password" rules={[{ required: true, message: 'Please input your password!', },]}>
                    <Input.Password placeholder="enter password"></Input.Password>
                </Form.Item>
                <Form.Item label=<b>Confirmation Password</b> name="cnfmPassword" dependencies={['password']}
                    rules={[{ required: true, message: 'Please confirm your password!', },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    }),
                    ]}>
                    <Input.Password placeholder="enter confirmation password"></Input.Password>
                </Form.Item>
                <Form.Item >
                    <Button block className={classes.signInBtn} htmlType="submit" >Sign Up</Button>
                </Form.Item>
            </Form>
            <div>
                <div className={classes.loginOptionDiv}>
                    <div >Already have an account?</div>
                    <div onClick={() => { history.push("/login") }}>Sign In</div>
                </div>
                <div>Forgot Password?</div>
            </div>
        </div>
        // <div className={classes.loginContainer}>
        //     <div className={classes.loginMain}>
        //         <img src={Logo} ></img>
        //         <div style={{font:"normal normal bold 28px/38px Open Sans"}} >Sign Up</div>
        //         <div style={{font:"normal normal normal 16px/22px Open Sans"}}>Create an account and publish Blogs.</div>
        //         <Form layout="vertical" onFinish={SignUpHandler}>
        //             <Form.Item label=<b>Full Name</b> name="name">
        //                 <Input placeholder="enter full name"></Input>
        //             </Form.Item>
        //             <Form.Item label=<b>E-mail</b>  name="email">
        //                 <Input placeholder="enter email"></Input>
        //             </Form.Item>
        //             <Form.Item label=<b>Phone</b> name="phone">
        //             <Input placeholder="enter phone number"></Input>
        //             </Form.Item>
        //             <Form.Item label=<b>Password</b> name="password" rules={[{required: true,message: 'Please input your password!',},]}>
        //                 <Input.Password placeholder="enter password"></Input.Password>
        //             </Form.Item>
        //             <Form.Item label=<b>Confirmation Password</b> name="cnfmPassword" dependencies={['password']} 
        //             rules={[{required: true,message: 'Please confirm your password!',},
        //                     ({ getFieldValue }) => ({
        //                         validator(_, value) {
        //                         if (!value || getFieldValue('password') === value) {
        //                          return Promise.resolve();
        //                          }
        //                         return Promise.reject(new Error('The two passwords that you entered do not match!'));},}),
        //                         ]}>
        //                 <Input.Password placeholder="enter confirmation password"></Input.Password>
        //             </Form.Item>
        //             <Form.Item >
        //                 <Button block className={classes.signInBtn} htmlType="submit" >Sign Up</Button>
        //             </Form.Item>
        //         </Form>
        //         <div>
        //        <div className={classes.loginOptionDiv}>
        //         <div >Already have an account?</div>
        //         <div onClick={()=>{history.push("/login")}}>Sign In</div>
        //         </div>
        //         <div>Forgot Password?</div>
        //         </div>
        //     </div>
        //     <Sider />
        // </div>
    )
}
export default SignUp;