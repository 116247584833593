import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Table,
  Upload,
  Radio, Dropdown, Menu,
  Typography, InputNumber, Spin,
} from "antd";
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import moment from "moment";
import React, { useState, useEffect } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Api from "services/Api";
import Notification from "services/Notification";
import { useDispatch } from "react-redux";
import { authActions } from "component/store";
import addIcon from "../../Images/addIcon.svg";
import moreVertical from "../../Images/moreVertical.svg";
import trashnew from "../../Images/trashnew.svg";
import phone from "../../Images/phone.svg";
import classes from '../Styles.module.css';
import { LoadingOutlined } from '@ant-design/icons';
import x from "../../Images/x.svg";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}
function Location() {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  dispatch(authActions.updatePageHighlight(window.location.pathname))
  const { Paragraph, Text } = Typography;
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [locationData, setLocationData] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false)
  const [comingSoon, setComingSoon] = useState(false);
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [publish, setPublish] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  // const sortedLocationData = [...locationData]?.sort((a, b) => a?.priority - b?.priority);
  const itemspop = (record) => (
    <Menu>
      <Menu.Item key="1">
        <div className="editBtn" onClick={() => { setEditData(record); setEdit(true); }}>
          <EditOutlined />
          Edit
        </div>
      </Menu.Item>
      <Menu.Item key="2">
        <div className="deleteBtn" onClick={() => { setDeleteData(record); setDeleteModal(true); }}>
          <img src={trashnew} style={{ width: '15px', height: '15px' }} /> Delete</div>
      </Menu.Item>
    </Menu>
  )
  // const columns = [
  //   {
  //     title: () => <b style={{ color: 'black' }}>Office Type</b>,
  //     dataIndex: "officeType",
  //     key: "officeType",
  //     width: '13vw',
  //     fontFamily: 'inherit',
  //     render: (text, record) => {
  //       return (
  //         <Row style={{ width: '15vw', marginRight: '5px' }}>
  //           <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal normal 16px/22px Open Sans", maxWidth: "150px", }}>   {record?.officeName} </Typography.Text>
  //           <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal normal 16px/22px Open Sans", maxWidth: "150px", }}>   {record?.locationName} </Typography.Text>
  //         </Row>
  //       )
  //     },
  //   },

  //   {
  //     title: () => <b style={{ color: 'black' }}>Details</b>,
  //     dataIndex: "details",
  //     key: "details",
  //     width: '20vw',
  //     fontFamily: 'inherit',
  //     render: (text, record) => {
  //       return (
  //         // <Paragraph style={{ font: "normal normal normal 16px/22px Open Sans", }} ellipsis={true ? { rows: 3, expandable: true, symbol: '...', fontSize: 16, } : false}>{record?.detail}</Paragraph>
  //         <Row style={{ width: '30vw', marginRight: '10px' }}>
  //           <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal normal 16px/22px Open Sans", maxWidth: "100%", }}>     {record?.detail} </Typography.Text>
  //         </Row>
  //       )
  //     },
  //   },

  //   {
  //     title: () => <b style={{ color: 'black' }}>Phone Number</b>,
  //     dataIndex: "phone_number",
  //     key: "phone_number",
  //     width: '12vw',
  //     fontFamily: 'inherit',
  //     render: (text, record) => {
  //       return <Row style={{ width: '12vw' }}>{record?.phoneNo}</Row>;
  //     },
  //   },

  //   {
  //     title: () => <b style={{ color: 'black' }}> Publish/Unpublish</b>,
  //     dataIndex: 'publish',
  //     key: 'publish',
  //     width: '10vw',
  //     fontFamily: 'inherit',
  //     render: (text, record) => {
  //       const isPublished = record?.publish;
  //       console.log("record", record)
  //       let dashobj = {}
  //       const handleClick = () => {
  //         setEditData(record);
  //         const newPublishStatus = !isPublished;
  //         setPublish(newPublishStatus);
  //         Api.patch(`/locations/updateLocation?id=${record?.id}`).params({ publish: newPublishStatus }).send((res, err) => {
  //           // console.log(res, err);
  //           console.log("junia", res)
  //         });
  //         getLocationdata()
  //       };
  //       return (
  //         <Col style={{ width: '10%', display: 'flex', gap: "10px", alignItems: 'center', }}>
  //           {isPublished ? (
  //             <div
  //               style={{
  //                 padding: '14px',
  //                 width: '90%',
  //                 height: '5vh',
  //                 cursor: 'pointer',
  //                 background: '#0086FF',
  //                 justifyContent: 'center',
  //                 alignItems: 'center',
  //                 display: 'flex',
  //                 borderRadius: '4px',
  //                 opacity: '1',
  //                 color: 'white',
  //               }}
  //               onClick={() => handleClick()}
  //             >
  //               UNPUBLISH
  //             </div>
  //           ) : (
  //             <div
  //               style={{
  //                 border: '1px solid #0086FF',
  //                 // padding: '14px',
  //                 paddingLeft: '25px',
  //                 paddingRight: '25px',
  //                 width: '90%',
  //                 height: '5vh',
  //                 cursor: 'pointer',
  //                 background: 'white',
  //                 justifyContent: 'center',
  //                 alignItems: 'center',
  //                 display: 'flex',
  //                 borderRadius: '4px',
  //                 opacity: '1',
  //                 color: '#0086FF',
  //               }}
  //               onClick={() => handleClick()}
  //             >
  //               PUBLISH
  //             </div>
  //           )}
  //           <Dropdown overlay={itemspop(data)} trigger={['click']}>
  //             <div style={{ color: '#707070', marginLeft: '12px', marginRight: '12px', display: 'flex', alignItems: 'center' }}>
  //               <img
  //                 src={moreVertical}
  //                 style={{ width: '15px', height: '15px', cursor: 'pointer', color: '#707070' }}
  //                 alt="More Vertical"
  //               />
  //             </div>
  //           </Dropdown>
  //         </Col>
  //       );
  //     },
  //   },
  // ];

  const getLocationdata = (() => {
    setLoader(true)
    Api.get("/locations/allLocations").send((res, err) => {
      // console.log(res, err);
      setLocationData(res);
      setLoader(false);
    });
  })

  useEffect(() => {
    getLocationdata()
  }, [toggle]);

  const deleteHandler = (val) => {
    let id = deleteData?.id;
    // console.log("deleteHandler", deleteData?.id, id, typeof id);
    Api.delete(`/locations/deleteLocation?id=${id}`).send((res, err) => {
      // console.log(res, err);
      setToggle(!toggle)
      setDeleteModal(false);
    });
  };
  const submitHandler = (val) => {
    if (val?.detail.trim().length === 0) {
      Notification.error("error", "please give a detail ")
      return;
    }
    if (val?.phoneNo.trim().length === 0) {
      Notification.error("error", "please give a phoneNumber ")
      return;
    }
    // if (val?.locationImage?.length === 0) {
    //   Notification.error("error", "please upload the location image")
    //   return;
    // }
    if (val?.locationName.trim().length === 0) {
      Notification.error("error", "please give a location ")
      return;
    }
    if (val?.officeName.trim().length === 0) {
      Notification.error("error", "please give office type ")
      return;
    }
    let data = {}
    data = { officeName: val?.officeName, detail: val?.detail, phoneNo: val?.phoneNo, locationName: val?.locationName, priority: val?.priority };
    // console.log(data);
    Api.post("/locations/saveLocation").params(data).send((res, err) => {
      console.log("0000response", res);
      if (res?.show?.type === "error") {
        console.log("0000222", res);
        setModal(true);
      } else {
        setToggle(!toggle);
        setModal(false);
        toggleModal()
        document.getElementById("locationAddForm").reset();
      }
    });
  };

  const editHandler = (val) => {
    console.log("editHandler123", val);
    if (val?.detail.trim().length === 0) {
      Notification.error("error", "please give a detail ")
      return;
    }
    if (val?.phoneNo.trim().length === 0) {
      Notification.error("error", "please give a phoneNumber ")
      return;
    }
    if (val?.locationName.trim().length === 0) {
      Notification.error("error", "please give a location ")
      return;
    }
    if (val?.officeName.trim().length === 0) {
      Notification.error("error", "please give office type ")
      return;
    }
    let id = editData?.id;
    let sendVal = {};
    if (val?.locationName !== undefined && val?.locationName !== editData?.locationName) {
      sendVal = { ...sendVal, locationName: val?.locationName };
    }
    if (val?.priority !== undefined && val?.priority !== editData?.priority) {
      sendVal = { ...sendVal, priority: val?.priority };
    }
    if (val?.detail !== undefined && val?.detail !== editData?.detail) {
      sendVal = { ...sendVal, detail: val?.detail };
    }
    if (val?.phoneNo !== undefined && val?.phoneNo !== editData?.phoneNo) {
      sendVal = { ...sendVal, phoneNo: val?.phoneNo };
    }
    if (val?.officeName !== undefined && val?.officeName !== editData?.officeName) {
      sendVal = { ...sendVal, officeName: val?.officeName };
    }
    // console.log("check here",sendVal);
    if (Object.keys(sendVal).length === 0) {
      Notification.success("No change", "")
      setEdit(false);
      return;
    }
    else {
      Api.patch(`/locations/updateLocation?id=${id}`).params(sendVal).send((res, err) => {
        // console.log(res, err);
        console.log("junia", res)
      });
    }
    setToggle(!toggle);
    setEdit(false)
  };

  const data = [];
  return (
    <div className="container">
      {width > 700 && (
        <Row className="firstRowDash">
          <Col style={{ width: '45%', }} >
            <Row>
              <Col><div style={{ fontSize: "24px", fontWeight: '600', marginBottom: '10px', fontFamily: 'inherit' }}> Locations</div> </Col>
            </Row>
            {/* <div style={{ fontSize: "16px", fontWeight: '600', }}>Brigosha's Global Footprint: Discover Our Locations Worldwide</div> */}
          </Col>
          <Col style={{ width: '50%', display: 'flex', justifyContent: 'end' }}>
            <Button className="addbtn" onClick={() => { setModal(true); }}>
              ADD LOCATION
            </Button>
          </Col>
        </Row>)}
      {width > 700 && (
        <Row className={classes.DashboardListRow}>
          <Row className="HeadingRow" style={{ backgroundColor: '#F8F8F8', width: '100%', display: 'flex', position: 'sticky', top: '0', zIndex: '1', gap: '5px', }}>
            <b style={{ width: '21%', marginLeft: '15px', marginRight: '3px' }} className={classes.Heading}>
              Office Type
            </b>
            {/* <b style={{ width: '9%', }} className={classes.Heading}>
            Image
          </b>
          <b style={{ width: '9%', }} className={classes.Heading}>
            Link
          </b> */}
            <b style={{ width: '45%', marginRight: '5px' }} className={classes.Heading}>
              Details
            </b>
            <b style={{ width: '18%', }} className={classes.Heading}>
              Phone Number
            </b>
            <b style={{ width: '10%', }} className={classes.Heading}>Publish/Unpublish</b>
          </Row>
          {loader ? (
            <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (
            <Row style={{ width: '100%', display: 'flex', }}>
              {locationData?.map((data, index) => {
                const isPublished = data?.publish;
                console.log("record", data);
                let dashobj = {};
                const handleClick = () => {
                  setEditData(data);
                  const newPublishStatus = !isPublished;
                  setPublish(newPublishStatus);
                  Api.patch(`/locations/updateLocation?id=${data?.id}`).params({ publish: newPublishStatus }).send((res, err) => {
                    // console.log(res, err);
                    console.log("junia", res)
                  });
                  getLocationdata()
                };
                return (
                  <div style={{ gap: '5px' }} className={classes.DashboardMapDiv} key={index}>
                    <Row style={{ width: '16vw', marginRight: '5px', }}>
                      <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: '14px', width: '100%', fontWeight: '600' }}>   {data?.officeName} </Typography.Text>
                      <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '100%', color: '#7D7D7D', fontFamily: 'inherit', fontWeight: '500' }}>   {data?.locationName} </Typography.Text>
                    </Row>
                    {/* <Col style={{ width: '9%', display: 'flex', fontWeight: '500', }}>
                    <img src={data?.locationImage} style={{ width: '100%', height: '100%', borderRadius: '5px' }} alt="Image" />
                  </Col>
                  <Col style={{ width: '9%', display: 'flex', }}>
                    {data?.locationLink}
                  </Col> */}
                    <Col style={{ width: '40%', marginLeft: '10px', display: 'flex', fontWeight: '500', marginRight: '5vw' }}>
                      <Typography.Text ellipsis={{ tooltip: true }} style={{ fontFamily: 'inherit', fontWeight: '500', maxWidth: "90%", }}>{data?.detail} </Typography.Text>
                    </Col>
                    <Col style={{ width: '18%', display: 'flex', fontWeight: '500', }}>
                      <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "90%", fontWeight: '500' }}>  {data?.phoneNo}</Typography.Text>
                    </Col>
                    <Col style={{ width: '10%', display: 'flex', gap: "5px", alignItems: 'center', marginLeft: '5px' }}>
                      {isPublished ? (<div style={{ padding: '14px', width: '7vw', height: '5vh', cursor: 'pointer', background: '#0086FF', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: 'white', }} onClick={() => handleClick()} >
                        UNPUBLISH
                      </div>
                      ) : (
                        <div style={{ border: '1px solid #0086FF', paddingLeft: '25px', paddingRight: '25px', width: '90%', height: '5vh', cursor: 'pointer', background: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: '#0086FF', }} onClick={() => handleClick()} >
                          PUBLISH
                        </div>
                      )}
                      <Dropdown overlay={itemspop(data)} trigger={['click']}>
                        <div style={{ color: '#707070' }}>
                          <img
                            src={moreVertical}
                            style={{ width: '15px', height: '15px', cursor: 'pointer', color: '#707070' }}
                            alt="More Vertical"
                          />
                        </div>
                      </Dropdown>
                    </Col>
                  </div >
                );
              })}
            </Row>)}
        </Row >)}

      {width < 700 && (
        <Row style={{ width: '100%', gap: '10px', display: 'flex', alignItems: 'center', borderBottom: '1px solid #DBDBDB', justifyContent: 'space-between', marginTop: '5px', paddingBottom: '5px' }} >
          <div style={{ fontSize: "20px", fontWeight: '500', fontFamily: 'poppins' }}> Location</div>
          <Button className="addnewbtnmag" onClick={toggleModal}> ADD LOCATION</Button>
        </Row>
      )}

      {width < 700 && (
        <Row className={classes.DashboardListRow}>
          {loader ? (
            <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (
            <Row style={{ width: '100%', display: 'flex', height: '80vh', overflowY: 'scroll' }}>
              {locationData?.map((data, index) => {
                const isPublished = data?.publish;
                console.log("record", data);
                let dashobj = {};
                const handleClick = () => {
                  setEditData(data);
                  const newPublishStatus = !isPublished;
                  setPublish(newPublishStatus);
                  Api.patch(`/locations/updateLocation?id=${data?.id}`).params({ publish: newPublishStatus }).send((res, err) => {
                    // console.log(res, err);
                    console.log("junia", res)
                  });
                  getLocationdata()
                };
                return (
                  <div style={{ gap: '5px' }} className={classes.DashboardDivMobileLoc} key={index}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '30%' }}>
                      <Row style={{ display: 'flex', width: '100%' }}><Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: '14px', width: '100%', fontWeight: '600', fontFamily: 'poppins', }}>   {data?.officeName} </Typography.Text></Row>
                      <Row style={{ display: 'flex', width: '100%' }}><Typography.Text ellipsis={{ tooltip: true }} style={{ width: '100%', color: '#7D7D7D', fontFamily: 'poppins', fontWeight: '500' }}>   {data?.locationName} </Typography.Text></Row>
                    </div>
                    <div style={{ height: '30%', width: '100%', }}>
                      <Paragraph style={{ fontWeight: '500', fontFamily: 'poppins', fontSize: '12px' }} ellipsis={true ? { rows: 2, symbol: '...', fontSize: 16, } : false}>{data?.detail} </Paragraph>
                    </div>
                    <div style={{ height: '15%', width: '100%' }}>
                      <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "90%", fontWeight: '500', fontFamily: 'poppins', }}><img src={phone} style={{ width: '13px', height: '13px', marginRight: '4px' }} />  {data?.phoneNo}</Typography.Text>
                    </div>
                    <div style={{ width: '100%', height: '19%', display: 'flex', marginTop: '5px' }} >
                      <Col style={{ width: '85%', display: 'flex', justifyContent: 'space-between' }}>
                        {isPublished ? (
                          <div style={{ height: '100%', border: '1px solid #0086FF', padding: '4vw 5vw', width: '40%', cursor: 'pointer', background: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: '#0086FF', }} onClick={() => handleClick()}> UNPUBLISH
                          </div>
                        ) : (
                          <div style={{ height: '100%', padding: '4vw 5vw', width: '40%', cursor: 'pointer', background: '#0086FF', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: 'white', }} onClick={() => handleClick()}
                          > PUBLISH
                          </div>
                        )}
                      </Col>
                      <Col style={{ width: '12%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} >
                        <Dropdown overlay={itemspop(data)} trigger={['click']}>
                          <div style={{ color: '#707070', display: "flex", flexShrink: 0 }}> <img src={moreVertical} style={{ width: '20px', height: '20px', cursor: 'pointer', color: '#707070' }} alt="More Vertical" />
                          </div>
                        </Dropdown>
                      </Col>

                    </div>
                  </div >
                );
              })}
            </Row>)}
          {/* <Col style={{ width: '100vw', display: 'flex', justifyContent: 'center', height: '10%', }}>
            <Button className="addnewbtn" onClick={toggleModal}><img style={{ width: '12px', height: '12px', marginRight: '8px' }} src={addIcon} />  ADD LOCATION</Button>
          </Col> */}
        </Row >)}

      {width > 700 && <Modal bodyStyle={{ height: '90vh', }} visible={modal} onCancel={() => { setModal(false); setComingSoon(false) }} footer={false}>
        <div className="headline">Add Location </div>
        <Form style={{ overflowY: 'scroll', height: '92%', padding: '7px' }} layout="vertical" form={form} onFinish={submitHandler} id="locationAddForm" >
          <Form.Item label="Office Type" name="officeName" rules={[{ required: true, message: "Please enter office type", },]}>
            <Input placeholder="Enter office type "  ></Input>
          </Form.Item>
          <Form.Item label="Location" name="locationName" rules={[{ required: true, message: "Please enter location", },]}>
            <Input placeholder="Enter location "  ></Input>
          </Form.Item>
          {/* <Form.Item label="Location Image" name="locationImage" rules={[{ required: true, message: "Please upload a location image", },]}>
            <Upload
              type="image"
              accept=".jpg, .png"
              action="https://testassociate.brigosha.com"
              listType="picture"
              maxCount={1}
              status="success"
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Location Link" name="locationLink" rules={[{ required: true, message: "Please upload link of the location", },]}>
            <Input placeholder="Enter the location link"  ></Input>
          </Form.Item> */}
          <Form.Item label="Priority" name="priority" rules={[{ required: true, message: "Please enter the priority level" }]}>
            <InputNumber placeholder="Priority number" min={1} max={5} />
          </Form.Item>
          <Form.Item label="Details" name="detail" rules={[{ required: true, message: "Please enter details", },]} >
            <Input.TextArea placeholder="Details about the location " rows={4}>
            </Input.TextArea>
          </Form.Item>
          <Form.Item label="Phone Number" name="phoneNo" rules={[{ required: true, pattern: /^[1-9]\d{9}$/, message: "Please enter a valid 10 digit mobile number", },]}>
            <Input placeholder="Enter 10 digit phone number" type="text" maxLength={10} />
          </Form.Item>

          <Row style={{ width: '25vw', }}>
            <Form.Item style={{ width: '100%', }}>
              <Col style={{ width: '30%' }}>
                <Button block htmlType="submit" style={{ justifyContent: 'center', width: '100%', display: 'flex', alignItems: 'center', backgroundColor: '#0086FF', font: 'normal normal bold 14px/18px Open Sans', color: 'white', borderRadius: '5px', height: '2vh', cursor: 'pointer', padding: '18px' }}>Add</Button>
              </Col>
              <Col style={{ width: '30%', marginLeft: '10vw', marginTop: '-6vh' }}>
                <Button style={{ justifyContent: 'center', width: '100%', display: 'flex', alignItems: 'center', backgroundColor: 'white', font: 'normal normal bold 14px/18px Open Sans', color: 'black', borderRadius: '5px', height: '2vh', cursor: 'pointer', padding: '18px' }} block onClick={() => { document.getElementById("locationAddForm").reset(); setModal(false) }}>
                  Discard
                </Button>
              </Col>
            </Form.Item>
          </Row>
        </Form>
      </Modal>}

      {showModal && width < 700 && (
        <div className="custom-modal-container" style={{ padding: '0px 20px 0px 20px', width: '90%', height: '80vh', zIndex: '1000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', justifyContent: 'center', alignItems: 'center', background: 'white', display: 'flex', boxShadow: 'rgb(22 23 23 / 77%) 5px 0px 48px 137px', borderRadius: '4px' }}>
          <div className="modal-content" style={{ width: '100%' }}>
            <div style={{ borderBottom: ' 1px solid #CCCCCC', marginTop: '-15px', marginBottom: '12px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ fontFamily: 'poppins', fontSize: '18px', fontWeight: '600', }}>Add Location</div>
              <img src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={toggleModal} />
            </div>
            <div className="modal-body" style={{ overflowY: 'scroll', height: '64vh', padding: '7px' }}>
              <Form layout="vertical" form={form} onFinish={submitHandler} id="locationAddForm">
                <Form.Item label="Office Type" name="officeName" rules={[{ required: true, message: "Please enter office type", }]}>
                  <Input placeholder="Enter office type" />
                </Form.Item>
                <Form.Item label="Location" name="locationName" rules={[{ required: true, message: "Please enter location", }]}>
                  <Input placeholder="Enter location" />
                </Form.Item>
                <Form.Item label="Priority" name="priority" rules={[{ required: true, message: "Please enter the priority level" }]}>
                  <InputNumber placeholder="Priority number" min={1} max={5} />
                </Form.Item>
                <Form.Item label="Details" name="detail" rules={[{ required: true, message: "Please enter details", }]}>
                  <Input.TextArea placeholder="Details about the location" rows={4} />
                </Form.Item>
                <Form.Item label="Phone Number" name="phoneNo" rules={[{ required: true, pattern: /^[1-9]\d{9}$/, message: "Please enter a valid 10 digit mobile number", }]}>
                  <Input placeholder="Enter 10 digit phone number" type="text" maxLength={10} />
                </Form.Item>
                <Form.Item style={{ width: '55vw', display: 'flex', marginBottom: '1px' }}>
                  <Button htmlType="submit" style={{ padding: '2px', marginBottom: '10px', backgroundColor: '#0086FF', color: 'white', borderRadius: '3px', width: '30vw', marginRight: '5px' }}>Add</Button>
                  <Button style={{ padding: '1px 8px 1px', width: 'fit-content', }} onClick={() => { document.getElementById("locationAddForm").reset(); setModal(false); }}>Discard</Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      )}

      {width > 700 && <Modal bodyStyle={{ height: '90vh', }} visible={edit} onCancel={() => { setEdit(false); setEditData({}); setComingSoon(false) }} footer={false} key={'location' + Math.random()}>
        {width > 700 && <div className="headline">Edit Location</div>}
        {width < 700 && <div style={{ fontFamily: 'poppins' }} className="headlineMob">Edit Location</div>}
        <Form style={{ overflowY: 'scroll', height: '93%' }} layout='vertical' onFinish={editHandler} id="editForm" initialValues={editData}>
          <Form.Item label="Office Type" name="officeName" rules={[{ required: true, message: "Please enter office type", },]}>
            <Input placeholder="Enter office type " ></Input>
          </Form.Item>
          <Form.Item label="Location" name="locationName" rules={[{ required: true, message: "Please enter location", },]}>
            <Input placeholder="Enter location "></Input>
          </Form.Item>
          {/* <Form.Item label="Location Image" name="locationImage" rules={[{ required: true, message: "Please upload a location image", },]}>
            <Upload
              type="image"
              accept=".jpg, .png"
              action="https://testassociate.brigosha.com"
              listType="picture"
              maxCount={1}
              status="success"
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Location Link" name="locationLink" rules={[{ required: true, message: "Please upload link of the location", },]}>
            <Input placeholder="Enter the location link"  ></Input>
          </Form.Item> */}
          <Form.Item label="Priority" name="priority" rules={[{ required: true, message: "Please enter the priority level" }]}>
            <InputNumber placeholder="Enter the priority number" min={1} max={5} />
          </Form.Item>
          <Form.Item label="Details" name="detail" rules={[{ required: true, message: "Please enter details", },]}>
            <Input.TextArea placeholder="Details about the location " rows={4}  ></Input.TextArea>
          </Form.Item>
          <Form.Item label="Phone Number" name="phoneNo" rules={[{ required: true, pattern: /^[1-9]\d{9}$/, message: "Please enter a valid 10 digit mobile number", },]}>
            <Input placeholder="Enter phone number" type="text" maxlength="10" pattern="[1-9]{1}[0-9]{9}" ></Input>
          </Form.Item>
          {width > 700 &&
            <Form.Item style={{ width: '22vw', display: 'flex', marginBottom: '1px' }}>
              <Button block htmlType="submit" style={{ marginBottom: '10px', width: '9vw', backgroundColor: '#0086FF', color: 'white', borderRadius: '5px', width: 'fit-content', marginRight: '5px' }}>Save Changes</Button>
              <Button style={{ width: 'fit-content' }} block key={3} onClick={() => {
                setEdit(false);
                document.getElementById('editForm').reset();
              }} >Discard</Button>
            </Form.Item>}
          {width < 700 &&
            <Form.Item style={{ width: '55vw', display: 'flex', marginBottom: '1px' }}>
              <Button block htmlType="submit" style={{ padding: '2px', marginBottom: '10px', backgroundColor: '#0086FF', color: 'white', borderRadius: '3px', width: '30vw', marginRight: '5px' }}>Save Changes</Button>
              <Button style={{ width: '20vw', padding: '2px', }} block key={3} onClick={() => {
                setEdit(false);
                document.getElementById('editForm').reset();
              }} >Discard</Button>
            </Form.Item>}
        </Form>
      </Modal>}

      {width < 700 && edit && (
        <div className="custom-modal-container" style={{ padding: '0px 20px 0px 20px', width: '90%', height: '80vh', zIndex: '1000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', justifyContent: 'center', alignItems: 'center', background: 'white', display: 'flex', boxShadow: 'rgb(22 23 23 / 77%) 5px 0px 48px 137px', borderRadius: '4px' }}>
          <div className="modal-content" style={{ width: '100%' }}>
            <div style={{ borderBottom: ' 1px solid #CCCCCC', marginTop: '-15px', marginBottom: '12px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ fontFamily: 'poppins', fontSize: '18px', fontWeight: '600', }}>Edit Location</div>
              <img src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={() => { setEdit(false); setEditData({}); setComingSoon(false); }} />
            </div>
            <div className="modal-body" style={{ overflow: 'auto', maxHeight: 'calc(80vh - 2 * 20px - 45px)' }}>
              <Form layout='vertical' onFinish={editHandler} id="editForm" initialValues={editData}>
                <Form.Item label="Office Type" name="officeName" rules={[{ required: true, message: "Please enter office type" }]}>
                  <Input placeholder="Enter office type" />
                </Form.Item>
                <Form.Item label="Location" name="locationName" rules={[{ required: true, message: "Please enter location" }]}>
                  <Input placeholder="Enter location" />
                </Form.Item>
                <Form.Item label="Priority" name="priority" rules={[{ required: true, message: "Please enter the priority level" }]}>
                  <InputNumber placeholder="Enter the priority number" min={1} max={5} />
                </Form.Item>
                <Form.Item label="Details" name="detail" rules={[{ required: true, message: "Please enter details" }]}>
                  <Input.TextArea placeholder="Details about the location" rows={4} />
                </Form.Item>
                <Form.Item label="Phone Number" name="phoneNo" rules={[{ required: true, pattern: /^[1-9]\d{9}$/, message: "Please enter a valid 10 digit mobile number" }]}>
                  <Input placeholder="Enter phone number" type="text" maxLength="10" pattern="[1-9]{1}[0-9]{9}" />
                </Form.Item>
                <Form.Item style={{ width: '55vw', display: 'flex', marginBottom: '1px' }}>
                  <Button htmlType="submit" style={{ padding: '2px', marginBottom: '10px', backgroundColor: '#0086FF', color: 'white', borderRadius: '3px', width: '30vw', marginRight: '5px' }}>Save Changes</Button>
                  <Button style={{ width: '20vw', padding: '2px' }} onClick={() => { setEdit(false); document.getElementById('editForm').reset(); }}>Discard</Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      )}


      <Modal visible={deleteModal} style={{ width: '650px' }} onCancel={() => { setDeleteModal(false); }} footer={false} closable={false} bodyStyle={{ width: window.innerWidth < 350 ? `${window.innerWidth - 20}px` : window.innerWidth < 700 ? 'fit-content' : '600px', backgroundColor: 'white', borderRadius: '5px' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}><CloseOutlined size="large" onClick={() => { setDeleteModal(false) }} style={{ font: 20, cursor: "pointer", fontSize: 16, opacity: '0.7' }} /></div>
        <div style={{ 'display': 'flex', 'flexDirection': 'column', 'gap': '10px' }}>
          <div className="modelTitle">Are you Sure ?</div>
          <div className="modelInfo">Do you really want to delete the Location? This process cannot be undone</div>
          <div className="modelBtn">
            <Button className="modelCancelBtn" onClick={() => { setDeleteModal(false) }}>Cancel</Button>
            <Button onClick={() => { deleteHandler(); }} className="modelDeleteBtn">Delete</Button>
          </div>
        </div>
      </Modal>
    </div >
  );
}
export default Location;
