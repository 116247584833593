import React, { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import arrowLeft from "../../Images/arrowLeft.svg";
import { displayName } from "react-quill";
import { Row } from "antd";
import ErrorGift from '../../Images/ErrorGift.gif'
import urls from "credential";
function NotFound() {
    const history = useHistory();
    const Goback = () => {
        // localStorage.removeItem("control-panel-1f2f");
        // history.pushState({}, "", urls?.redirectionUrl)
        window.location.href = urls?.baseUrl
    };
    return (
        <Row style={{ height: '100vh', width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <div style={{}}>
                {/* <Row style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: 'sans-serif', fontWeight: '800' }}>
                    <span style={{ fontSize: '45px', color: 'red' }}>404</span>
                    <span style={{ fontSize: '25px', color: 'red' }}>Page Not Found!!!</span>
                </Row> */}
                <Row style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: 'sans-serif', fontWeight: '800', justifyContent: 'center' }}>
                    <img style={{ width: '25%', height: '25%' }} src={ErrorGift}></img>
                    {/* <span style={{ fontSize: '24px', }}>Something Went Wrong</span> */}
                </Row>

                <Row style={{ height: 'fit-content', width: '100%', justifyContent: 'center', fontWeight: '500', fontSize: '14px', display: 'flex', gap: '7px', marginTop: '8px', cursor: 'pointer', marginTop: '12px' }} ><button style={{ backgroundColor: '#0086FF', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer', padding: '4px' }} onClick={Goback}>Back to HomePage</button></Row>

            </div>
        </Row>
    )
}
export default NotFound;