import { Col, Row } from "antd"
import { Route, BrowserRouter as Router, Switch } from "react-router-dom"
import Event from "component/Events/Event"
import EMagazine from "component/EMagazine/EMagazine"
import Gallery from "component/Gallery/Gallery"
import Location from "component/Location/Location"
import DashBoard from "component/DashBoard/DashBoard"
import "../component.css"
import Blog from "component/Blog/Blog"
import BrigoshaLogin from "component/LoginPages/BrigoshaLogin"
import SignUp from "component/LoginPages/SignUp"
import BlogUsers from "component/blogUsers/BlogUsers"
import ProtectedRoute from "./ProtectedRoute"
import { useSelector } from "react-redux"
import Unauthorized from "component/LoginPages/Unauthorized"
import NotFound from "component/LoginPages/NotFound"

function Main(props) {
    const { user } = useSelector(state => state.auth);
    let span = window?.innerWidth < 1445 ? 4 : 3;
    console.log("ameer main", window.location.pathname)
    return (
        <Router>
            <Switch>
                <Route path='/brigosha-login'>
                    <BrigoshaLogin />
                </Route>
                <Route exact path='/sign-up'>
                    <SignUp></SignUp>
                </Route>

                <Route exact path="/unauthorized">
                    <Unauthorized />
                </Route>
                <ProtectedRoute exact path="/"><DashBoard /></ProtectedRoute>
                <ProtectedRoute exact path='/events' ><Event /></ProtectedRoute>
                <ProtectedRoute exact path='/magazine'><EMagazine /></ProtectedRoute>
                <ProtectedRoute exact path='/brigosha-blog'><Blog company="Brigosha" /></ProtectedRoute>
                <ProtectedRoute exact path='/aulas-blog'><Blog company="Aulas" /></ProtectedRoute>
                <ProtectedRoute exact path='/vector-blog'><Blog company="Vector" /></ProtectedRoute>
                <ProtectedRoute exact path='/blog'><Blog /></ProtectedRoute>
                <ProtectedRoute exact path='/gallery'><Gallery /></ProtectedRoute>
                <ProtectedRoute exact path='/location'><Location /></ProtectedRoute>
                <ProtectedRoute exact path='/blog-users'><BlogUsers /></ProtectedRoute>
                <Route path="/*">
                    <NotFound />
                </Route>
            </Switch >
        </Router >
    )
}
export default Main