// import { SearchOutlined } from "@ant-design/icons";
// import { Input } from "antd";
// import "./SearchCompo.css";
// import { useEffect, useState } from "react";
// import search from '../Images/search.svg'
// function getWindowDimensions() {
//   const { innerWidth: width, innerHeight: height } = window;
//   return {
//     width,
//     height,
//   };
// }
// export function useWindowDimensions() {
//   const [windowDimensions, setWindowDimensions] = useState(
//     getWindowDimensions()
//   );
//   useEffect(() => {
//     function handleResize() {
//       setWindowDimensions(getWindowDimensions());
//     }
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);
//   return windowDimensions;
// }

// const SearchCompo = (props) => {
//   const { innerWidth: width, innerHeight: height } = window;
//   return (
//     <>
//       {width > 700 &&
//         <div style={{ boxShadow: "rgba(9, 17, 43, 0.08) 3px 3px 6px 5px", display: "flex", gap: "10px", backgroundColor: "white", borderRadius: "5px", maxWidth: props?.maxWidth ? props?.maxWidth : "100%", width: "100%", alignItems: 'center', justifyContent: 'space-between', height: '100%' }} >
//           <div style={{ display: 'flex', alignItems: 'center', }}> <Input style={{ color: "#000000", fontWeight: "400", fontSize: "17px", border: "0", display: 'flex', alignItems: 'center' }} id='searchUser' onChange={(e) => { const currValue = e.target.value.trim(); props?.setSearch(currValue); }} value={props?.value} placeholder="Search" /></div>
//           <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}><SearchOutlined style={{ fontSize: '18px', opacity: '40%', }} /></div>
//         </div>}

//       {width < 700 &&
//         <div style={{ position: 'relative', height: '27px', boxShadow: "rgba(9, 17, 43, 0.08) 3px 3px 6px 5px", display: "flex", gap: "10px", backgroundColor: "white", borderRadius: "5px", maxWidth: props?.maxWidth ? props?.maxWidth : "100%", alignItems: 'center', justifyContent: 'space-between', height: '100%' }} >
//           <Input className="Inputsearch" style={{ border: '2px solid green !important', color: "#000000", fontWeight: "400", fontSize: "15px", border: "0", display: 'flex', alignItems: 'center', height: '95%', width: '80vw' }} id='searchUser' onChange={(e) => { const currValue = e.target.value.trim(); props?.setSearch(currValue); }} value={props?.value} placeholder="Search" />
//           <img src={search} style={{ opacity: '40%', height: '20px', width: '20px', marginRight: '3px' }} />
//         </div>}
//     </>
//   )
// }

// export default SearchCompo;



import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import "./SearchCompo.css";

const SearchCompo = (props) => {
  return (
    // <div style={{ display: "flex", gap: "10px", backgroundColor: "white", borderRadius: "5px", maxWidth: props?.maxWidth ? props?.maxWidth : "100%", width: "100%", }}>
    //   <Input style={{ height: "49px", padding: "0.8rem 1rem", color: "#000000", fontWeight: "400", fontSize: "17px", border: "0", }} id='searchUser' onChange={(e) => { const currValue = e.target.value.trim(); props.setSearch(currValue); }} value={props.value} placeholder="Search" />
    //   <SearchOutlined style={{ margin: '15px 15px 0 0', fontSize: '18px', opacity: '40%' }} />
    // </div>


    <div style={{ display: "flex", gap: "10px", backgroundColor: "white", borderRadius: "5px", maxWidth: props?.maxWidth ? props?.maxWidth : "100%", width: "100%", }}>
      <Input className='SearchCompo_inputSearch' style={{ width: '100%' }} id='searchUser' onChange={(e) => { const currValue = e.target.value.trim(); props?.setSearch(currValue); }} value={props?.value} placeholder="Search" />
    </div>
  )
}
export default SearchCompo;