import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Layout, Button, Row, Col, Popover, Space } from 'antd';
import LogoutIcon from "../../Images/LogoutIcon.svg"
import userIcon from "../../Images/userIcon.svg"
import './Header.css'
import { useSelector } from 'react-redux';
import urls from 'credential';
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}
function Header() {
    const { width } = useWindowDimensions();
    let history = useHistory();
    const { Header } = Layout;
    const { user } = useSelector(state => state.auth)
    const [isUserImageClicked, setIsUserImageClicked] = useState(false);
    const handleUserImageClick = () => {
        setIsUserImageClicked(true);
    };

    const handleGoback = () => {
        localStorage.removeItem("control-panel-1f2f");
        // history.pushState({}, "", urls?.redirectionUrl)
        window.location.href = (urls?.redirectionUrl);
    };
    // const handleLogout = () => {
    //     localStorage.removeItem("control-panel-1f2f");
    //     window.location.reload();
    // };
    return (
        <>
            {width > 700 && (
                <div style={{ display: "flex", gap: "10px", alignItems: 'center', cursor: 'pointer' }} onClick={handleUserImageClick}>
                    <Popover
                        placement="bottomRight"
                        content={
                            <div
                                style={{ cursor: "pointer", width: "3rem", fontWeight: "550" }}
                                onClick={handleGoback}
                            >
                                <div> Logout</div>
                            </div>
                        }
                        trigger="click"
                    >
                        <Space
                            style={{ fontWeight: "550", cursor: "pointer", fontSize: "1rem" }}
                        >
                            <img
                                src={user?.image ? user?.image : userIcon}
                                style={{ height: '18px', width: '18px', }}
                            />
                            <div style={{ font: "normal normal normal 16px/25px Poppin", display: 'flex', alignItems: "center" }}>{user?.name}</div>
                        </Space>

                    </Popover>
                </div>
            )}
            {width <= 700 && (
                <>
                    <img src={user?.image ? user?.image : userIcon} style={{ height: '20px', width: '20px', marginRight: '5px' }} />
                </>
            )}

        </>
    )
}
export default Header