// import Sider from "antd/lib/layout/Sider";
import React, { useEffect, useState } from 'react';
import { Menu, Row, Col } from 'antd';
import brigoshalogo from './brigoshaLogo.svg'
import brigoshalogoblue from '../../Images/BrigoshaLogoblue.svg'
import BrigoshaIcon from '../../Images/BrigoshaIcon.svg'
import downIcon from '../../Images/downIcon.svg'
import { useHistory } from "react-router-dom";
import "./Sidebar.css";
import menu from '../../Images/menu.svg'
import eventIcon from "../../Images/eventIcon.svg"
import dashboardIcon from "../../Images/dashboardIcon.svg"
import magazineIcon from "../../Images/magazineIcon.svg"
import galaryIcon from "../../Images/gallery.svg"
import locationIcon from "../../Images/location.svg"
import blogIcon from "../../Images/blog.svg"
import blogUsers from "../../Images/blogUsers.svg"
import chevrondown from "../../Images/chevrondown.svg"
import chevronup from "../../Images/chevronup.svg"
import dashboardIconblue from '../../Images/DashboardIconblue.svg'
import mapPin from '../../Images/mapPin.svg'
import image from '../../Images/image.svg'
import book from '../../Images/book.svg'
import award from '../../Images/award.svg'
import fileText from '../../Images/fileText.svg'
import users from '../../Images/users.svg'
import { DownOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import urls from 'credential';
import Header from './Header';
import LogoutIcon from "../../Images/LogoutIcon.svg";
import classes from '../LoginPages/Login.module.css'
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}

function Sidebar() {
    const { width } = useWindowDimensions();
    const { user } = useSelector(state => state.auth);
    const [menuKey, setMenuKey] = useState("1");
    let history = useHistory();
    const { pageHighlight } = useSelector(state => state.auth)
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [isBlogMenuOpen, setBlogMenuOpen] = useState(false);

    const toggleBlogMenu = () => {
        setBlogMenuOpen(!isBlogMenuOpen);


    };
    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);

    };

    const handleGoback = () => {
        localStorage.removeItem("control-panel-1f2f");
        window.location.href = (urls?.redirectionUrl);
    };

    const [dashboardImg, setDashboardImg] = useState(dashboardIcon)
    const [eventImg, setEventImg] = useState(eventIcon)
    const [magazineImg, setMagazineImg] = useState(magazineIcon)
    const [galleryImg, setGalleryImg] = useState(galaryIcon)
    const [locationImg, setLocationImg] = useState(locationIcon)
    const [blogImg, setBlogImg] = useState(blogIcon)
    const [blogUserImg, setBlogUserImg] = useState(blogUsers)

    const [items, setItems] = useState([
        {
            key: "1",
            label: <div className="sidebar"> <img src={dashboardImg} /><div className='sidebarheading' style={{ fontFamily: 'poppins', }}>Dashboard</div></div>,
        },
        {
            key: "2",
            label: <div className="sidebar"> <img src={eventImg} /><div className='sidebarheading' style={{ fontFamily: 'poppins', }}>Events</div></div>,
        },
        {
            key: "3",
            label: <div className="sidebar"> <img src={magazineImg} /><div className='sidebarheading' style={{ fontFamily: 'poppins', }}>E-Magazine</div></div>,
        },
        {
            key: "5",
            label: <div className="sidebar"> <div><img src={galleryImg} /></div><div className='sidebarheading' style={{ fontFamily: 'poppins', }}>Gallery</div></div>,
        },
        {
            key: "6",
            label: <div className="sidebar"> <img src={locationImg} /><div className='sidebarheading' style={{ fontFamily: 'poppins', }}>Location</div></div>,
        }
    ]);

    useEffect(() => {
        console.log("useeffect ", user)
        if (user?.privilege === "SuperAdmin" && user?.platform === "Brigosha") {
            setItems([...items,
            {
                key: "sub",
                label: <div className="sidebar"> <img src={blogIcon} /><div className='sidebarheading' style={{ fontFamily: 'poppins', }}>Blog</div></div>,
                children: [
                    {
                        key: "10",
                        label: <div className="sidebar"><div className='sidebarheading' style={{ fontFamily: 'poppins', }}>Brigosha Website</div></div>,
                    },
                    {
                        key: "11",
                        label: <div className="sidebar"><div className='sidebarheading' style={{ fontFamily: 'poppins', }}>Aulas Website</div></div>,
                    },
                    {
                        key: "12",
                        label: <div className="sidebar"><div className='sidebarheading' style={{ fontFamily: 'poppins', }}>Vector Website</div></div>,
                    },
                ],
            },
            {
                key: "7",
                label: <div className="sidebar"> <img src={blogUsers} /><div className='sidebarheading' style={{ fontFamily: 'poppins', }}>Blog Users</div></div>,
            }

            ])

        }
        else if (user?.privilege === "Admin") {
            if (user?.platform === "Brigosha") {
                setItems([...items,
                {
                    key: "10",
                    label: <div className="sidebar"><img src={blogIcon} /><div className='sidebarheading' style={{ fontFamily: 'poppins', }}>Blog</div></div>,
                }
                ])
            }
            else if (user?.platform === "Aulas") {
                setItems([]);
                setMenuKey("11");
            }
            else if (user?.platform === "Vector") {
                setItems([]);
                setMenuKey("12");
            }
        }
        else if (user?.privilege === "Blogger") {
            setItems([{
                key: '4',
                label: <div className="sidebar" style={{ backgroundColor: '#e6f7ff' }}><img src={dashboardIcon} /><div style={{ fontWeight: '500', fontFamily: 'poppins', fontSize: '16px', color: '#0086FF', }}>Dashboard</div></div>,
            }])
            setMenuKey("4");
        }
        console.log("item", items)
    }, [])

    {/* <div style={{display:'flex',justifyContent:"space-between",alignItems:'center'}}> <div className="sidebar"><img src={blogIcon} /><div>Blog</div> </div> <DownOutlined /> </div>, */ }
    console.log("ameer", window.location.pathname)
    useEffect(() => {
        let href = pageHighlight
        console.log("ameer", href)
        if (href === "/") {
            setMenuKey("1")

        }
        else if (href === "/events") {
            setMenuKey("2")
        }
        else if (href === "/magazine") {
            setMenuKey("3")
        }
        else if (href === "/blog") {
            setMenuKey("4")
        }
        else if (href === "/gallery") {
            setMenuKey("5")
        }
        else if (href === "/location") {
            setMenuKey("6")
        }
        else if (href === "/blog-users") {
            setMenuKey("7")
        }
        else if (href === "/brigosha-blog") {
            setMenuKey("10")
        }
        else if (href === "/aulas-blog") {
            setMenuKey("11")
        }
        else if (href === "/vector-blog") {
            setMenuKey("12")
        }

    }, [pageHighlight])

    useEffect(() => {
        console.log("clicked")
        clickHandler()
    }, [dashboardImg, eventImg, magazineImg, galleryImg, locationImg, blogImg, blogUserImg])

    const clickHandler = (key) => {
        switch (key) {
            case "1":
                history.push("/")
                console.log("imgss")
                setMenuKey("1")
                setDashboardImg(dashboardIconblue)
                break;

            case "2":
                history.push("/events")
                setMenuKey("2")
                setEventImg(award)
                break;

            case "3":
                history.push("/magazine")
                setMenuKey("3")
                setMagazineImg(fileText)
                break;

            case "4":
                history.push("/blog")
                setMenuKey("4")
                setBlogImg(book)
                break;

            case "5":
                history.push("/gallery")
                setMenuKey("5")
                setGalleryImg(image)
                break;
            case "6":
                history.push("/location")
                setMenuKey("6")
                setLocationImg(mapPin)
                break;
            case "7":
                history.push("/blog-users");
                setMenuKey("7");
                setBlogUserImg(users)
                break;
            case "10":
                history.push("/brigosha-blog");
                setMenuKey("10");
                break;
            case "11":
                history.push("/aulas-blog");
                setMenuKey("11");
                break;
            case "12":
                history.push("/vector-blog");
                setMenuKey("12");
                break;
        }
        if (key === "sub") {
            toggleBlogMenu();
        }

        setSidebarOpen(false)

    }

    return (
        <>

            {width > 700 &&
                <div className='sideBar' style={{ height: '97%', }}>
                    <div className='logo'>
                        <img
                            src={brigoshalogoblue}
                            alt="Brigosha Technologies"
                            style={{ width: "100%", height: '100%' }}
                        />
                    </div>

                    <Menu
                        theme="white"
                        mode="inline"
                        style={{ padding: '15px', height: '70vh', overflowY: 'scroll', overflowX: 'hidden', borderTop: '1px solid #DBDBDB', marginTop: '8px' }}
                        selectedKeys={[menuKey]}
                        onClick={(key) => { clickHandler(key.key) }}
                        items={items}
                        className='Sidebar-children'
                    />
                    <div className='footer' style={{}}>
                        {/* <div>
                    <img
                        src={brigoshalogo}
                        alt="Brigosha Technologies"
                        style={{ width: 30, }}
                    />
                </div> */}

                        <div style={{ color: '#C4C4C4', font: "normal normal bold 14px/19px Open Sans" }}>Powered by </div>
                        <div style={{ 'color': '#0086FF', font: "normal normal bold 14px/19px Open Sans" }}> Brigosha Technologies</div>
                    </div>

                </div>
            }


            {/* {
                user?.privilege !== "Blogger" &&
                width > 700 && (
                    <div className='sideBar' style={{ height: '97%', }}>
                        <>
                            <div className='logo'>
                                <img
                                    src={brigoshalogo}
                                    alt="Brigosha Technologies"
                                    style={{ width: "100%", height: '100%' }}
                                />
                            </div>

                            <Menu
                                theme="white"
                                mode="inline"
                                style={{ padding: '15px', height: '70vh', overflowY: 'scroll', overflowX: 'hidden', borderTop: '1px solid #DBDBDB', marginTop: '8px' }}
                                selectedKeys={[menuKey]}
                                onClick={(key) => { clickHandler(key.key) }}
                                items={items}
                                className='Sidebar-children'
                            />
                            {user?.privilege !== "Blogger" && (
                                <div className='footer' style={{}}>
                                    <div style={{ color: '#C4C4C4', font: "normal normal bold 14px/19px Open Sans" }}>Powered by </div>
                                    <div style={{ 'color': '#0086FF', font: "normal normal bold 14px/19px Open Sans" }}> Brigosha Technologies</div>
                                </div>
                            )}
                        </>

                    </div>
                )} */}
            {width <= 700 ? (
                <>
                    <div style={{ display: 'flex', alignItems: 'center', height: '30px', width: '100vw', justifyContent: 'space-between', marginTop: '7px', marginBottom: '10px', }} >
                        <img src={menu} alt="overview" style={{ height: "16px", width: "16px", marginLeft: '10px' }} onClick={toggleSidebar} />
                        <img src={BrigoshaIcon} alt="Brigosha Technologies" style={{ width: "40px", height: '35px', marginRight: '3px' }} />
                    </div>
                    {isSidebarOpen && (
                        <div style={{ height: '99vh', width: '100vw', backgroundColor: 'white', zIndex: '2' }} className="mobileSidebar">
                            <div style={{ backgroundColor: 'white', height: '70vh', width: '100vw', padding: '15px', overflowY: 'scroll', overflowX: 'hidden', borderTop: '1px solid #DBDBDB', marginTop: '8px', position: 'relative', zIndex: '2' }}>
                                {items.map(item => (
                                    <div style={{ marginBottom: '15px', }}
                                        key={item.key}
                                        onClick={() => clickHandler(item.key)}
                                        className={menuKey === item.key ? 'selectedMenuItem' : 'menuItem'}
                                    >
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                                            {item.label}
                                            {item.children ? (
                                                <img
                                                    src={isBlogMenuOpen ? chevronup : chevrondown}
                                                    alt="dropdown"
                                                    style={{ height: "15px", width: "18%", }}
                                                />
                                            ) : null}
                                        </div>
                                        {item.key === "sub" && isBlogMenuOpen && (
                                            <div style={{ paddingLeft: '35px', marginTop: '5px' }}>
                                                {item.children.map(childItem => (
                                                    <div style={{ marginBottom: '8px', fontFamily: 'poppins' }}
                                                        key={childItem.key}
                                                        onClick={() => clickHandler(childItem.key)}
                                                        className={menuKey === childItem.key ? 'selectedMenuItem' : 'menuItem'}
                                                    >
                                                        {childItem.label}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div onClick={handleGoback}>
                                    <img style={{ width: '19px', height: '19x', marginRight: '19px' }} src={LogoutIcon} />
                                    <span style={{ color: '#FF6969', fontFamily: 'poppins' }}>LogOut</span>
                                </div>
                            </div>

                            <div className={classes.poweredBy}>
                                <div style={{ color: '#7D7D7D', fontFamily: 'poppins', fontSize: '14px' }}>Powered by</div>
                                <div>
                                    <div>Brigosha Technologies</div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : null}
        </>
    )
}
export default Sidebar;