import { Button, Col, Form, Input, message, Modal, Row, Table, Typography, Upload, Dropdown, Menu, Pagination, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { EyeOutlined, EditOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import Api from "services/Api";
import Notification from "services/Notification";
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "component/store";
import addIcon from "../../Images/addIcon.svg";
import Openweb from "../../Images/Openweb.svg";
import moreVertical from "../../Images/moreVertical.svg";
import trashnew from "../../Images/trashnew.svg";
import classes from '../Styles.module.css';
import { LoadingOutlined } from '@ant-design/icons';
import x from "../../Images/x.svg"
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}

function Dashboard() {
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    dispatch(authActions.updatePageHighlight(window.location.pathname));
    const [modal, setModal] = useState(false)
    const [edit, setEdit] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [editData, setEditData] = useState()
    const [deleteData, setDeleteData] = useState({})
    const [fileList, setFileList] = useState([]);
    const [loader, setLoader] = useState(false)
    const [toggle, setToggle] = useState(false)
    const [dashboardData, setdashboardData] = useState([])
    const [btnLoader, setBtnLoader] = useState(false)
    const { token } = useSelector(state => state.auth);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [publish, setPublish] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const { Paragraph, Text } = Typography;

    // Calculate the indexes of the items to be displayed on the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dashboardData?.slice(indexOfFirstItem, indexOfLastItem);
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => {
        setShowModal(!showModal);
    };
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePreview = (file) => {
        setPreviewImage(file.url || file.thumbUrl);
        setPreviewVisible(true);
    };

    const handleCancelPreview = () => {
        setPreviewVisible(false);
    };
    // const togglePublish = (record) => {
    //     const updatedRecord = { ...record, publish: !record.publish };
    //     updateRecord(updatedRecord);
    //     const updatedData = data.map((item) => {
    //         if (item.id === record.id) {
    //             return updatedRecord;
    //         }
    //         return item;
    //     });
    //     setData(updatedData);
    // };
    const itemspop = (record) => (
        <Menu>
            <Menu.Item key="1">
                <div className="editBtn" onClick={() => {
                    setEditData(record); setEdit(true); console.log("editData", record, editData);
                    let obj = [{ url: record?.gallerySlide[0]?.locationPath }]; setFileList(obj)
                }}><EditOutlined /> Edit</div>
            </Menu.Item>
            <Menu.Item key="2">
                <div className="deleteBtn" onClick={() => { setDeleteData(record); setDeleteModal(true); }}>
                    <img src={trashnew} style={{ width: '15px', height: '15px' }} /> Delete</div>
            </Menu.Item>
        </Menu>
    )

    // const columns = [
    //     {
    //         title: () => <b style={{ color: 'black' }}>Title</b>,
    //         dataIndex: 'image',
    //         key: 'image',
    //         width: '15vw',
    //         fontFamily: 'inherit',
    //         render: (text, record) => {
    //             return (
    //                 <Row style={{ width: '20vw', height: 'fit-content', }} >
    //                     <Col style={{ width: '45px', height: '45px', marginRight: '5px', }}><img src={record?.gallerySlide[0]?.locationPath} style={{ width: "100%", height: '100%', borderRadius: '5px' }}></img> </Col>
    //                     <Col style={{ display: 'block', width: '75%' }}>
    //                         <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "150px", fontSize: '14px', fontWeight: '500', fontWeight: '600' }}>  {record?.title}</Typography.Text>
    //                         <div style={{ color: 'grey' }}>Posted Date: {moment(record?.updated_at).format("MMM D, YYYY")}</div>
    //                     </Col>
    //                 </Row>
    //             )
    //         }
    //     },
    //     {
    //         title: () => <b style={{ color: 'black' }}>Description</b>,
    //         dataIndex: 'description',
    //         key: "description",
    //         fontFamily: 'inherit',
    //         // width: '15vw',
    //         render: (text, record) => {

    //             return (
    //                 <Row style={{ display: 'flex', width: '40vw', height: '45px', }}>
    //                     <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "100%", }}>  {record?.description}</Typography.Text>
    //                 </Row>

    //             )
    //         }
    //     },
    //     {
    //         title: () => <b style={{ color: 'black' }}>Publish/Unpublish</b>,
    //         dataIndex: 'publish',
    //         key: 'publish',
    //         width: '10vw',
    //         fontFamily: 'inherit',
    //         render: (text, record) => {
    //             const isPublished = record?.publish;
    //             console.log("record", record)
    //             let dashobj = {}
    //             const handleClick = () => {
    //                 setEditData(record);
    //                 const newPublishStatus = !isPublished;
    //                 setPublish(newPublishStatus);
    //                 { console.log("jjjj", record) }
    //                 Api.patch(`/dashboard/updateSlide?id=${record?.id}`).params({ publish: newPublishStatus }).send((res, p) => {
    //                     console.log("aaaa", "res", res);
    //                     if (p === 100 && res !== undefined) {
    //                         setToggle(!toggle);
    //                         setEdit(false);
    //                     }
    //                 })
    //                 getDashboard()
    //             };
    //             return (
    //                 <Row style={{ width: '12vw', display: 'flex', gap: '38px', height: '45px', }}>
    //                     {
    //                         isPublished ?
    //                             <div style={{ padding: '14px', width: '7vw', height: '5vh', cursor: 'pointer', background: '#0086FF', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: 'white', }
    //                             }
    //                                 onClick={handleClick} >
    //                                 UNPUBLISH
    //                             </div >
    //                             :
    //                             <div style={{ border: '1px solid #0086FF', padding: '14px', width: '7vw', height: '5vh', cursor: 'pointer', background: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: '#0086FF', }}
    //                                 onClick={handleClick}>
    //                                 PUBLISH
    //                             </div>
    //                     }
    //                     <Dropdown overlay={itemspop(record)} trigger={["click"]} >
    //                         <div style={{ color: '#707070' }}>
    //                             <img src={moreVertical} style={{ width: '15px', height: '15px', cursor: "pointer", color: "#707070" }} alt="More Vertical" />
    //                         </div>
    //                     </Dropdown>
    //                 </Row >
    //             );
    //         },
    //     },
    // ];

    const getDashboard = () => {
        if (token) {
            setLoader(true)
            Api.get("/dashboard/slides").send((res, err) => {
                console.log("0000", res)
                console.log(res, err)
                setdashboardData(res);
                setLoader(false)
            })
        }
    }

    useEffect(() => {
        getDashboard()
    }, [toggle, token])

    const deleteHandler = (val) => {
        // console.log("deleteHandler",val,deleteData?.id)
        let id = deleteData?.id;
        Api.delete(`/dashboard/deleteSlide?id=${id}`).send((res) => {
            // console.log(res);
            setToggle(!toggle);
            setDeleteModal(false)
        })

    }
    const submitHandler = (val) => {
        // console.log(val?.slideImage?.fileList[0])
        console.log("999val", val)

        if (val?.title.trim().length === 0) {
            Notification.error("error", "please give a title ")
            return;
        }
        // if (val?.heading.trim().length === 0) {
        //     Notification.error("error", "please give a heading ")
        //     return;
        // }
        if (val?.description.trim().length === 0) {
            Notification.error("error", "please give a description")
            return;
        }
        if (val?.slideImage?.fileList?.length === 0) {
            Notification.error("error", "please upload a slide Image");
            return;
        }
        let type = val?.slideImage?.fileList[0]?.type;
        if (!(type === "image/jpeg" || type === "image/png")) {
            Notification.error("error", "please upload a jpg/png file ");
            return;
        }

        let data = { ...val, slideImage: val?.slideImage?.fileList[0]?.originFileObj }
        Api.post("/dashboard/saveSlide").upload(data, (percentCompleted, response) => {
            console.log("00001111", percentCompleted, response?.data?.show?.type);
            if (percentCompleted === 100 && response !== undefined) {
                if (response?.data?.show?.type === "error") {
                    console.log("0000222", percentCompleted, response);
                    setModal(true);
                }
                else {
                    setToggle(!toggle);
                    console.log("0000333", percentCompleted, response);
                    setModal(false);
                    document.getElementById("addSlideForm")?.reset();
                    setModal(false);
                    toggleModal()
                }

            }
        })
    }
    const editHandler = (val) => {
        // console.log(val)
        if (val?.title.trim().length === 0) {
            Notification.error("error", "please give a title")
            return;
        }

        if (val?.description.trim().length === 0) {
            Notification.error("error", "please give a description")
            return;
        }
        if (val?.slideImage?.fileList?.length === 0) {
            Notification.error("error", "please upload a slide image");
            return;
        }
        if (val?.slideImage?.fileList?.length === 0) {
            Notification.error("error", "please Select a event image");
            return;
        }
        let data = {};
        let id = editData?.id;
        let slideImageId = editData?.gallerySlide[0]?.id
        if (val?.title !== undefined && val?.title !== editData?.title) {

            data = { ...data, title: val?.title }
        }
        if (val?.heading !== undefined && val?.heading !== editData?.heading) {

            data = { ...data, heading: val?.heading }
        }
        if (val?.description !== undefined && val?.description !== editData?.description) {

            data = { ...data, description: val?.description }
        }
        if (val?.slideImage !== undefined) {
            let type = val?.slideImage?.fileList[0]?.type;
            if (!(type === "image/jpeg" || type === "image/png")) {
                Notification.error("error", "please upload a jpg/png file ");
                return;
            }
            data = { ...data, slideImage: val?.slideImage?.file?.originFileObj, slideImageId: slideImageId }
        }
        console.log("editHandler", data, "check name", val);
        if (Object.keys(data).length === 0) {
            Notification.success("No change", "")
            setEdit(false);
            return;
        }

        else {
            if (data.slideImage !== undefined) {
                Api.patch(`/dashboard/updateSlide?id=${id}`).uploadAndPatch(data, (p, r) => {
                    // console.log(p,r);
                    if (p === 100 && r !== undefined) {
                        setToggle(!toggle);
                        setEdit(false);
                    }
                })
            }
            else {
                Api.patch(`/dashboard/updateSlide?id=${id}`).params(data).send((res) => {
                    // console.log(res);
                    if (res !== undefined) {
                        setToggle(!toggle);
                        setEdit(false);
                    }


                })
            }

        }
    }

    return (
        <div className="container" >
            {width > 700 && (
                <Row className="firstRowDash">
                    <Col style={{ width: '45%', }} >
                        <Row>
                            <Col ><div style={{ fontSize: "24px", fontWeight: '600', marginBottom: '10px', fontFamily: 'inherit' }}> Dashboard</div> </Col>
                            <Col><div style={{ display: 'flex', width: "100%", fontWeight: '600', marginTop: '10px', marginLeft: '10px', }}>  <a href="https://new.brigosha.com/" target="_blank">visit website < img src={Openweb} style={{ width: '11px', height: '11px', marginLeft: '4px' }} /></a></div></Col>
                        </Row>
                    </Col>
                    <Col style={{ width: '50%', display: 'flex', justifyContent: 'end' }}>
                        <Button className="addbtn" onClick={() => { setModal(true) }} loading={false}> ADD SLIDES</Button>
                    </Col>
                </Row>
            )}

            {width > 700 && (
                <Row className={classes.DashboardListRow}>
                    <Row className="HeadingRow" style={{ backgroundColor: '#F8F8F8', width: '100%', display: 'flex', position: 'sticky', top: '0', zIndex: '1', }}>
                        <b style={{ width: '33%', marginLeft: '10px', marginRight: '5px' }} className={classes.Heading}>
                            Title
                        </b>
                        <b style={{ width: '46%', }} className={classes.Heading}>
                            Description
                        </b>
                        <b style={{ width: '10%', marginLeft: '27px' }} className={classes.Heading}>Publish/Unpublish</b>
                    </Row>
                    {loader ? (
                        <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (
                        <Row style={{ width: '100%', display: 'flex' }}>
                            {dashboardData?.map((data, index) => {
                                const isPublished = data?.publish;
                                console.log("record", data);
                                let dashobj = {};
                                const handleClick = () => {
                                    setEditData(data);
                                    const newPublishStatus = !isPublished;
                                    setPublish(newPublishStatus);
                                    { console.log("jjjj", data) }
                                    Api.patch(`/dashboard/updateSlide?id=${data?.id}`).params({ publish: newPublishStatus }).send((res, p) => {
                                        console.log("aaaa", "res", res);
                                        if (p === 100 && res !== undefined) {
                                            setToggle(!toggle);
                                            setEdit(false);
                                        }
                                    });
                                    getDashboard();
                                };
                                return (
                                    <div className={classes.DashboardMapDiv} key={index}>
                                        <Row style={{ width: '34%', height: '100%', display: 'flex', alignItems: 'center', gap: '6px', flexShrink: 0 }}>
                                            <Col style={{ width: '45px', height: '45px', display: 'flex', marginRight: '5px', marginLeft: '5px' }}>
                                                <img src={data?.gallerySlide[0]?.locationPath} style={{ width: '100%', height: '100%', borderRadius: '5px' }} alt="Profile" />
                                            </Col>
                                            <Col style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '80%' }}>
                                                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "90%", fontWeight: '600', fontFamily: 'inherit' }}> {data?.title}</Typography.Text>
                                                {/* <div style={{ display: 'flex', color: '#7D7D7D', fontFamily: 'inherit', fontWeight: '500' }}>Date Posted: {moment(data?.updated_at).format('lll')}</div> */}
                                                <div style={{ display: 'flex', color: '#7D7D7D', fontFamily: 'inherit', fontWeight: '500' }}>Date Posted: {moment(data?.updated_at).format('LL')}</div>
                                            </Col>
                                        </Row>

                                        <Col style={{ alignItems: 'center', height: '100%', width: '46%', display: 'flex', marginRight: '10px', justifyContent: 'flex-start', flexShrink: 0 }}>
                                            <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "90%", fontWeight: '500' }}>  {data?.description}</Typography.Text>
                                        </Col>
                                        <Col style={{ width: '10%', display: 'flex', gap: "5px", alignItems: 'center', marginLeft: '30px' }}>
                                            {isPublished ? (
                                                <div style={{ padding: '14px', width: '7vw', height: '5vh', cursor: 'pointer', background: '#0086FF', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: 'white', }} onClick={() => handleClick()}
                                                > UNPUBLISH
                                                </div>
                                            ) : (
                                                <div style={{ border: '1px solid #0086FF', padding: '14px', width: '7vw', height: '5vh', cursor: 'pointer', background: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: '#0086FF', }} onClick={() => handleClick()}
                                                > PUBLISH
                                                </div>
                                            )}
                                            <Dropdown overlay={itemspop(data)} trigger={['click']}>
                                                <div style={{ color: '#707070', display: "flex", flexShrink: 0 }}> <img src={moreVertical} style={{ width: '15px', height: '15px', cursor: 'pointer', color: '#707070' }} alt="More Vertical" />
                                                </div>
                                            </Dropdown>
                                        </Col>
                                    </div >
                                );
                            })}
                        </Row>)}
                </Row >
            )}

            {width < 700 && (
                <Row style={{ width: '100%', justifyContent: 'space-between', display: 'flex', alignItems: 'center', borderBottom: '1px solid #DBDBDB' }} >
                    <div style={{ width: '60%', display: 'flex', gap: window.innerWidth < 390 ? '0px' : '10px', flexDirection: window.innerWidth < 390 ? 'column' : 'row' }}>
                        <Col style={{ display: 'flex', alignItems: 'center' }}><div style={{ fontSize: "20px", fontWeight: '500', fontFamily: 'poppins' }}> Dashboard</div> </Col>
                        <Col style={{ display: 'flex', alignItems: 'center' }}><div style={{ display: 'flex', alignItems: 'center', fontWeight: '600', }}>  <a href="https://new.brigosha.com/" target="_blank">visit website < img src={Openweb} style={{ width: '11px', height: '11px', marginLeft: '4px' }} /></a></div></Col>
                    </div>
                    <Button className="addnewbtndash" onClick={toggleModal} loading={false}> ADD SLIDES</Button>
                </Row>
            )}

            {width < 700 && (
                <Row className={classes.DashboardListRow}>
                    {loader ? (
                        <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (
                        <Row style={{ width: '100%', display: 'flex', height: '75vh', overflowY: 'scroll' }}>
                            {dashboardData?.map((data, index) => {
                                const isPublished = data?.publish;
                                console.log("record", data);
                                let dashobj = {};
                                const handleClick = () => {
                                    setEditData(data);
                                    const newPublishStatus = !isPublished;
                                    setPublish(newPublishStatus);
                                    { console.log("jjjj", data) }
                                    Api.patch(`/dashboard/updateSlide?id=${data?.id}`).params({ publish: newPublishStatus }).send((res, p) => {
                                        console.log("aaaa", "res", res);
                                        if (p === 100 && res !== undefined) {
                                            setToggle(!toggle);
                                            setEdit(false);
                                        }
                                    });
                                    getDashboard();
                                };
                                return (
                                    <div className={classes.DashboardDivMobile} key={index}>
                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '15px', height: '45%', marginBottom: '5px' }}>
                                            <Col style={{ width: '20%', height: '100%' }}>
                                                <img src={data?.gallerySlide[0]?.locationPath} style={{ width: '60px', height: '60px', borderRadius: '5px' }} alt="Profile" />
                                            </Col>
                                            <Col style={{ width: '80%', height: '100%' }}>
                                                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "90%", fontWeight: '600', fontFamily: 'poppins' }}> {data?.title}</Typography.Text>
                                                <div style={{ display: 'flex', color: '#7D7D7D', fontFamily: 'poppins', fontWeight: '500', fontSize: '12px' }}>Date Posted: {moment(data?.updated_at).format('LL')}</div>
                                            </Col>
                                        </div>
                                        <div style={{ height: '35%' }}>
                                            <Paragraph style={{ fontWeight: '500', fontFamily: 'poppins', fontSize: '12px' }} ellipsis={true ? { rows: 2, symbol: '...', fontSize: 16, } : false}>{data?.description}</Paragraph>
                                            {/* <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "90%", fontWeight: '500' }}>  {data?.description}</Typography.Text> */}
                                        </div>
                                        <div style={{ width: '100%', height: '19%', display: 'flex', }} >
                                            <Col style={{ width: '85%', display: 'flex', justifyContent: 'space-between' }}>
                                                {isPublished ? (
                                                    <div style={{ height: '100%', border: '1px solid #0086FF', padding: '4vw 5vw', width: '40%', cursor: 'pointer', background: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: '#0086FF', }} onClick={() => handleClick()}> UNPUBLISH
                                                    </div>
                                                ) : (
                                                    <div style={{ height: '100%', padding: '4vw 5vw', width: '40%', cursor: 'pointer', background: '#0086FF', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: 'white', }} onClick={() => handleClick()}
                                                    > PUBLISH
                                                    </div>
                                                )}
                                            </Col>
                                            <Col style={{ width: '12%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} >
                                                <Dropdown overlay={itemspop(data)} trigger={['click']}>
                                                    <div style={{ color: '#707070', display: "flex", flexShrink: 0 }}> <img src={moreVertical} style={{ width: '20px', height: '20px', cursor: 'pointer', color: '#707070' }} alt="More Vertical" />
                                                    </div>
                                                </Dropdown>
                                            </Col>

                                        </div>
                                    </div >
                                );
                            })}
                        </Row>)
                    }
                    {width > 700 && <Col style={{ width: '100vw', display: 'flex', justifyContent: 'center', height: '10%', }}>
                        <Button className="addnewbtn" onClick={() => { setModal(true) }} loading={false}><img style={{ width: '12px', height: '12px', marginRight: '8px' }} src={addIcon} /> ADD SLIDES</Button>
                    </Col>}
                    {/* {width < 700 && <Col style={{ width: '100vw', display: 'flex', justifyContent: 'center', height: '10%', }}>
                        <Button className="addnewbtn" onClick={toggleModal} loading={false}><img style={{ width: '12px', height: '12px', marginRight: '8px' }} src={addIcon} /> ADD SLIDES</Button>
                    </Col>} */}
                </Row >
            )
            }
            {width > 700 && <Modal bodyStyle={{ height: '73vh', }}
                visible={modal} onCancel={() => { setModal(false); }} footer={false} className='model' key={Math.random()} >
                <div className="headline">Add Slides</div>
                <Form style={{ overflow: "scroll", height: '88%', padding: '7px' }} layout='vertical' onFinish={submitHandler} id="addSlideForm">
                    <Form.Item label="Title" name="title" rules={[{ required: true, message: "Please enter title", },]}>
                        <Input placeholder="enter title "></Input>
                    </Form.Item>
                    <Form.Item label="Description" name="description" rules={[{ required: true, message: "Please enter description", },]}>
                        <Input.TextArea label="enter description" rows={4}></Input.TextArea>
                    </Form.Item>
                    <Form.Item label="Image" name="slideImage" rules={[{ required: true, message: "Please upload a slide image", },]}>
                        <Upload
                            type="image"
                            accept=".jpg, .png"
                            action="https://testassociate.brigosha.com"
                            listType="picture"
                            maxCount={1}
                            status="success"
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Row style={{ width: '25vw', }}>
                        <Form.Item style={{ width: '100%', }}>
                            <Col style={{ width: '50%' }}>
                                <Button block={true} htmlType="submit" style={{ justifyContent: 'center', width: '100%', display: 'flex', alignItems: 'center', backgroundColor: '#0086FF', font: 'normal normal bold 14px/18px Open Sans', color: 'white', borderRadius: '5px', height: '2vh', cursor: 'pointer', padding: '18px' }} loading={btnLoader}>Add Slides</Button>
                            </Col>
                            <Col style={{ width: '40%', marginLeft: '14vw', marginTop: '-6vh' }}>
                                <Button style={{ justifyContent: 'center', width: '100%', display: 'flex', alignItems: 'center', backgroundColor: 'white', font: 'normal normal bold 14px/18px Open Sans', color: 'black', borderRadius: '5px', height: '2vh', cursor: 'pointer', padding: '18px' }} block onClick={() => { document.getElementById("addSlideForm").reset(); setModal(false) }} >Discard</Button>
                            </Col>
                        </Form.Item>
                    </Row>

                </Form>
            </Modal>}

            {showModal && width < 700 && (
                <div className="custom-modal-container" style={{ padding: '0px 20px 0px 20px', width: '90%', height: '73vh', zIndex: '1000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', justifyContent: 'center', alignItems: 'center', background: 'white', display: 'flex', boxShadow: 'rgb(22 23 23 / 77%) 5px 0px 48px 137px', borderRadius: '4px' }}>
                    <div className="modal-content" style={{ width: '100%', }}>
                        <div style={{ borderBottom: ' 1px solid #CCCCCC', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                            <div style={{ fontFamily: 'poppins', fontSize: '18px', fontWeight: '600', }}>Add Slides</div>
                            <img src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={toggleModal} />
                        </div>
                        <div className="modal-body" style={{ overflow: 'auto', maxHeight: 'calc(80vh - 2 * 20px - 45px)' }}>
                            <Form style={{ overflow: "scroll", height: '88%', padding: '7px' }} layout='vertical' onFinish={submitHandler} id="addSlideForm">
                                <Form.Item label="Title" name="title" rules={[{ required: true, message: "Please enter title", },]}>
                                    <Input placeholder="enter title "></Input>
                                </Form.Item>
                                <Form.Item label="Description" name="description" rules={[{ required: true, message: "Please enter description", },]}>
                                    <Input.TextArea label="enter description" rows={4}></Input.TextArea>
                                </Form.Item>
                                <Form.Item label="Image" name="slideImage" rules={[{ required: true, message: "Please upload a slide image", },]}>
                                    <Upload
                                        type="image"
                                        accept=".jpg, .png"
                                        action="https://testassociate.brigosha.com"
                                        listType="picture"
                                        maxCount={1}
                                        status="success"
                                    >
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </Form.Item>
                                <Form.Item style={{ width: '55vw', display: 'flex', marginBottom: '1px' }}>
                                    <Button block={true} htmlType="submit" style={{ width: 'fit-content', padding: '1px 8px 1px', marginBottom: '10px', backgroundColor: '#0086FF', color: 'white', borderRadius: '3px', marginRight: '5px' }} loading={btnLoader}>Add Slides</Button>
                                    <Button style={{ padding: '1px 8px 1px', width: 'fit-content', }} block onClick={() => { document.getElementById("addSlideForm").reset(); }}
                                    >Discard</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            )}


            {width > 700 && <Modal bodyStyle={{ height: '83vh' }} open={edit} onCancel={() => { setEdit(false); setEditData({}); }} footer={false} key={Math.random()} >
                <div className="headline">Edit Slides</div>
                <Form style={{ overflow: 'scroll', height: '88%' }} layout="vertical" onFinish={editHandler} id="editForm" initialValues={editData}>
                    {/* <Form.Item label="Heading" name="heading" rules={[{ required: true, message: "Please enter heading" }]}>
                        <Input placeholder="Enter heading" />
                    </Form.Item> */}
                    <Form.Item label="Title" name="title" rules={[{ required: true, message: "Please enter title" }]}>
                        <Input placeholder="Enter title" />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[{ required: true, message: "Please enter description" }]}
                    >
                        <Input.TextArea label="Enter description" rows={4} />
                    </Form.Item>

                    <Form.Item
                        label={
                            <span>
                                Image
                            </span>
                        }
                        name="slideImage"
                        rules={[
                            ({ getFieldValue }) => ({
                                required: getFieldValue('slideImage') ? true : false,
                                message: "Please upload an image",
                            }),
                        ]}
                    >
                        <Upload
                            accept=".jpg, .png"
                            action="https://testassociate.brigosha.com"
                            listType="picture"
                            maxCount={1}
                            defaultFileList={[...fileList]}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item style={{ width: '22vw', display: 'flex', marginBottom: '1px' }}>
                        <Button block htmlType="submit" style={{ marginBottom: '10px', width: '9vw', backgroundColor: '#0086FF', color: 'white', borderRadius: '5px', width: 'fit-content', marginRight: '5px' }}>
                            Save Changes
                        </Button>
                        <Button style={{ width: 'fit-content' }} block onClick={() => { document.getElementById("editForm").reset(); setEdit(false) }}>
                            Discard
                        </Button>
                    </Form.Item>
                </Form>
            </Modal >}

            {width < 700 && edit && (
                <div className="custom-modal-container" style={{ padding: '0px 20px 0px 20px', width: '90%', height: '80vh', zIndex: '1000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', justifyContent: 'center', alignItems: 'center', background: 'white', display: 'flex', boxShadow: 'rgb(22 23 23 / 77%) 5px 0px 48px 137px', borderRadius: '4px' }}>
                    <div className="modal-content" style={{ width: '100%' }}>
                        <div style={{ borderBottom: ' 1px solid #CCCCCC', marginTop: '-15px', marginBottom: '12px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ fontFamily: 'poppins', fontSize: '18px', fontWeight: '600', }}>Edit Slides</div>
                            <img src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={() => { setEdit(false) }} />
                        </div>
                        <div className="modal-body" style={{ overflow: 'auto', maxHeight: 'calc(80vh - 2 * 20px - 45px)' }}>
                            <Form style={{ overflow: 'scroll', height: '88%' }} layout="vertical" onFinish={editHandler} id="editForm" initialValues={editData}>
                                {/* <Form.Item label="Heading" name="heading" rules={[{ required: true, message: "Please enter heading" }]}>
                        <Input placeholder="Enter heading" />
                    </Form.Item> */}
                                <Form.Item label="Title" name="title" rules={[{ required: true, message: "Please enter title" }]}>
                                    <Input placeholder="Enter title" />
                                </Form.Item>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[{ required: true, message: "Please enter description" }]}
                                >
                                    <Input.TextArea label="Enter description" rows={4} />
                                </Form.Item>

                                <Form.Item
                                    label={
                                        <span>
                                            Image
                                        </span>
                                    }
                                    name="slideImage"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            required: getFieldValue('slideImage') ? true : false,
                                            message: "Please upload an image",
                                        }),
                                    ]}
                                >
                                    <Upload
                                        accept=".jpg, .png"
                                        action="https://testassociate.brigosha.com"
                                        listType="picture"
                                        maxCount={1}
                                        defaultFileList={[...fileList]}
                                    >
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </Form.Item>
                                <Form.Item style={{ width: '55vw', display: 'flex', marginBottom: '1px' }}>
                                    <Button block htmlType="submit" style={{ padding: '1px 8px 1px', marginBottom: '10px', backgroundColor: '#0086FF', color: 'white', borderRadius: '3px', width: 'fit-content', marginRight: '5px' }}>
                                        Save Changes
                                    </Button>
                                    <Button style={{ width: 'fit-content', padding: '1px 8px 1px', }} block onClick={() => { document.getElementById("editForm").reset(); setEdit(false) }}>
                                        Discard
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            )}

            <Modal open={deleteModal} onCancel={() => { setDeleteModal(false) }} footer={false} closable={false} id="delete" bodyStyle={{ width: window.innerWidth < 350 ? `${window.innerWidth - 20}px` : window.innerWidth < 700 ? 'fit-content' : '600px', backgroundColor: 'white', borderRadius: '5px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}><CloseOutlined size="large" onClick={() => { setDeleteModal(false) }} style={{ font: 20, cursor: "pointer", fontSize: 16, opacity: '0.7' }} /></div>
                <div style={{ 'display': 'flex', 'flexDirection': 'column', 'gap': '10px', }}>
                    <div className="modelTitle">Are you Sure ?</div>
                    <div className="modelInfo">Do you really want to delete the slide? This process cannot be undone.</div>
                    <div className="modelBtn">
                        <Button className="modelCancelBtn" onClick={() => { setDeleteModal(false) }}>Cancel</Button>
                        <Button onClick={() => { deleteHandler(); }} className='modelDeleteBtn'>Delete</Button>
                    </div>
                </div>
            </Modal>

        </div >
    )
};
export default Dashboard;