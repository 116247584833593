import { createSlice } from "@reduxjs/toolkit";





const initialState = {
    user:null,
    token:null,
    pageHighlight:"",
};
const authUpdate=createSlice({
    name: "auth",
    initialState,
    reducers:{
            updateUser(state,action){
                state.user=action.payload;
            },
            updateToken(state,action){
                state.token=action.payload;
            },
            updatePageHighlight(state,action){
                state.pageHighlight=action.payload
            }
        // function
    }
})
export default authUpdate.reducer;
export const authActions = authUpdate.actions;

