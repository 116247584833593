import { Button, Col, Form, Input, Modal, Row, Select, Table, Tooltip, Typography, Upload, Tag, Spin, Menu } from "antd";
import { EditOutlined, DeleteOutlined, UploadOutlined, PlusOutlined, CloudUploadOutlined, EyeOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { Pie } from "@ant-design/plots";
import Api from "services/Api";
import Notification from "services/Notification";
import BlogDetails from "./BlogDetails";
import SearchCompo from "component/searchCompo";
import Paragraph from "antd/lib/typography/Paragraph";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { authActions } from "component/store";
import addIcon from "../../Images/addIcon.svg";
import mail from "../../Images/mail.svg";
import phone from "../../Images/phone.svg";
import qs from 'qs';
import TagInput from "component/TagInput";
import classes from '../Styles.module.css';
import { LoadingOutlined } from '@ant-design/icons';
import chevrondown from "../../Images/chevrondown.svg"
import chevronup from "../../Images/chevronup.svg"
import x from "../../Images/x.svg"
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}

function Blog({ company }) {
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    dispatch(authActions.updatePageHighlight(window.location.pathname))
    const [tagData, setTagData] = useState([]);
    const [modal, setModal] = useState(false)
    const [edit, setEdit] = useState(false)
    const [editData, setEditData] = useState()
    const [section, setSection] = useState("Approved");
    const [sectionData, setSectionData] = useState();
    const [tableLoader, setTableLoader] = useState(false)
    const [preview, setPreview] = useState(false);
    const [previewData, setPreviewData] = useState();
    const [toggle, setToggle] = useState(false);
    const [search, setSearch] = useState("")
    const [platform, setPlateform] = useState('');
    const [editClicked, setEditClicked] = useState(false);
    const { user } = useSelector(state => state.auth);
    const [loader, setLoader] = useState(true);
    const [showDropdown, setShowDropdown] = useState(true);
    const [selectedSection, setSelectedSection] = useState("Approved");
    const [isIconUp, setIsIconUp] = useState(false);
    console.log("showwww", showDropdown)
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
        setIsIconUp(!isIconUp);
    };

    const [showModal, setShowModal] = useState(false);

    const [formData, setFormData] = useState({
        title: '',
        tag: [],
        image: [],
    });

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const fileList = [
        {
            // uid: '-1',
            // name: 'yyy.png',
            // status: 'done',
            // url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            // thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        }, ,]
    const [tableParams, setTableParams] = useState({
        pagination: {
            showSizeChanger: true,
            current: 1,
            pageSize: 10,
        },
    });
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [publish, setPublish] = useState(false);
    const form = new FormData();
    const [tags, setTags] = useState([])
    let blogData = { previewData }
    console.log("0000preview", previewData, "0000Bolgdata", blogData)
    const handleTagInputChange = (e) => {
        const { value } = e.target;
        const tag = value.trim();
        if (tag && !tags.includes(tag)) {
            setTags([...tags, tag]);
        }
    };

    const handleTagClose = (removedTag) => {
        const updatedTags = tags.filter((tag) => tag !== removedTag);
        setTags(updatedTags);
    };
    const handlePreview = (file) => {
        setPreviewImage(file.url || file.thumbUrl);
        setPreviewVisible(true);
    };

    const handleCancelPreview = () => {
        setPreviewVisible(false);
    };
    const props = {
        name: 'file',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        headers: {
            authorization: 'authorization-text',
        }
    };
    useEffect(() => {
        setPlateform(window.location.pathname)
    }, [window.location.pathname])
    useEffect(() => {
        setLoader(true)
        // setTableLoader(true);
        Api.get(`/blog/blogView?${qs.stringify(getRandomuserParams(tableParams))}`).params({ status: section, company: company, search: search }).send((res) => {
            console.log("0000resss", res)
            setSectionData(res?.blogsWithData);
            // setTableLoader(false)
            setLoader(false)
            // setTableParams({
            //     ...tableParams,
            //     pagination: {
            //         current: 1,
            //         pageSize: 10,
            //         total: res?.meta?.totalItems,
            //     },
            // });
        })
    }, [section, toggle, search])
    let count = 0;
    useEffect(() => {
        setLoader(true)
        setTableLoader(true);
        // ?${qs.stringify(getRandomuserParams(tableParams))}
        Api.get(`/blog/blogView?${qs.stringify(getRandomuserParams(tableParams))}`).params({ status: section, company: company, search: search }).send((res) => {
            console.log("0000resss kkkkjjjj", res)
            setSectionData(res?.blogsWithData);
            setTableLoader(false)
            setLoader(false)

            // setTableParams({
            //     ...tableParams,
            //     pagination: {
            //         ...tableParams.pagination,
            //         total: res?.meta?.totalItems,
            //     },
            // });
        })
    }, [JSON.stringify(tableParams)])
    // JSON.stringify(tableParams)
    let isImagePresent = true;
    const handleRemove = () => {
        // Notification.error('Upload image');
        isImagePresent = false;
    };

    const getRandomuserParams = (params) => ({
        limit: params.pagination?.pageSize,
        page: params.pagination?.current,

    });
    const handleTableChange = (pagination,) => {
        console.log(pagination)
        setTableParams({
            pagination,
        });
    };

    const submitHandler = (val) => {
        console.log("halleluiah", val?.image)

        if (!isImagePresent) {
            Notification.error('Please upload an image');
            return;
        }
        if (val?.tag?.length > 3) {
            Notification.error("error", "you can not add more than 3 tags");
            return;
        }
        let flag = false;
        val?.tag?.forEach((item) => {
            if (item?.length > 30) {
                flag = true;
                return;
            }
        })
        if (flag) {
            Notification.error("error", "Tag length should be less than 30 characters")
            return;
        }
        let data = { title: val?.title, tags: [val?.tag], coverImage: val?.image?.fileList[0]?.originFileObj };
        if (user?.privilege === "SuperAdmin") {
            let company = platform === "/brigosha-blog" ? "Brigosha" : platform === "/aulas-blog" ? "Aulas" : platform === "/vector-blog" ? "Vector" : "";
            data = { ...data, company: company }
        }
        console.log("submithandler", val, data)
        setEditClicked(true)
        setPreviewData({ blogTitle: val?.title, coverImage: val?.image?.fileList[0]?.thumbUrl, tags: val?.tag, company: company, blogId: "new", image: val?.image?.fileList[0]?.originFileObj })
        setPreview(true);
        setSection("Drafts");
        toggleDropdown();
        setModal(false);
        toggleModal()
    }

    const dummy = [
        { tags: ["tag1", "tag2"], id: 1, CoverImage: { id: 1, url: "https://ibelong-library.s3.ap-south-1.amazonaws.com/files/profile.jpg" }, title: "jai mahakal", publisher: "brahmanandAM", publisherDate: "22-0-23", blogContent: "<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita</p>", },
        { tags: ["tag1", "tag2"], id: 2, CoverImage: { id: 1, url: "https://ibelong-library.s3.ap-south-1.amazonaws.com/files/profile.jpg" }, title: "jai mahakal", publisher: "brahmanandAM", publisherDate: "22-0-23", blogContent: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita" },
        { tags: ["tag1", "tag2"], id: 3, CoverImage: { id: 1, url: "https://ibelong-library.s3.ap-south-1.amazonaws.com/files/profile.jpg" }, title: "jai mahakal", publisher: "brahmanandAM", publisherDate: "22-0-23", blogContent: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita" },
        { tags: ["tag1", "tag2"], id: 4, CoverImage: { id: 1, url: "https://ibelong-library.s3.ap-south-1.amazonaws.com/files/profile.jpg" }, title: "jai mahakal", publisher: "brahmanandAM", publisherDate: "22-0-23", blogContent: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita" },
        { tags: ["tag1", "tag2"], id: 5, CoverImage: { id: 1, url: "https://ibelong-library.s3.ap-south-1.amazonaws.com/files/profile.jpg" }, title: "jai mahakal", publisher: "brahmanandAM", publisherDate: "22-0-23", blogContent: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita" },
        { tags: ["tag1", "tag2"], id: 6, CoverImage: { id: 1, url: "https://ibelong-library.s3.ap-south-1.amazonaws.com/files/profile.jpg" }, title: "jai mahakal", publisher: "brahmanandAM", publisherDate: "22-0-23", blogContent: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita" },
        { tags: ["tag1", "tag2"], id: 7, CoverImage: { id: 1, url: "https://ibelong-library.s3.ap-south-1.amazonaws.com/files/profile.jpg" }, title: "jai mahakal", publisher: "brahmanandAM", publisherDate: "22-0-23", blogContent: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita" },
        { tags: ["tag1", "tag2"], id: 8, CoverImage: { id: 1, url: "https://ibelong-library.s3.ap-south-1.amazonaws.com/files/profile.jpg" }, title: "jai mahakal", publisher: "brahmanandAM", publisherDate: "22-0-23", blogContent: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita" },
    ]

    return (
        <>
            {width > 700 && (
                <div className="container">
                    {preview ? <BlogDetails setPublish={setPublish} publish={publish} editClicked={editClicked} setEditClicked={setEditClicked} setPreviewData={setPreviewData} setToggle={setToggle} toggle={toggle} blogData={previewData} setPreview={setPreview} section={section} setSection={setSection}></BlogDetails> :
                        <div style={{ height: '100%' }} >
                            <div className="firstRow">
                                <div className="headlineBlogdetails" >{platform === "/brigosha-blog" ? "Blogs Of Brigosha Website" : platform === "/aulas-blog" ? "Aulas Blogs " : platform === "/vector-blog" ? "Vector Blogs" : platform === "/blog" ? "Blog" : ""}</div>
                            </div>
                            <div className="TableDiv" style={{ height: "71vh", display: "flex", flexDirection: "column", gap: "24px", borderRadius: '15px', boxSizing: 'border-box', marginTop: '10px' }}>
                                <div className="blog-heading">
                                    <div className="blog-tabs">
                                        <div onClick={() => { setSection("Approved") }} style={{ color: section === "Approved" ? "#0086FF" : "#7E7E7E", borderBottom: section === "Approved" ? "4px solid #0086FF" : "", }} >Approved</div>
                                        <div onClick={() => { setSection("Rejected") }} style={{ color: section === "Rejected" ? "#0086FF" : "#7E7E7E", borderBottom: section === "Rejected" ? "4px solid #0086FF" : "" }} >Rejected</div>
                                        <div onClick={() => { setSection("Pending") }} style={{ color: section === "Pending" ? "#0086FF" : "#7E7E7E", borderBottom: section === "Pending" ? "4px solid #0086FF" : "" }}>Pending</div>
                                        <div onClick={() => { setSection("Drafts") }} style={{ color: section === "Drafts" ? "#0086FF" : "#7E7E7E", borderBottom: section === "Drafts" ? "4px solid #0086FF" : "" }}>Drafts</div>
                                    </div>

                                    <div style={{ display: 'flex', gap: "16px", }}>
                                        <div style={{ width: '15vw', height: '100%', }}>
                                            <SearchCompo style={{ height: '100%', }} setSearch={setSearch} value={search}></SearchCompo>
                                        </div>
                                        <div className="addbtn" style={{ width: "10vw", gap: "8px", }} onClick={() => { setModal(true) }}>
                                            <div>ADD BLOGS</div></div>
                                    </div>
                                </div>


                                <Row className={classes.DashboardListRowBlog}>
                                    <Row className="HeadingRow" style={{ backgroundColor: '#F8F8F8', width: '100%', display: 'flex', position: 'sticky', top: '0', zIndex: '1', height: 'fit-content' }}>

                                        <Col style={{ width: '30%', marginLeft: '14px' }} className={classes.Heading}>
                                            Title
                                        </Col>
                                        <Col style={{ width: '25%', }} className={classes.Heading}>
                                            Publisher
                                        </Col>
                                        <Col style={{ width: '29%', }} className={classes.Heading}>
                                            Contact Details
                                        </Col>
                                        {section === 'Approved' && (user?.privilege === 'Admin' || user?.privilege === 'SuperAdmin') ? (
                                            <Col style={{ width: '10%', }} className={classes.Heading}>Publish/Unpublish</Col>) : (
                                            <Col style={{ width: '10%', marginLeft: '10px' }} className={classes.Heading}>Preview</Col>
                                        )}
                                    </Row>
                                    {loader ? (
                                        <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center', }}> <Spin indicator={antIcon} /></Row>) :
                                        sectionData?.length > 0 ? (
                                            <Row style={{ width: '100%', display: 'flex', height: '100%', display: 'block' }}>
                                                {
                                                    sectionData?.map((mapdata, index) => {
                                                        console.log("sectionData", sectionData)
                                                        const isPublished = mapdata?.publish;
                                                        const approveHandler = (val) => {
                                                            const newPublishStatus = !isPublished;
                                                            let status = newPublishStatus ? 'Approved' : 'Rejected';
                                                            let data = { status: status, publish: newPublishStatus };
                                                            console.log("blogdatajjj", mapdata)
                                                            Api.patch(`/blog/updateBlog/?blogId=${mapdata?.blogId}`).params(data).send((res) => {
                                                                if (res?.show?.type === 'error') {
                                                                    setEditClicked(true);
                                                                    setPreview(true);
                                                                } else {
                                                                    setPreview(false);
                                                                    setToggle(!toggle);
                                                                    setSection('Approved');
                                                                    setEditClicked(false);
                                                                }
                                                            });

                                                            Api.get('/blog/singleBlog').params({ blogId: blogData?.blogId }).send((res) => {
                                                                setPreviewData(res?.blogsWithData);
                                                            });
                                                        };

                                                        return (
                                                            <div className={classes.DashboardMapDiv} key={index}>
                                                                <Row style={{ width: '29%', height: '100%', display: 'flex', alignItems: 'center', gap: '6px', marginRight: '8px', }} >
                                                                    <Col style={{ display: 'flex', cursor: 'pointer', marginLeft: '5px' }} onClick={() => { setPreview(true); setPreviewData(mapdata) }}>
                                                                        <img src={mapdata?.coverImage} alt="img" style={{ width: "45px", height: "45px", borderRadius: '5px' }}></img>
                                                                    </Col>
                                                                    <Col style={{ display: 'flex', height: '100%', width: '16vw', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                                        <div style={{ display: 'flex', width: '100%', }}>
                                                                            {/* <Typography.Text style={{ fontWeight: '600', fontFamily: 'inherit', display: 'flex', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}> */}
                                                                            <Typography.Text ellipsis={{ tooltip: true }} style={{ fontWeight: '600', fontFamily: 'inherit', maxWidth: '95%' }}>
                                                                                {mapdata?.blogTitle}</Typography.Text>
                                                                        </div>
                                                                        <div style={{ display: 'flex', width: '100%', }}>
                                                                            {section === "Pending" ? <div >{mapdata?.review === "awaiting" ? <div style={{ border: "1px solid #FFB300", width: '100px', color: '#FFB300', borderRadius: '4px', font: "normal normal normal 12px/17px Open Sans", padding: '2px' }}>Awaiting Review</div> : mapdata?.review === "completed" ? <div style={{ border: "1px solid #A17AFF", color: '#A17AFF', width: '115px', borderRadius: '4px', font: "normal normal normal 12px/17px Open Sans", padding: '2px' }}>Review Completed</div> : ""}</div> : ""}
                                                                        </div>
                                                                        <div style={{ display: 'flex', width: '100%', }}>
                                                                            {section === "Approved" ? (
                                                                                <div style={{ display: 'flex', width: '100%', fontWeight: '500', color: '#7D7D7D', fontFamily: 'inherit', }}>{mapdata?.publishedDate}</div>
                                                                            ) : section === "Rejected" ? (
                                                                                <div style={{ display: 'flex', width: '100%', fontWeight: '500', color: '#7D7D7D', fontFamily: 'inherit' }}>
                                                                                    {mapdata?.rejectedDate ? mapdata.rejectedDate : "NA"}
                                                                                </div>
                                                                            ) : (
                                                                                <div style={{ display: 'flex', width: '100%', fontWeight: '500', color: '#7D7D7D', fontFamily: 'inherit', }}>{moment(mapdata?.created).format("DD-MM-YYYY")}</div>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Col style={{ width: '25%', marginLeft: '10px', display: 'flex', }}>
                                                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '100%', fontWeight: '500' }}>{mapdata?.publisherName}</Typography.Text>
                                                                </Col>
                                                                <Col style={{ width: '30%', marginLeft: '10px', display: 'flex', gap: "5px" }}>
                                                                    <div style={{ display: 'flex', fontWeight: '500' }}> <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '100%', }}>{mapdata?.email}</Typography.Text></div>
                                                                    <div style={{ display: 'flex', fontWeight: '500' }}>{mapdata?.phone}</div>
                                                                </Col>
                                                                {section === 'Approved' && (user?.privilege === 'Admin' || user?.privilege === 'SuperAdmin') ? (
                                                                    <Col style={{ width: '12%', display: 'flex', gap: '5px', alignItems: 'center' }}>
                                                                        {console.log('junesection', section)}
                                                                        {isPublished ? (
                                                                            <div style={{ padding: '14px', width: '7vw', height: '5vh', cursor: 'pointer', background: '#0086FF', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: 'white', }} onClick={() => approveHandler(mapdata)}
                                                                            > UNPUBLISH
                                                                            </div>
                                                                        ) : (
                                                                            <div style={{ border: '1px solid #0086FF', padding: '14px', width: '7vw', height: '5vh', cursor: 'pointer', background: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: '#0086FF', }} onClick={() => approveHandler(mapdata)}>
                                                                                PUBLISH
                                                                            </div>
                                                                        )}
                                                                    </Col>) : (
                                                                    <Col style={{ width: '10%', display: 'flex', gap: '3px', height: 'fit-content', alignItems: 'center' }} className="BlogPreviewBtn" onClick={() => { setPreview(true); setPreviewData(mapdata) }}>
                                                                        <EyeOutlined />Preview
                                                                    </Col >
                                                                )}


                                                            </div>
                                                        );
                                                    })
                                                }
                                            </Row>) : (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontWeight: '600', color: '#808080', height: '65vh', width: '100%' }}>No Data</div>
                                        )}
                                </Row >
                            </div>

                            <Modal open={modal} onCancel={() => { setModal(false); }} footer={false} key={Math.random()} bodyStyle={{ height: '90vh', }}>
                                {width > 700 && (
                                    <div className="headline">Add title & image </div>
                                )}
                                {width < 700 && (
                                    <div style={{ fontFamily: 'poppins' }} className="headlineMob">Add title & image</div>
                                )}
                                <Form style={{ overflow: "scroll", height: '90%', padding: '7px' }} layout='vertical' onFinish={submitHandler} >
                                    <Form.Item
                                        label={<div className="heading">Title</div>}
                                        name="title"
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please enter a valid title",
                                                validator: (_, value) => {
                                                    if (value && value.trim() !== "") {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error("Please enter a valid title"));
                                                },
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Enter title" />
                                    </Form.Item>
                                    {width > 700 && (
                                        <Form.Item label={<div className="heading">Tags for the blog</div>} name="tag" rules={[{ required: true, message: "Please enter tags ", },]}>
                                            <Select mode="tags" RemoveMode={"tagClose"} tokenSeparators={[',']} dropdownStyle={{ display: 'none' }} style={{ width: '100%' }} onChange={(val) => { console.log("tagsare", val) }}> </Select>
                                        </Form.Item>)}
                                    {/* {width < 700 && (
                                        <Form.Item label={<div  >Tags for the blog</div>} name="tag" rules={[{ required: true, message: "Please enter tags ", },]}>
                                            <Select mode="tags" RemoveMode={"tagClose"} tokenSeparators={[',']} dropdownStyle={{ display: 'none' }} style={{ width: '100%' }} onChange={(val) => { console.log("tagsare", val) }}> </Select>
                                        </Form.Item>)} */}
                                    {width > 700 && (
                                        <Form.Item label={<div className="heading">Image</div>} name="image" rules={[{ required: true, message: "Please upload an image ", },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                const fileList = getFieldValue('image');
                                                console.log("hall22", fileList?.fileList?.length)
                                                if (fileList?.fileList?.length === 0) {
                                                    return Promise.reject(new Error('Please upload an image'));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                        ]}>
                                            <Upload.Dragger accept=".jpg, .png"
                                                action="https://testassociate.brigosha.com/blog/uploadBlogImages"
                                                beforeUpload={(file) => {
                                                    const isImage = (file.type === 'image/png') || (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/svg');
                                                    if (!isImage) {
                                                        Notification.error("error", `upload image only`);
                                                    }
                                                    return isImage || Upload.LIST_IGNORE;
                                                }
                                                }
                                                listType="picture"
                                                maxCount={1}
                                            >
                                                <div className="galleryUploadDrager" >
                                                    <CloudUploadOutlined style={{ width: "40px", height: "40px", fontSize: "34px" }} />
                                                    <div style={{ color: '#0086FF' }}>Click or drag file to this area to upload</div>
                                                </div>
                                            </Upload.Dragger>
                                        </Form.Item>)}
                                    {width < 700 && (
                                        <Form.Item label={<div className="heading" >Image</div>} name="image" rules={[{ required: true, message: "Please upload an image ", },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                const fileList = getFieldValue('image');
                                                console.log("hall22", fileList?.fileList?.length)
                                                if (fileList?.fileList?.length === 0) {
                                                    return Promise.reject(new Error('Please upload an image'));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                        ]}>
                                            <Upload.Dragger accept=".jpg, .png"
                                                action="https://testassociate.brigosha.com/blog/uploadBlogImages"
                                                beforeUpload={(file) => {
                                                    const isImage = (file.type === 'image/png') || (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/svg');
                                                    if (!isImage) {
                                                        Notification.error("error", `upload image only`);
                                                    }
                                                    return isImage || Upload.LIST_IGNORE;
                                                }
                                                }
                                                listType="picture"
                                                maxCount={1}
                                            >
                                                <div className="galleryUploadDrager" >
                                                    <CloudUploadOutlined style={{ width: "40px", height: "40px", fontSize: "34px" }} />
                                                    <div style={{ color: '#0086FF' }}>Click or drag file to this area to upload</div>
                                                </div>
                                            </Upload.Dragger>
                                        </Form.Item>)}
                                    {width > 700 && (
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '28px' }}>
                                            <Form.Item>
                                                <button style={{ width: '90px', height: '33px', cursor: 'pointer', backgroundColor: "#0086FF", justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: 'white', border: 'none' }} key={2} type="submit" >ADD</button>
                                            </Form.Item>
                                            <Form.Item>
                                                <div style={{ width: '90px', height: '33px', border: 'none', backgroundColor: 'white', border: "1px solid #CFCFCF", color: "#707070", justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', cursor: 'pointer' }} key={3} onClick={() => { setModal(false); }} >DISCARD</div>
                                            </Form.Item>

                                        </div>)}
                                    {width < 700 && (
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px', }}>
                                            <Form.Item>
                                                <button style={{ padding: '2px', marginBottom: '10px', backgroundColor: '#0086FF', color: 'white', borderRadius: '3px', width: '30vw', marginRight: '5px' }} key={2} type="submit" >ADD</button>
                                            </Form.Item>
                                            <Form.Item>
                                                <div style={{ width: '90px', height: '33px', border: 'none', backgroundColor: 'white', border: "1px solid #CFCFCF", color: "#707070", justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', cursor: 'pointer' }} key={3} onClick={() => { setModal(false); }} >DISCARD</div>
                                            </Form.Item>
                                        </div>)}
                                </Form>
                            </Modal>
                        </div>
                    }
                </div >
            )}
            {width < 700 && (
                <>
                    {/* <div className="containerMob"> */}
                    {preview ? <BlogDetails setPublish={setPublish} publish={publish} editClicked={editClicked} setEditClicked={setEditClicked} setPreviewData={setPreviewData} setToggle={setToggle} toggle={toggle} blogData={previewData} setPreview={setPreview} section={section} setSection={setSection}></BlogDetails> :
                        <div style={{ height: '100vh', width: '100vw' }} >
                            <div style={{ height: '15vh', display: 'flex !important', gap: '22px' }} className="firstRow">
                                <div style={{ display: 'flex', flexDirection: 'row', width: '95vw', justifyContent: 'space-between', height: '50%', alignItems: 'baseline' }}>
                                    <div style={{ fontFamily: 'poppins', width: '60%', display: 'flex', }} className="headlineBlogdetails" >{platform === "/brigosha-blog" ? "Blogs Of Brigosha" : platform === "/aulas-blog" ? "Aulas Blogs " : platform === "/vector-blog" ? "Vector Blogs" : platform === "/blog" ? "Blog" : ""}</div>
                                    <div style={{ fontFamily: 'poppins', display: 'flex', flexDirection: 'column', border: '1px solid #0086FF', zIndex: 3, borderRadius: '5px', justifyContent: 'center', width: '34%', height: '5vh' }} className="blog-tabs">
                                        {showDropdown ? (
                                            <div style={{ display: 'flex', color: '#0086FF', fontWeight: '500', justifyContent: 'center' }} onClick={() => { toggleDropdown(); setSection(selectedSection); }}>
                                                <div style={{ alignItems: 'center', display: 'flex', }}> {section}</div>
                                                {showDropdown ?
                                                    <div style={{ alignItems: 'center', display: 'flex', }}><img style={{ width: '18px', height: '18px', }} src={chevrondown} alt="chevron-down" /> </div> : <div style={{ alignItems: 'center', display: 'flex' }}><img style={{ width: '18px', height: '18px', }} src={chevronup} alt="chevron-up" /></div>
                                                }
                                            </div>
                                        ) : (
                                            <div style={{ display: 'flex', flexDirection: 'column', boxShadow: '0px 3px 6px #00000014', marginTop: '107px', alignItems: 'center', justifyContent: 'center' }} onClick={() => { toggleDropdown(); }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '6px' }}> <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '500' }}> {section}</div>
                                                    <div>{showDropdown ?
                                                        <img style={{ width: '18px', height: '18px', justifyContent: 'center', display: 'flex' }} src={chevrondown} alt="chevron-down" /> : <img style={{ width: '18px', height: '18px', justifyContent: 'center', display: 'flex' }} src={chevronup} alt="chevron-up" />
                                                    }</div></div>
                                                <div className="dropdown-content" style={{ width: '31vw', marginTop: '14px', padding: '5px', borderRadius: '3px', backgroundColor: 'white' }}>
                                                    <div style={{ backgroundColor: 'white', fontWeight: '500', color: section === "Approved" ? "#0086FF" : "#7E7E7E", backgroundColor: section === "Approved" ? "#EBEBEB" : "white" }} onClick={() => setSection("Approved")}>Approved</div>
                                                    <div style={{ backgroundColor: 'white', fontWeight: '500', color: section === "Rejected" ? "#0086FF" : "#7E7E7E", backgroundColor: section === "Rejected" ? "#EBEBEB" : "white" }} onClick={() => setSection("Rejected")}>Rejected</div>
                                                    <div style={{ backgroundColor: 'white', fontWeight: '500', color: section === "Pending" ? "#0086FF" : "#7E7E7E", backgroundColor: section === "Pending" ? "#EBEBEB" : "white" }} onClick={() => setSection("Pending")}>Pending</div>
                                                    <div style={{ backgroundColor: 'white', fontWeight: '500', color: section === "Drafts" ? "#0086FF" : "#7E7E7E", backgroundColor: section === "Drafts" ? "#EBEBEB" : "white" }} onClick={() => setSection("Drafts")}>Drafts</div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div style={{ alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'space-around', height: '50%' }}>
                                    <div style={{ width: '55%', height: '30px' }}>
                                        <SearchCompo style={{ height: '100%', }} setSearch={setSearch} value={search}></SearchCompo>
                                    </div>
                                    <div className="addbtnblog" onClick={toggleModal}>
                                        ADD BLOGS
                                    </div>
                                </div>
                            </div>

                            <div className="TableDiv" style={{ height: "70vh", display: "flex", flexDirection: "column", gap: "24px", borderRadius: '15px', boxSizing: 'border-box', marginTop: '10px', padding: '8px' }}>
                                <Row className={classes.DashboardListRowBlog}>
                                    {loader ? (
                                        <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) :
                                        sectionData?.length > 0 ? (
                                            <Row style={{ width: '100%', display: 'flex', }}>
                                                {
                                                    sectionData?.map((mapdata, index) => {
                                                        const isPublished = mapdata?.publish;
                                                        const approveHandler = (val) => {
                                                            const newPublishStatus = !isPublished;
                                                            let status = newPublishStatus ? 'Approved' : 'Rejected';
                                                            let data = { status: status, publish: newPublishStatus };
                                                            console.log("blogdatajjj", mapdata)
                                                            Api.patch(`/blog/updateBlog/?blogId=${mapdata?.blogId}`).params(data).send((res) => {
                                                                if (res?.show?.type === 'error') {
                                                                    setEditClicked(true);
                                                                    setPreview(true);
                                                                } else {
                                                                    setPreview(false);
                                                                    setToggle(!toggle);
                                                                    setSection('Approved');
                                                                    setEditClicked(false);
                                                                }
                                                            });

                                                            Api.get('/blog/singleBlog').params({ blogId: blogData?.blogId }).send((res) => {
                                                                setPreviewData(res?.blogsWithData);
                                                            });
                                                        };

                                                        return (
                                                            <div className={classes.DashboardDivMobileBlog} key={index}>
                                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '15px', height: '35%', marginBottom: '5px' }}>
                                                                    <Col style={{ width: '50px', height: '50px', display: 'flex', alignItems: 'center', }} onClick={() => { setPreview(true); setPreviewData(mapdata) }}>
                                                                        <img src={mapdata?.coverImage} alt="img" style={{ width: "50px", height: "50px", borderRadius: '5px' }}></img>
                                                                    </Col>
                                                                    <Col style={{ width: '75%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                                        <Typography.Text ellipsis={{ tooltip: true }} style={{ fontWeight: '600', fontFamily: 'poppins', maxWidth: '95%' }}> {mapdata?.blogTitle}</Typography.Text>
                                                                        <div style={{ display: 'flex', width: '100%', }}>
                                                                            {section === "Pending" ? <div >{mapdata?.review === "awaiting" ? <div style={{ border: "1px solid #FFB300", width: '100%', color: '#FFB300', borderRadius: '4px', font: "poppins", padding: '2px', fontSize: '12px' }}>Awaiting Review</div> : mapdata?.review === "completed" ? <div style={{ border: "1px solid #A17AFF", color: '#A17AFF', width: '100%', borderRadius: '4px', font: "poppins", padding: '2px', fontSize: '12px' }}>Review Completed</div> : ""}</div> : ""}
                                                                        </div>
                                                                        <div style={{ display: 'flex', width: '100%', }}>
                                                                            {section === "Approved" ? (
                                                                                <div style={{ display: 'flex', width: '100%', fontWeight: '500', color: '#7D7D7D', fontFamily: 'poppins', }}>Date : {mapdata?.publishedDate}</div>
                                                                            ) : section === "Rejected" ? (
                                                                                <div style={{ display: 'flex', width: '100%', fontWeight: '500', color: '#7D7D7D', fontFamily: 'poppins' }}>
                                                                                    Date : {mapdata?.rejectedDate ? mapdata?.rejectedDate : "NA"}
                                                                                </div>
                                                                            ) : (
                                                                                <div style={{ display: 'flex', width: '100%', fontWeight: '500', color: '#7D7D7D', fontFamily: 'poppins', }}>Date : {moment(mapdata?.created).format("DD-MM-YYYY")}</div>
                                                                            )}
                                                                        </div>

                                                                    </Col>

                                                                </div>

                                                                <div style={{ fontWeight: '500', fontFamily: 'poppins', gap: '4px', width: '100%', display: 'flex', height: '13%', alignItems: 'center' }}>Publisher - <Typography.Text ellipsis={{ tooltip: true }} style={{ fontFamily: 'poppins', color: '#7D7D7D', fontWeight: '500' }}>{mapdata?.publisherName}</Typography.Text></div>
                                                                <div style={{ width: '100%', display: 'flex', height: '28%', flexDirection: 'column', gap: '5px', marginBottom: '5px' }}>
                                                                    <div style={{ display: 'flex', fontWeight: '500', fontFamily: 'poppins', alignItems: 'center' }}> <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '100%', }}><img src={mail} style={{ width: '14px', height: '14px', marginRight: '10px' }} />{mapdata?.email}</Typography.Text></div>
                                                                    <div style={{ display: 'flex', fontWeight: '500', fontFamily: 'poppins', alignItems: 'center' }}><img src={phone} style={{ width: '14px', height: '14px', marginRight: '10px' }} />{mapdata?.phone !== null ? mapdata?.phone : "Not available"}</div>
                                                                </div>
                                                                {section === 'Approved' && (user?.privilege === 'Admin' || user?.privilege === 'SuperAdmin') ? (
                                                                    <div style={{ width: '100%', display: 'flex', height: '12%', gap: '5px', justifyContent: 'flex-start' }}>
                                                                        {isPublished ? (
                                                                            <div style={{ padding: '4vw 5vw', width: '40%', height: '5vh', cursor: 'pointer', background: '#0086FF', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: 'white', }} onClick={() => approveHandler(mapdata)}
                                                                            > UNPUBLISH
                                                                            </div>
                                                                        ) : (
                                                                            <div style={{ border: '1px solid #0086FF', padding: '4vw 5vw', width: '40%', height: '5vh', cursor: 'pointer', background: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: '#0086FF', }} onClick={() => approveHandler(mapdata)}>
                                                                                PUBLISH
                                                                            </div>
                                                                        )}
                                                                    </div>) : (
                                                                    <div style={{ width: '100%', display: 'flex', gap: '3px', height: 'fit-content', alignItems: 'center' }} className="BlogPreviewBtn" onClick={() => { setPreview(true); setPreviewData(mapdata) }}>
                                                                        <EyeOutlined />Preview
                                                                    </div >
                                                                )}
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </Row>) : (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontWeight: '600', color: '#808080', height: '65vh', width: '100%' }}>No Data</div>
                                        )}
                                </Row >
                            </div>
                            {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '8px' }}>
                               
                                <Col className="addbtnblog" onClick={toggleModal}>
                                    ADD BLOGS
                                </Col>
                            </div> */}
                            {/* <Modal open={modal} onCancel={() => { setModal(false); }} footer={false} key={Math.random()} bodyStyle={{ height: '90vh', }}>
                                {width > 700 && (<div className="headline">Add title & image </div>)}
                                {width < 700 && (<div style={{ fontFamily: 'poppins' }} className="headlineMob">Add title & image </div>)}
                                <Form style={{ overflow: "scroll", height: '95%', padding: '7px', }} layout='vertical' onFinish={submitHandler} >
                                    {width < 700 && (
                                        <Form.Item
                                            label={<div className="heading" style={{ fontWeight: '600', fontFamily: 'poppins' }}>Title</div>}
                                            name="title"
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Please enter a valid title",
                                                    validator: (_, value) => {
                                                        if (value && value.trim() !== "") {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("Please enter a valid title"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter title" />
                                        </Form.Item>)}
                                    {width > 700 && (
                                        <Form.Item
                                            label={<div className="heading">Title</div>}
                                            name="title"
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Please enter a valid title",
                                                    validator: (_, value) => {
                                                        if (value && value.trim() !== "") {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("Please enter a valid title"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter title" />
                                        </Form.Item>)}
                                    {width < 700 && (
                                        <Form.Item label={<div className="heading" style={{ fontWeight: '600', fontFamily: 'poppins' }}>Tags for the blog</div>} name="tag" rules={[{ required: true, message: "Please enter tags ", },]}>
                                            <Select mode="tags" RemoveMode={"tagClose"} tokenSeparators={[',']} dropdownStyle={{ display: 'none' }} style={{ width: '100%' }} onChange={(val) => { console.log("tagsare", val) }}> </Select>
                                        </Form.Item>)}

                                    {width < 700 && (
                                        <Form.Item label={<div className="heading" style={{ fontWeight: '600', fontFamily: 'poppins', }}>Image</div>} name="image" rules={[{ required: true, message: "Please upload an image ", },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                const fileList = getFieldValue('image');
                                                console.log("hall22", fileList?.fileList?.length)
                                                if (fileList?.fileList?.length === 0) {
                                                    return Promise.reject(new Error('Please upload an image'));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                        ]}>
                                            <Upload.Dragger accept=".jpg, .png"
                                                action="https://testassociate.brigosha.com/blog/uploadBlogImages"
                                                beforeUpload={(file) => {
                                                    const isImage = (file.type === 'image/png') || (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/svg');
                                                    if (!isImage) {
                                                        Notification.error("error", `upload image only`);
                                                    }
                                                    return isImage || Upload.LIST_IGNORE;
                                                }
                                                }
                                                listType="picture"
                                                maxCount={1}
                                            >
                                                <div className="galleryUploadDrager" >
                                                    <CloudUploadOutlined style={{ width: "40px", height: "40px", fontSize: "34px" }} />
                                                    <div style={{ color: '#0086FF' }}>Click or drag file to this area to upload</div>
                                                </div>
                                            </Upload.Dragger>
                                        </Form.Item>)}
                                    {width > 700 && (
                                        <Form.Item label={<div className="heading">Image</div>} name="image" rules={[{ required: true, message: "Please upload an image ", },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                const fileList = getFieldValue('image');
                                                console.log("hall22", fileList?.fileList?.length)
                                                if (fileList?.fileList?.length === 0) {
                                                    return Promise.reject(new Error('Please upload an image'));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                        ]}>
                                            <Upload.Dragger accept=".jpg, .png"
                                                action="https://testassociate.brigosha.com/blog/uploadBlogImages"
                                                beforeUpload={(file) => {
                                                    const isImage = (file.type === 'image/png') || (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/svg');
                                                    if (!isImage) {
                                                        Notification.error("error", `upload image only`);
                                                    }
                                                    return isImage || Upload.LIST_IGNORE;
                                                }
                                                }
                                                listType="picture"
                                                maxCount={1}
                                            >
                                                <div className="galleryUploadDrager" >
                                                    <CloudUploadOutlined style={{ width: "40px", height: "40px", fontSize: "34px" }} />
                                                    <div style={{ color: '#0086FF' }}>Click or drag file to this area to upload</div>
                                                </div>
                                            </Upload.Dragger>
                                        </Form.Item>)}
                                    {width > 700 &&
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '28px', }}>
                                            <Form.Item>
                                                <button style={{ width: '90px', height: '33px', cursor: 'pointer', backgroundColor: "#0086FF", justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: 'white', border: 'none' }} key={2} type="submit" >ADD</button>
                                            </Form.Item>
                                            <Form.Item>
                                                <div style={{ width: '90px', height: '33px', border: 'none', backgroundColor: 'white', border: "1px solid #CFCFCF", color: "#707070", justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', cursor: 'pointer' }} key={3} onClick={() => { setModal(false); }} >DISCARD</div>
                                            </Form.Item>

                                        </div>}
                                    {width < 700 &&
                                        <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'flex-start', gap: '28px' }}>
                                            <Form.Item>
                                                <button style={{ fontSize: '14px', width: '20vw', height: '33px', cursor: 'pointer', backgroundColor: "#0086FF", justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: 'white', border: 'none' }} key={2} type="submit" >Add</button>
                                            </Form.Item>
                                            <Form.Item>
                                                <div style={{ fontSize: '14px', width: '20vw', height: '33px', border: 'none', backgroundColor: 'white', border: "1px solid #CFCFCF", color: "#707070", justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', cursor: 'pointer' }} key={3} onClick={() => { setModal(false); }} >Discard</div>
                                            </Form.Item>

                                        </div>}

                                </Form>
                            </Modal> */}
                            {showModal && (
                                <div className="modal-container" style={{ padding: '0px 20px 0px 20px', width: '90%', height: '80vh', zIndex: '1000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', justifyContent: 'center', alignItems: 'center', background: 'white', display: 'flex', boxShadow: 'rgb(22 23 23 / 77%) 5px 0px 48px 137px', borderRadius: '4px' }} >
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <div style={{ borderBottom: '1px solid #CCCCCC', marginTop: '-10px', marginBottom: '12px', height: '5vh', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ fontFamily: 'poppins', fontSize: '18px', fontWeight: '600', }} >Add title & image</div>
                                                <img src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={toggleModal} />
                                            </div>
                                        </div>
                                        <div className="modal-body" style={{ overflow: 'auto', maxHeight: 'calc(80vh - 2 * 20px - 45px)' }}>
                                            <Form onFinish={submitHandler}>
                                                <Form.Item
                                                    label={window.innerWidth > 700 ? "Title" : <div className="heading" style={{ fontWeight: '600', fontFamily: 'poppins' }}>Title</div>}
                                                    name="title"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please enter a valid title",
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Enter title" />
                                                </Form.Item>
                                                {/* <Form.Item
                                                    label={window.innerWidth > 700 ? "Tags for the blog" : <div className="heading" style={{ fontWeight: '600', fontFamily: 'poppins' }}>Tags for the blog</div>}
                                                    name="tag"
                                                    rules={[{ required: true, message: "Please enter tags", }]}
                                                >
                                                    <Select mode="tags" tokenSeparators={[',']} />
                                                </Form.Item> */}
                                                <Form.Item label={<div className="heading">Tags for the blog</div>} name="tag" rules={[{ required: true, message: "Please enter tags ", },]}>
                                                    <Select mode="tags" RemoveMode={"tagClose"} tokenSeparators={[',']} dropdownStyle={{ display: 'none' }} style={{ width: '100%' }} onChange={(val) => { console.log("tagsare", val) }}> </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    label={window.innerWidth > 700 ? "Image" : <div className="heading" style={{ fontWeight: '600', fontFamily: 'poppins' }}>Image</div>}
                                                    name="image"
                                                    rules={[
                                                        { required: true, message: "Please upload an image" },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                const fileList = getFieldValue('image');
                                                                if (fileList?.fileList?.length === 0) {
                                                                    return Promise.reject(new Error('Please upload an image'));
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Upload.Dragger
                                                        accept=".jpg, .png"
                                                        action="https://testassociate.brigosha.com/blog/uploadBlogImages"
                                                        beforeUpload={(file) => {
                                                            const isImage = (file.type === 'image/png') || (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/svg');
                                                            if (!isImage) {
                                                                Notification.error("error", `upload image only`);
                                                            }
                                                            return isImage || Upload.LIST_IGNORE;
                                                        }
                                                        }
                                                        listType="picture"
                                                        maxCount={1}
                                                    >
                                                        <div className="galleryUploadDrager">
                                                            <UploadOutlined style={{ width: "40px", height: "40px", fontSize: "34px" }} />
                                                            <div style={{ color: '#0086FF' }}>Click or drag file to this area to upload</div>
                                                        </div>
                                                    </Upload.Dragger>
                                                </Form.Item>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '28px' }}>
                                                    <Form.Item>
                                                        <button style={{ width: '90px', height: '33px', cursor: 'pointer', backgroundColor: "#0086FF", justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: 'white', border: 'none' }} key={2} type="submit" >ADD</button>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <div style={{ width: '90px', height: '33px', border: 'none', backgroundColor: 'white', border: "1px solid #CFCFCF", color: "#707070", justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', cursor: 'pointer' }} key={3} onClick={toggleModal}>DISCARD</div>
                                                    </Form.Item>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                </>
            )
            }

        </>
    )
}
export default Blog;