import { Button, Col, Form, Input, Modal, Row, Select, Table, Typography, Upload } from "antd";
import { EditOutlined, DeleteOutlined, UploadOutlined, PlusOutlined, CloseOutlined, CloudUploadOutlined, CheckOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useRef, useState } from "react";
import Api from "services/Api";
import Notification from "services/Notification";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import reasonIcon from "../../Images/reasonIcon.svg";
import eye from "../../Images/eye.svg";
import editblue from "../../Images/editblue.svg";
import leftIcon from "../../Images/leftIcon.svg";
import { removeRepeatedPrefixSuffix } from "component/Algo";
// import Editor from "./editor";
import 'react-quill/dist/quill.snow.css';
import QuillToolbar from "./toolBar";
import { useSelector } from "react-redux";
import ModalCompo from "component/ModalCompo";
import moreVerticalblue from "../../Images/moreVerticalblue.svg";
import "../component.css";
import x from "../../Images/x.svg"
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}
var quillRefObj;
Quill.register('modules/imageResize', ImageResize);
function BlogDetails({ setPublish, publish, blogData, setPreview, setPreviewData, section, setToggle, toggle, setSection, setEditClicked, editClicked }) {
  const { width } = useWindowDimensions();
  const [popupOpen, setPopupOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteImg, setDeleteImg] = useState(false);
  const [blogText, setBlogText] = useState("");
  const [deleteModal, setDeleteModal] = useState(false)
  const [page, setPage] = useState("reject")
  const [reviewModal, setReviewModal] = useState(false)
  const [rejectionModal, setRejectionModal] = useState(false)
  const [reasonEdit, setReasonEdit] = useState(false);
  const [approveModal, setApproveModal] = useState(false)
  const { user } = useSelector(state => state.auth);
  const [buttonContent, setButtonContent] = useState("");
  const [videoLinkInputVisible, setVideoLinkInputVisible] = useState(false);
  const [videoLinkInputValue, setVideoLinkInputValue] = useState("");
  const [commentValue, setCommentValue] = useState(blogData?.comment || "");
  const getUpdate = () => {
    Api.get("/blog/singleBlog").params({ blogId: blogData?.blogId }).send((res) => {
      console.log("res", res)
      setPreviewData(res?.blogsWithData);
    })
  }
  console.log("jjjjjsbse upr", blogData)
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const handlePreview = (file) => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewVisible(true);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleCancelPreview = () => {
    setPreviewVisible(false);
  };
  const innerWidth = window.innerWidth;
  let widthValue;

  if (innerWidth < 460) {
    widthValue = 'auto';
  } else if (innerWidth < 700) {
    widthValue = 'fit-content';
  } else {
    widthValue = '600px';
  }

  const bodyStyle = {
    padding: "0px",
    width: widthValue,
    border: '2px solid red'
  };


  console.log("sssBlogData", blogData)
  useEffect(() => {
    console.log("inside useeffect", blogData)
    if (section === "Drafts") {
      if (user?.privilege === "Admin" || user?.privilege === "SuperAdmin") {
        if (editClicked) {
          {
            width > 700 && setButtonContent(<div style={{ display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center" }}>
              <div className="button discard" onClick={() => {
                setEditClicked(false);
                if (blogData?.blogId === "new") {
                  setPreview(false);
                  setSection("Drafts");
                }
              }}
              >DISCARD</div>
              <div className="button saveAsDraft" onClick={DraftHandler} >{"SAVE AS DRAFT"}</div>
              {blogData?.blogId !== "new" ? <div className="btnIconStyle approvebutton" onClick={() => { setApproveModal(true); }}><CheckOutlined /> APPROVE </div> : ""}
            </div>)
          }
          {
            width < 700 && setButtonContent(<div style={{ display: 'flex', flexDirection: 'row', gap: "20px", alignItems: "center", marginLeft: '10px' }}>
              <div className="button discard" onClick={() => {
                setEditClicked(false);
                if (blogData?.blogId === "new") {
                  setPreview(false);
                  setSection("Drafts");
                }
              }}
              >DISCARD</div>
              <div className="button saveAsDraft" onClick={DraftHandler} >{"SAVE AS DRAFT"}</div>
              {/* {blogData?.blogId !== "new" ? <div className="btnIconStyle approvebutton" onClick={() => { setApproveModal(true); }}><CheckOutlined /> APPROVE </div> : ""} */}
            </div>)
          }

        }
        else {

          {
            width > 700 &&
              setButtonContent(<div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center", }}>
                <div className="btnIconStyle button" style={{ background: '#EBEBEB', color: "#000000" }} onClick={() => { setEditClicked(true) }}><EditOutlined></EditOutlined> EDIT</div>
                <div className="button discard" style={{ cursor: 'not-allowed' }} >{"DRAFT COPY"}</div>
                <div className="btnIconStyle approvebutton" onClick={() => { setApproveModal(true); }}><CheckOutlined /> APPROVE </div>
              </div>)
          }
          {
            width < 700 &&
              setButtonContent(<div style={{ display: 'flex', flexDirection: 'row', gap: "20px", alignItems: "center", width: '97%', justifyContent: "center" }}>
                <div style={{ cursor: 'not-allowed', color: '#0086FF80', borderRight: '1px solid #A1A1A1', paddingRight: '28px' }} >Draft Copy</div>
                <div style={{ background: 'white', color: "#0086FF", paddingRight: '28px' }} onClick={() => { setEditClicked(true) }}> Edit Blog</div>
                {/* <div className="btnIconStyle approvebutton" onClick={() => { setApproveModal(true); }}><CheckOutlined /> APPROVE </div> */}
              </div>)
          }
        }
      }
      else if (user?.privilege === "Blogger") {
        if (editClicked) {
          <>
            {width > 700 && setButtonContent(<div style={{ display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center" }}>
              <div className="button discard" onClick={() => {
                setEditClicked(false);
                if (blogData?.blogId === "new") {
                  setPreview(false);
                  setSection("Drafts");
                }
              }}>DISCARD</div>
              <div className="button saveAsDraft" onClick={DraftHandler} >{"SAVE AS DRAFT"}</div>
              {blogData?.blogId !== "new" ? <div className="btnIconStyle button" style={{ width: '12vw' }} onClick={() => { setReviewModal(true) }}><CheckOutlined />SUBMIT FOR REVIEW</div> : ""}
            </div>)}
            {width < 700 && setButtonContent(<div style={{ display: 'none', flexDirection: 'row', gap: "20px", alignItems: "center", marginLeft: '10px' }}>
              <div className="button discard" onClick={() => {
                setEditClicked(false);
                if (blogData?.blogId === "new") {
                  setPreview(false);
                  setSection("Drafts");
                }
              }}>DISCARD</div>
              <div className="button saveAsDraft" onClick={DraftHandler} >{"SAVE AS DRAFT"}</div>
              {blogData?.blogId !== "new" ? <div className="btnIconStyle button" style={{ width: '12vw', display: 'none' }} onClick={() => { setReviewModal(true) }}><CheckOutlined />SUBMIT FOR REVIEW</div> : ""}
            </div>)}
          </>

        }
        else {
          setButtonContent(
            <>
              {width > 700 && <div style={{ display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center" }}>
                <div className="btnIconStyle button" style={{ background: '#EBEBEB', color: "#000000" }} onClick={() => { setEditClicked(true) }}><EditOutlined></EditOutlined> EDIT</div>
                <div className="button discard" style={{ cursor: 'not-allowed' }} >{"DRAFT COPY"}</div>
                <div className="btnIconStyle button" style={{ width: '12vw' }} onClick={() => { setReviewModal(true) }}><CheckOutlined />SUBMIT FOR REVIEW</div>
              </div>}
              {width < 700 && <div style={{ display: 'flex', flexDirection: 'row', gap: "20px", alignItems: "center", width: '97%', justifyContent: "center" }}>
                <div style={{ cursor: 'not-allowed', color: '#0086FF80', borderRight: '1px solid #A1A1A1', paddingRight: '28px' }} >Draft Copy</div>
                <div style={{ background: 'white', color: "#0086FF" }} onClick={() => { setEditClicked(true) }}><img src={editblue} /> Edit Blog</div>
                {/* <div className="btnIconStyle button" style={{ width: '12vw' }} onClick={() => { setReviewModal(true) }}><CheckOutlined />SUBMIT FOR REVIEW</div> */}
              </div>}
            </>
          )
        }
      }

    }
    else if (section === "Pending") {
      if (user?.privilege === "Admin" || user?.privilege === "SuperAdmin") {
        if (editClicked) {
          {
            width > 700 && setButtonContent(<div style={{ display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center" }}>
              <div className="button discard" onClick={() => { setEditClicked(false) }}>DISCARD</div>
              <div className="button saveAsDraft" onClick={() => { submitHandler({ review: "completed" }) }} >{"SAVE"}</div>
              <div className="btnIconStyle approvebutton" onClick={() => { setApproveModal(true); }}><CheckOutlined /> APPROVE</div>
            </div>)
          }
          {
            width < 700 && setButtonContent(<div style={{ display: 'none', flexDirection: 'row', gap: "20px", alignItems: "center" }}>
              <div className="button discard" onClick={() => { setEditClicked(false) }}>DISCARD</div>
              <div className="button saveAsDraft" onClick={() => { submitHandler({ review: "completed" }) }} >{"SAVE"}</div>
              <div className="btnIconStyle approvebutton" onClick={() => { setApproveModal(true); }}><CheckOutlined /> APPROVE</div>
            </div>)
          }
        }
        else {
          {
            width > 700 && (
              setButtonContent(<div style={{ display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center", }}>
                <div className="btnIconStyle button" style={{ background: '#EBEBEB', color: "#000000" }} onClick={() => { setEditClicked(true) }}><EditOutlined></EditOutlined> EDIT</div>
                <div className="btnIconStyle modelDeleteBtn" onClick={() => { setDeleteModal(true) }}> <CloseOutlined />REJECT</div>
                <div className="btnIconStyle approvebutton" onClick={() => { setApproveModal(true); }}><CheckOutlined /> APPROVE </div>
              </div>)

            )
          }
          {
            width < 700 && (
              setButtonContent(<div style={{ display: 'flex', flexDirection: 'row', gap: "20px", alignItems: "center", width: '97%', justifyContent: "center" }}>
                <div style={{ background: 'white', color: "#0086FF", borderRight: '1px solid #A1A1A1', paddingRight: '28px' }} onClick={() => { setEditClicked(true) }}><img src={editblue} /> Edit Blog</div>
                <div style={{ background: 'white', color: "#ff6969" }} onClick={() => { setDeleteModal(true) }}> Reject</div>
              </div>)

            )
          }
        }
      }
      else if (user?.privilege === "Blogger") {
        setButtonContent(
          <div style={{ display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center" }}>
            {width > 700 && <div className="buttonPending" style={{ background: '#F5F7FA', color: '#FFB300', border: '1px solid #FFB300' }} >{"PENDING"}</div>}
            {width < 700 && <div className="buttonPending" style={{ background: 'white', color: '#FFB300', }} >Pending</div>}
          </div>)
      }
    }
    else if (section === "Rejected") {
      if (user?.privilege === "Admin" || user?.privilege === "SuperAdmin" || user?.privilege === "Blogger") {
        {
          width > 700 && setButtonContent(<div style={{ display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center" }}>
            {section === "Rejected" ? editClicked ? "" : <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginLeft: '40px', cursor: 'pointer' }} onClick={() => { setRejectionModal(true); }}><img src={reasonIcon} style={{ height: '20px', width: '20px' }} /><div style={{ textDecoration: "underline", font: "normal normal normal 16px/22px Open Sans", }}> reason for rejection</div></div> : ""}
            <div className="buttonP" style={{ background: '#FF696926', color: '#FF6969', width: "140px", cursor: 'default' }}>NOT PUBLISHED</div>
          </div>)
        }
        {
          width < 700 && setButtonContent(<div className="editBtnMobReject" style={{ display: 'flex', flexDirection: 'row', alignItems: "center", }}>
            {console.log('editcli', editClicked)}
            <div style={{ color: '#FF6969', cursor: 'default', fontSize: '14px', fontWeight: '500', fontFamily: 'poppins', }}>Not Published</div>
            <div>|</div>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', cursor: 'pointer' }} onClick={() => { setRejectionModal(true); }}><img src={eye} style={{ width: '18px', height: '18px' }} /><div style={{ fontSize: '14px', fontWeight: '500', fontFamily: 'poppins', color: '#0086FF' }}> Reason for Rejection</div></div>
          </div>)
        }


      }
      if (blogData?.publisherId === user?.id) {
        if (editClicked && width > 700) {
          setButtonContent(<div style={{ display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center" }}>
            <div className="button discard" onClick={() => { setEditClicked(false) }}>DISCARD</div>
            <div className="button saveAsDraft" onClick={DraftHandler} >{"SAVE AS DRAFT"}</div>
            {(user?.privilege === "SuperAdmin" || user?.privilege === "Admin") ? <div className="btnIconStyle approvebutton" onClick={() => {
              let content = removeRepeatedPrefixSuffix(quillRefObj?.getEditorContents(), "<p><br></p>")
              if (blogData?.blogContent?.localeCompare(content) === 0) {
                Notification.error("error", "Please Update the content & Submit ");
                return;
              }
              setApproveModal(true);
            }}><CheckOutlined /> APPROVE </div> : <div className="btnIconStyle button" style={{ width: '12vw' }} onClick={() => {
              let content = removeRepeatedPrefixSuffix(quillRefObj?.getEditorContents(), "<p><br></p>")
              console.log("cccc", content)
              if (blogData?.blogContent?.localeCompare(content) === 0) {
                console.log("ccccooo", content)
                Notification.error("error", "Please Update the content & Submit ");
                return;
              }
              setReviewModal(true)
            }}><CheckOutlined /> SUBMIT FOR REVIEW </div>}
          </div>)
        }
        else {

          {
            width > 700 && setButtonContent(<div style={{ display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center" }}>
              {section === "Rejected" ? editClicked ? "" : <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginLeft: '40px', cursor: 'pointer' }} onClick={() => { setRejectionModal(true); }}><img src={reasonIcon} style={{ height: '20px', width: '20px' }} /><div style={{ textDecoration: "underline", font: "normal normal normal 16px/22px Open Sans", }}> reason for rejection</div></div> : ""}
              <div className="btnIconStyle button" style={{ background: '#EBEBEB', color: "#000000" }} onClick={() => { setEditClicked(true) }}><EditOutlined></EditOutlined> EDIT</div>
              <div className="buttonP" style={{ width: "140px", background: '#FF696926', color: '#FF6969', }}>NOT PUBLISHED</div>
            </div>)
          }
          // {
          //   width < 700 && setButtonContent(<div className="editBtnMobReject" style={{ display: 'flex', flexDirection: 'row', alignItems: "center", }}>
          //     <div style={{ color: '#FF6969', cursor: 'default', fontSize: '14px', fontWeight: '500', fontFamily: 'poppins', }}>Not Published</div>
          //     <div>|</div>
          //     {section === "Rejected" ? editClicked ? "" : <div style={{ display: 'flex', gap: '8px', alignItems: 'center', cursor: 'pointer' }} onClick={() => { setRejectionModal(true); }}><img src={eye} style={{ width: '18px', height: '18px' }} /><div style={{ fontSize: '14px', fontWeight: '500', fontFamily: 'poppins', color: '#0086FF' }}> Reason for Rejection</div></div> : ""}

          //   </div>)
          // }

        }

      }
    }
    else if (section === "Approved") {
      if (user?.privilege === "Admin" || user?.privilege === "SuperAdmin") {
        setButtonContent(<div style={{ display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center" }}>
          {/* <div className="btnIconStyle button" style={{ background: '#F5F7FA', color: "#707070", boxShadow: "0px 3px 6px #00000014", border: "1px solid #CFCFCF" }} onClick={(text, record) => {
            // const publish = (text, record) => {
            const isPublished = blogData?.publish;
            const newPublishStatus = !isPublished;
            setPublish(newPublishStatus);
            // if ((section === "Approved") && (user?.privilege === "Admin" || user?.privilege === "SuperAdmin")) {
            // const approveHandler = (val) => {
            // let status = newPublishStatus ? "Approved" : "Rejected";
            console.log("sssjjjjjjunia", newPublishStatus)
            let data = { publish: newPublishStatus }
            // let data = { status: "Approved", publish: newPublishStatus }
            setPublish(!publish);
            Api.patch(`/blog/updateBlog/?blogId=${blogData?.blogId}`).params(data).send((res) => {
              console.log("Approvehandlerres1111", res?.show?.type)
              if (res?.show?.type === "error") {
                setEditClicked(true);
                setPreview(true);
              }
              else {
                setPreview(false);
                setToggle(!toggle);
                setSection("Approved")
                setEditClicked(false);
              }
            })

            Api.get("/blog/singleBlog").params({ blogId: blogData?.blogId }).send((res) => {
              console.log("res", res)
              setPreviewData(res?.blogsWithData);
            })

            // }
            return (
              <> {
                isPublished ?
                  <div style={{ padding: '14px', width: '7vw', height: '5vh', cursor: 'pointer', background: '#FFFFFF', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: '#0086FF', border: '1px solid #0086FF' }
                  }
                    onClick={approveHandler} >
                    UNPUBLISH
                  </div >
                  :
                  <div style={{ padding: '14px', width: '7vw', height: '5vh', cursor: 'pointer', background: '#0086FF', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: '#FFFFFF', }}
                    onClick={approveHandler}>
                    PUBLISH
                  </div>
              }</>
            );
            // }

          }}>PUBLISH</div> */}
          {/* <div className="buttonP" style={{ background: '#0086FF26', color: '#0086FF', boxShadow: "0px 3px 6px #00000014", cursor: 'default' }}>APPROVED</div> */}
          <div className="buttonP" style={{ background: '#0086FF26', color: '#0086FF', boxShadow: "0px 3px 6px #00000014", cursor: 'default' }}>
            {blogData?.publish ? "PUBLISHED" : "APPROVED"}
          </div>

        </div>)
      }
      else if (user?.privilege === "Blogger") {
        if (editClicked) {
          setButtonContent(<div style={{ display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center" }}>
            <div className="button discard" onClick={() => { setEditClicked(false) }}>DISCARD</div>
            <div className="button saveAsDraft" onClick={DraftHandler} >{"SAVE AS DRAFT"}</div>
            <div className="btnIconStyle button" style={{ width: '12vw' }} onClick={() => { setReviewModal(true) }}><CheckOutlined /> SUBMIT FOR REVIEW </div>
          </div>)
        }
        else {
          setButtonContent(<div style={{ display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center" }}>
            {width > 700 && <div className="buttonP" style={{ background: '#0086FF26', color: '#0086FF', boxShadow: "0px 3px 6px #00000014", cursor: 'default' }}>PUBLISHED</div>}
            {width < 700 && <div className="buttonP" style={{ background: 'none', color: '#0086FF', cursor: 'default', marginLeft: '5px' }}>Published</div>}

          </div>)
        }
      }
    }

  }, [section, editClicked, blogData])
  console.log("mmmmm", blogData)

  const imageHandler = () => {
    const input = document.createElement("input");
    input.className = "imageInBlog";
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    console.log("imageeee", input)
    input.onchange = async function () {
      const file = input.files[0];
      const formData = new FormData();
      if (file !== null) {
        formData.append("file", file);
      }
      console.log("Api req", { upload: file });
      Api.post("/blog/uploadBlogImages").upload(
        { blogImages: file },
        (percent, response) => {
          console.log("Api res", response, percent);
          if (response) {
            var cursorPosition = quillRefObj?.getEditor().getSelection();
            var imagePath = response?.data;
            console.log("image link", imagePath)
            quillRefObj?.getEditor().insertEmbed(
              cursorPosition.index,
              "image",
              imagePath,
            );
            console.log("image link", quillRefObj?.getEditor())
          }
        }
      );
    };
  }

  const isValidVideoLink = (videoLink) => {
    const videoLinkRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+|.+\.(mp4|avi|mov|wmv)$/i;
    return videoLinkRegex.test(videoLink);
  };
  const showVideoLinkInput = (vv) => {
    console.log("showVideo", vv)
    setVideoLinkInputVisible(true);
    setVideoLinkInputValue("");
  };

  const hideVideoLinkInput = () => {

    setVideoLinkInputVisible(false);
    setVideoLinkInputValue("");
  };

  const handleVideoLinkInputChange = (e) => {
    setVideoLinkInputValue(e.target.value);
  };

  const videoHandler = (a) => {
    setVideoLinkInputVisible(false);
    console.log("videoclick", a)
    if (isValidVideoLink(videoLinkInputValue)) {
      let modifiedVideoLink;
      if (videoLinkInputValue.match(/\.(mp4|avi|mov|wmv)$/i)) {
        // File upload
        modifiedVideoLink = videoLinkInputValue;
      } else {
        // YouTube video link
        modifiedVideoLink = transformVideoLink(videoLinkInputValue);
      }
      const quill = quillRefObj?.getEditor();
      const range = quill.getSelection(true);
      quill.insertEmbed(range.index, "video", modifiedVideoLink, Quill.sources.USER);
      quill.setSelection(range.index + 1, Quill.sources.SILENT);
    } else {
      alert(
        "Incorrect video link or unsupported video file format. Please enter a valid video link or upload a video file (mp4, avi, mov, wmv)."
      );
    }
  };

  const transformVideoLink = (videoLink) => {
    if (videoLink.match(/\.(mp4|avi|mov|wmv)$/i)) {
      return videoLink;
    } else {
      const match = videoLink.match(/(?:https?:\/\/(?:www\.)?youtube\.com\/watch\?v=|https?:\/\/youtu\.be\/)([\w-]+)/);
      if (match) {
        const videoId = match[1];
        return `https://www.youtube.com/embed/${videoId}`;
      }
    }
    return '';
  };

  const changeHandler = (val, delta, editor) => {
    // const editorData = editor.getContents();
    console.log("change data in quill", val, "junia", delta);
    const editorData = quillRefObj ? quillRefObj?.getEditorContents() : "";
    // setBlogText(editorData);
    console.log("without edit", editorData);
    console.log("after edit", removeRepeatedPrefixSuffix(editorData, "<p><br></p>"))
    console.log("after editjunia", removeRepeatedPrefixSuffix(editorData))
  }

  const modules = useMemo(() => ({
    toolbar:
    {
      handlers: {
        "image": imageHandler, "video": showVideoLinkInput,
      },
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, 7,] }],
        [{ font: [] }],
        // [{ size: [] }],
        [{ 'size': ['small', false, 'large',] }],
        [{ script: "sub" }, { script: "super" }],
        ["bold", "italic", "underline", "strike", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link", "image", "video"],
        [{ color: [] }, { background: [] }],
      ],

    },
    imageResize:
    {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }

  }), [])

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image',
  ]

  const rejectHandler = (val) => {
    Api.patch(`/blog/updateBlog/?blogId=${blogData?.blogId}`).params({ status: "Rejected", comment: val?.comment }).send((res) => {
      console.log("res", res)
      setPreview(false);
      setSection("Rejected")
      setToggle(!toggle);
    })
  }
  const approveHandler = (val) => {
    let content = quillRefObj?.getEditorContents();
    if ((content?.trim()?.length === 0 || content === undefined) && blogData?.blogContent === null) {
      Notification.error("error", "Please write blog before submitting")
      return;
    }
    // if (content?.trim()?.length === 0 || content === undefined) {
    //   Notification.error("error", "Please write blog before submittingreject2")
    //   return;
    // }
    let data = { status: "Approved", }
    if (content !== undefined) {
      let string = removeRepeatedPrefixSuffix(content, "<p><br></p>");
      data = { ...data, content: string }
    }
    Api.patch(`/blog/updateBlog/?blogId=${blogData?.blogId}`).params(data).send((res) => {
      console.log("Approvehandlerres1111", res?.show?.type)
      if (res?.show?.type === "error") {
        setEditClicked(true);
        setPreview(true);
      }
      else {
        setPreview(false);
        setToggle(!toggle);
        setSection("Approved")
        setEditClicked(false);
      }
    })
  }
  const DraftHandler = (val) => {
    console.log("111222test", val)
    let content = quillRefObj?.getEditorContents();
    console.log("111222", content)
    if (blogData?.blogId === "new") {
      console.log(blogData, "inside draft")
      let data = { title: blogData?.blogTitle, tags: [blogData?.tags], coverImage: blogData?.image, company: blogData?.company, };
      if (content) {
        let string = removeRepeatedPrefixSuffix(content, "<p><br></p>")
        data = { ...data, content: string }
        console.log(data, "previous draft junia")
      }
      else {
        Notification.error("error", "Please write the blog before submit")
        console.log(data, "Please write the blog before submit junia")
        return;
      }
      // Api.post("/blog/createBlog").upload(data, (per, res) => {
      //   console.log("res", res, per);
      //   if (res !== undefined && per === 100) {
      //     setToggle(!toggle);
      //     setSection("Drafts");
      //     setPreview(false);
      //     setEditClicked(false);
      //   }

      // })

      Api.post("/blog/createBlog").upload(data, (per, res) => {
        console.log("res", res, per);
        if (res !== undefined && per === 100) {
          console.log("reserror", res?.data?.show?.type, per);
          if (res?.data?.show?.type === "error") {
            setEditClicked(true);
            setPreview(true);
          }
          else {
            console.log("ressuccess", res?.data?.show?.type, per);
            setToggle(!toggle);
            setSection("Drafts");
            setPreview(false);
            setEditClicked(false);
          }

        }
      })
    }
    else {
      console.log("111222333", content)
      let trimmedContent = content?.trim();
      // let data = { content: content, status: "Drafts" };
      let data = { content: trimmedContent !== "<p><br></p>" ? trimmedContent : "", status: "Drafts" };

      Api.patch(`/blog/updateBlog/?blogId=${blogData?.blogId}`).params(data).send((res) => {
        console.log("res", res)
        if (res?.show?.type !== "error") {
          setReviewModal(false)
          if (section !== "Drafts") {
            setPreview(false);
            setSection("Drafts");
          }
          else if (section === "Drafts") {
            getUpdate();
          }
          setToggle(!toggle);
        }
      })
      setEditClicked(false);
    }
  }
  const submitHandler = (val) => {
    console.log("111222333444")
    let content = quillRefObj?.getEditorContents();
    console.log("ameeeri", content, blogData?.blogContent === null)
    if (blogData?.blogContent === null && (content?.trim()?.length === 0 || content === undefined)) {
      Notification.error("error", "Please write blog before submitting")
      return;
    } else if (blogData?.blogContent?.localeCompare(content) === 0) {
      Notification.error("error", "Please update the blog and submit");
      return;
    }

    let data = { status: "Pending" };
    if (val?.review) {
      data = { ...data, review: val?.review }
    }
    if (content !== undefined) {
      let string = removeRepeatedPrefixSuffix(content, "<p><br></p>");
      data = { ...data, content: string }
    }

    Api.patch(`/blog/updateBlog/?blogId=${blogData?.blogId}`).params(data).send((res) => {
      console.log("res", res)
      if (res?.show?.type !== "error") {
        setSection("Pending")
      }
      setReviewModal(false);
      setPreview(false);
      setToggle(!toggle);
      setEditClicked(false);
    })
  }

  const EditHandler = (val) => {
    // console.log("data",val?.image,)
    let data = {};
    let data1 = { ...blogData }
    console.log("clicked edit submit", data1)
    if (blogData?.blogTitle !== val?.title) {
      if (val?.title?.trim() === 0) {
        Notification.error("error", "Please give title");
        return;
      }
      data = { ...data, title: val?.title }
      data1 = { ...data1, blogTitle: val?.title }
    }
    if (val?.image !== data1?.coverImage) {
      if (val?.image?.fileList?.length === 0) {
        Notification.error("error", "Please upload an Image")
        return;
      }
      data = { ...data, coverImage: val?.image?.fileList[0]?.originFileObj, coverImageId: data1?.coverImageId }
      data1 = { ...data1, image: val?.image?.fileList[0]?.originFileObj, coverImage: val?.image?.fileList[0]?.thumbUrl }
      // console.log("ameer",data1)
    }
    if (blogData?.tags?.join(",") !== val?.tag?.join(",")) {
      if (val?.tag?.length > 3) {
        Notification.error("error", "you can not add more than 3 tags");
        return;
      }
      if (val?.tag?.length === 0) {
        Notification.error("error", "Give some tag to your blog")
        return;
      }
      let flag = false
      val?.tag?.forEach((item) => {
        if (item?.length > 30) {
          flag = true;
          return;
        }
      })
      if (flag) {
        Notification.error("error", "Tag length should be less than 30 characters")
        return;
      }
      data = { ...data, tags: val?.tag?.join(",") }
      data1 = { ...data1, tags: val?.tag }
      console.log(data1);

      // return ;

    }
    if (blogData?.blogId === "new") {
      console.log("inside if in edit", data1)
      setPreviewData(data1)
      setEditModal(false)
      return;
    }
    else {
      Api.patch(`/blog/updateBlog/?blogId=${blogData?.blogId}`).uploadAndPatch(data, (res, per) => {
        console.log("ress", res, per)
        setToggle(!toggle);
        getUpdate();
        setEditModal(false);
        setEditClicked(false);
      })
    }

  }
  let len = 0;
  let count = 0;
  return (
    <>
      {width > 700 &&
        <>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center', gap: '8px' }}>
            <div style={{ display: 'flex', gap: '15px', alignItems: 'center', width: '100%', }}>
              <div style={{ cursor: 'pointer', }} onClick={() => { setPreview(false); setEditClicked(false); setToggle(!toggle); }}><ArrowLeftOutlined style={{ fontSize: '20px', display: 'flex', alignItems: 'center' }}></ArrowLeftOutlined></div>
              <div style={{ width: '45px', height: '45px', }}> <img src={blogData?.coverImage} style={{ width: "100%", height: "100%", borderRadius: '4px' }} ></img> </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', gap: '16px', width: 'fit-content', }}>
                <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 20px/28px Open Sans", maxWidth: '250px' }}>{blogData?.blogTitle}</Typography.Text>
                {(editClicked && section === "Drafts") ? <div style={{ cursor: 'pointer' }} onClick={() => { setEditModal(true) }}><EditOutlined size="large"></EditOutlined></div> : ""}
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: '10px', maxWidth: '440px', alignItems: 'center', }}>
                {blogData?.tags?.map((val, id) => {
                  len += val?.length;
                  console.log(len, val?.length)
                  if (len <= 30) {
                    return (<>
                      <div style={{ padding: "1vh 1.5vw", backgroundColor: '#EBEBEB', borderRadius: '4px', display: "flex", justifyContent: 'center', alignItems: 'center', }} key={id}><div>{val}</div></div>
                    </>
                    )
                  }
                  else if (len > 30) {
                    if (id === 1) {
                      count = count + 1;
                      return;
                    }
                    else if (id === 2) {
                      count = count + 1;
                      return (
                        <div style={{ width: 'max-content' }}> + {count}</div>
                      )
                    }
                  }

                })}
              </div>
            </div>
            {buttonContent}
          </div >
          <div style={{ display: "flex", width: "100%", marginTop: "5vh", }} >
            {editClicked ? (
              <>
                {videoLinkInputVisible ? (
                  <ModalCompo contentStyle={{ width: "23vw", top: '52vh', left: '5vw', height: '10vh', }} open={videoLinkInputVisible} onCancel={() => setVideoLinkInputVisible(false)}>
                    <Row style={{ width: '35vw', marginTop: '7px', marginLeft: '9px' }}>
                      <Col style={{ width: '60%', marginBottom: "9px", height: '35px', marginRight: '1vw' }}>
                        <input style={{ fontFamily: "system-ui ", border: '1px solid #464444', borderRadius: '5px', width: '100%' }}
                          type="text"
                          value={videoLinkInputValue}
                          onChange={handleVideoLinkInputChange}
                          placeholder="Enter the video link "
                        />
                      </Col>
                      <Col style={{ width: '35%', justifyContent: 'center' }}>
                        <button style={{ fontWeight: '500', fontFamily: "system-ui ", border: 'none', width: 'fit-content', color: 'blue', marginRight: '3px', cursor: 'pointer' }} onClick={videoHandler}>OK</button>
                        <button style={{ fontWeight: '500', fontFamily: "system-ui ", border: 'none', width: 'fit-content', cursor: 'pointer' }} onClick={hideVideoLinkInput}>Cancel</button>
                      </Col>
                    </Row>

                  </ModalCompo>
                ) : null}
                <ReactQuill className="QuillEditor"
                  ref={(el) => {
                    quillRefObj = el;
                  }}
                  defaultValue={blogData?.blogContent || ""}
                  placeholder="Type here"
                  onChange={changeHandler}
                  selection={{ start: 0, end: 0 }}
                  modules={modules}
                ></ReactQuill>
              </>
            ) : (
              <div style={{ backgroundColor: "white", padding: "20px", width: "100%", overflowY: "auto", height: '70vh', display: 'flex' }} >
                <div
                  dangerouslySetInnerHTML={{
                    __html: blogData?.blogContent
                      ? blogData?.blogContent
                      : "<p>please click on edit to write the blog</p>",
                  }}
                ></div>
              </div>
            )}
          </div>

        </>
      }

      {width < 700 &&
        <div style={{ width: '100vw', height: '100vh' }}>
          <div style={{ display: 'flex', gap: '5px', alignItems: 'center', width: '100%', }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '17vh' }}>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ cursor: 'pointer', width: '10%', display: 'flex' }} onClick={() => { setPreview(false); setEditClicked(false); setToggle(!toggle); }}><img src={leftIcon} style={{ width: '27px', height: '27px', }} /></div>
                <div style={{ width: '45px', height: '45px', marginRight: '5px' }}> <img src={blogData?.coverImage} style={{ width: "45px", height: "45px", borderRadius: '4px' }} ></img> </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', gap: '16px', width: 'fit-content', }}>
                    <Typography.Text ellipsis={{ tooltip: true }} style={{ fontFamily: 'poppins', fontSize: '14px', fontWeight: '600', maxWidth: '75vw' }}>{blogData?.blogTitle}</Typography.Text>
                    {(editClicked && section === "Drafts") ? <div style={{ cursor: 'pointer' }} onClick={() => { setEditModal(true) }}><EditOutlined size="large"></EditOutlined></div> : ""}
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", gap: '10px', maxWidth: '440px', alignItems: 'center', }}>
                    {blogData?.tags?.map((val, id) => {
                      len += val?.length;
                      console.log(len, val?.length)
                      if (len <= 30) {
                        return (<>
                          <div style={{ padding: "1vh 1.5vw", backgroundColor: '#EBEBEB', borderRadius: '4px', display: "flex", justifyContent: 'center', alignItems: 'center', }} key={id}><div>{val}</div></div>
                        </>
                        )
                      }
                      else if (len > 30) {
                        if (id === 1) {
                          count = count + 1;
                          return;
                        }
                        else if (id === 2) {
                          count = count + 1;
                          return (
                            <div style={{ width: 'max-content' }}> + {count}</div>
                          )
                        }
                      }

                    })}
                  </div>
                </div>
              </div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginLeft: '5px', }}>
                {buttonContent}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", width: "100vw", padding: '8px', flexDirection: 'column', justifyContent: 'center', height: '74vh', }} >
            {editClicked ? (
              <>
                {videoLinkInputVisible ? (
                  <ModalCompo contentStyle={{ width: "23vw", top: '52vh', left: '5vw', height: '10vh', }} open={videoLinkInputVisible} onCancel={() => setVideoLinkInputVisible(false)}>
                    <Row style={{ width: '35vw', marginTop: '7px', marginLeft: '9px' }}>
                      <Col style={{ width: '60%', marginBottom: "9px", height: '35px', marginRight: '1vw' }}>
                        <input style={{ fontFamily: "system-ui ", border: '1px solid #464444', borderRadius: '5px', width: '100%' }}
                          type="text"
                          value={videoLinkInputValue}
                          onChange={handleVideoLinkInputChange}
                          placeholder="Enter the video link "
                        />
                      </Col>
                      <Col style={{ width: '35%', justifyContent: 'center' }}>
                        <button style={{ fontWeight: '500', fontFamily: "system-ui ", border: 'none', width: 'fit-content', color: 'blue', marginRight: '3px', cursor: 'pointer' }} onClick={videoHandler}>OK</button>
                        <button style={{ fontWeight: '500', fontFamily: "system-ui ", border: 'none', width: 'fit-content', cursor: 'pointer' }} onClick={hideVideoLinkInput}>Cancel</button>
                      </Col>
                    </Row>

                  </ModalCompo>
                ) : null}
                <ReactQuill className="QuillEditor"
                  ref={(el) => {
                    quillRefObj = el;
                  }}
                  defaultValue={blogData?.blogContent || ""}
                  placeholder="Type here"
                  onChange={changeHandler}
                  selection={{ start: 0, end: 0 }}
                  modules={modules}
                ></ReactQuill>
              </>
            ) : (
              <div style={{
                backgroundColor: "white", padding: "20px", width: "100%", overflowY: "auto", height: '56vh',
                // height: section === "Drafts" ? '65vh' : '70vh', 
                display: 'flex',
              }} >
                <div
                  dangerouslySetInnerHTML={{
                    __html: blogData?.blogContent
                      ? blogData?.blogContent
                      : "<p>please click on edit to write the blog</p>",
                  }}
                ></div>
              </div>
            )}


            {
              editClicked ? (
                section === "Drafts" ? (
                  <>
                    {/* {setButtonContent} */}
                    <div style={{ display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center" }}>
                      {blogData?.blogId !== "new" && (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '7px' }}>
                          {user?.privilege === "Blogger" ?
                            (<div className="btnIconStyle button" style={{ fontWeight: '500', width: '80%', display: 'flex', height: '6vh', fontSize: '14px' }} onClick={() => { setReviewModal(true) }}>
                              Submit for Review
                            </div>) : (
                              <>
                                {blogData?.blogId !== "new" ? <div className="btnIconStyle button" style={{ fontWeight: '500', width: '80%', display: 'flex', height: '6vh', fontSize: '14px' }} onClick={() => { setApproveModal(true); }}> Approve </div> : ""}
                              </>
                            )}
                          <div style={{ borderRadius: '6px', border: '1px solid #0086FF', width: '10%', color: '#707070', display: "flex", flexShrink: 0, alignItems: 'center', position: 'relative', justifyContent: 'center' }}> <img src={moreVerticalblue} style={{ width: '15px', height: '15px', cursor: 'pointer', color: '#707070' }} alt="More Vertical" onClick={() => setPopupOpen(!popupOpen)} />  {popupOpen && (
                            <div style={{ width: '22vw', borderRadius: '5px', position: 'absolute', bottom: '100%', right: 0, backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 6px 3px', zIndex: 100, padding: '10px', display: 'flex', flexDirection: 'column', }} >
                              <div className="Discardpop" style={{ color: popupOpen ? 'black' : '#0086FF', cursor: 'pointer', fontWeight: '500', fontSize: '14px', fontFamily: 'poppins', }} onClick={() => {
                                setEditClicked(false); if (blogData?.blogId === "new") { setPreview(false); setSection("Drafts"); } setPopupOpen(false);
                              }}> Discard
                              </div>
                              <div className="Draftpop" style={{ color: popupOpen ? 'black' : '#0086FF', cursor: 'pointer', fontWeight: '500', fontSize: '14px', fontFamily: 'poppins' }} onClick={() => {
                                DraftHandler(); setPopupOpen(false);
                              }}> Save as Draft
                              </div>
                            </div>

                          )} </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  section == 'Pending' ? (
                    <>
                      {user?.privilege === "Admin" || user?.privilege === "SuperAdmin" && (
                        <>
                          <div style={{ display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center" }}>
                            {blogData?.blogId !== "new" && (
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '7px' }}>
                                <div className="btnIconStyle button" style={{ fontWeight: '500', width: '80%', display: 'flex', height: '6vh', fontSize: '14px' }}> Approve</div>
                                <div style={{ borderRadius: '6px', border: '1px solid #0086FF', width: '10%', color: '#707070', display: "flex", flexShrink: 0, alignItems: 'center', position: 'relative', justifyContent: 'center' }}> <img src={moreVerticalblue} style={{ width: '15px', height: '15px', cursor: 'pointer', color: '#707070' }} alt="More Vertical" onClick={() => setPopupOpen(!popupOpen)} />  {popupOpen && (
                                  <div style={{ width: '26vw', boxShadow: '0px 3px 6px #00000029', borderRadius: '5px', position: 'absolute', bottom: '100%', right: 0, backgroundColor: 'white', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', zIndex: 100, padding: '10px', display: 'flex', flexDirection: 'column', }} >
                                    <div className="Discardpop" style={{ color: popupOpen ? 'black' : '#0086FF', cursor: 'pointer', fontWeight: '500', fontSize: '14px', fontFamily: 'poppins', }} onClick={() => { setEditClicked(false) }}>Discard</div>
                                    <div className="Draftpop" style={{ color: popupOpen ? 'black' : '#0086FF', cursor: 'pointer', fontWeight: '500', fontSize: '14px', fontFamily: 'poppins' }} onClick={() => { submitHandler({ review: "completed" }) }} >Save</div>
                                  </div>

                                )} </div>
                              </div>
                            )}
                          </div>
                        </>
                      )
                      }
                    </>
                  ) : (section === "Rejected" && blogData?.publisherId === user?.id ? (
                    <div style={{ display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center" }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '7px' }}>
                        {(user?.privilege === "SuperAdmin" || user?.privilege === "Admin") ? <div className="btnIconStyle button" style={{ fontWeight: '500', width: '80%', display: 'flex', height: '6vh', fontSize: '14px' }} onClick={() => {
                          let content = removeRepeatedPrefixSuffix(quillRefObj?.getEditorContents(), "<p><br></p>")
                          if (blogData?.blogContent?.localeCompare(content) === 0) {
                            Notification.error("error", "Please Update the content & Submit ");
                            return;
                          }
                          setApproveModal(true);
                        }}> Approve </div> : <div className="btnIconStyle button" style={{ fontWeight: '500', width: '80%', display: 'flex', height: '6vh', fontSize: '14px' }} onClick={() => {
                          let content = removeRepeatedPrefixSuffix(quillRefObj?.getEditorContents(), "<p><br></p>")
                          console.log("cccc", content)
                          if (blogData?.blogContent?.localeCompare(content) === 0) {
                            console.log("ccccooo", content)
                            Notification.error("error", "Please Update the content & Submit ");
                            return;
                          }
                          setReviewModal(true)
                        }}>Submit for Review </div>}

                        <div style={{ borderRadius: '6px', border: '1px solid #0086FF', width: '10%', color: '#707070', display: "flex", flexShrink: 0, alignItems: 'center', position: 'relative', justifyContent: 'center' }}> <img src={moreVerticalblue} style={{ width: '15px', height: '15px', cursor: 'pointer', color: '#707070' }} alt="More Vertical" onClick={() => setPopupOpen(!popupOpen)} />  {popupOpen && (
                          <div style={{ width: '26vw', boxShadow: '0px 3px 6px #00000029', borderRadius: '5px', position: 'absolute', bottom: '100%', right: 0, backgroundColor: 'white', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', zIndex: 100, padding: '10px', display: 'flex', flexDirection: 'column', }} >
                            <div className="Discardpop" style={{ color: popupOpen ? 'black' : '#0086FF', cursor: 'pointer', fontWeight: '500', fontSize: '14px', fontFamily: 'poppins', }} onClick={() => { setEditClicked(false) }}>Discard</div>
                            <div className="Draftpop" style={{ color: popupOpen ? 'black' : '#0086FF', cursor: 'pointer', fontWeight: '500', fontSize: '14px', fontFamily: 'poppins' }} onClick={DraftHandler}>Save as Draft</div>
                          </div>

                        )} </div>
                      </div>
                    </div>
                  ) : '')
                )

              ) : (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  {section === "Drafts" && user?.privilege === "Blogger" ? (
                    <div className="btnIconStyle button" style={{ width: '100%', display: 'flex', height: '6vh' }} onClick={() => { setReviewModal(true) }}>
                      Submit for Review
                    </div>
                  ) : section === "Rejected" && blogData?.publisherId === user?.id ? (
                    <div className="btnIconStyle button" style={{ width: '100%', display: 'flex', height: '6vh' }} onClick={() => { setEditClicked(true) }}>Edit Blog</div>
                  ) : section === 'Pending' && (user?.privilege === "Admin" || user?.privilege === "SuperAdmin") ? (
                    <div className="btnIconStyle button" style={{ width: '100%', display: 'flex', height: '6vh' }} onClick={() => { setApproveModal(true); }}><CheckOutlined /> Approve </div>
                  ) : section === 'Drafts' && user?.privilege === "Admin" || user?.privilege === "SuperAdmin" ? (
                    <div className="btnIconStyle button" style={{ width: '100%', display: 'flex', height: '6vh' }} onClick={() => { setApproveModal(true); }}>Approve </div>
                  ) : ''
                  }

                </div>
              )
            }
          </div>
        </div >
      }

      {width > 700 && <Modal open={editModal} onCancel={() => { setEditModal(false); }} footer={false} key={Math.random()} bodyStyle={{ height: '90vh', }}>
        <div className="headline">Title & Image</div>
        <Form style={{ overflow: "scroll", height: '93%' }} layout='vertical' onFinish={EditHandler} initialValues={{ title: blogData?.blogTitle, tag: blogData?.tags, image: blogData?.coverImage }}>
          <Form.Item label=<div className="heading">Title</div> name="title" rules={[{
            whitespace: true,
            validator: (_, value) => {
              if (value && value.trim() !== "") {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Title cannot be empty"));
            },
          },]}>
            <Input placeholder="enter title "></Input>
          </Form.Item>
          <Form.Item label=<div className="heading" >Tags for the blog</div> name="tag" rules={[{ required: true, message: "Please enter tags ", },]}>
            <Select mode="tags" tokenSeparators={[',']} size={'default'} dropdownStyle={{ display: 'none' }} style={{ width: '100%' }} onChange={(val) => { console.log(val) }}> </Select>
          </Form.Item>
          <Form.Item label=<div className="heading">Image</div> name="image"  >
            <Upload.Dragger id="dragger"
              beforeUpload={(file) => {
                const isImage = (file.type === 'image/png') || (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/svg');
                if (!isImage) {
                  Notification.error("error", `upload image only`);
                }
                return isImage || Upload.LIST_IGNORE;
              }}
              accept=".jpg, .png" action="https://www.mocky.io/v2/5cc8019d300000980a055e76" listType="picture" maxCount={1} defaultFileList={[{ url: blogData?.coverImage },]}>
              <div className="galleryUploadDrager" >
                <CloudUploadOutlined style={{ width: "40px", height: "40px", fontSize: "34px" }} />
                <div style={{ color: '#0086FF' }}>Click or drag file to this area to upload</div>
              </div>
            </Upload.Dragger>
          </Form.Item>
          {width > 700 &&
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '28px' }}>
              <Form.Item>
                <div className="button" style={{ border: 'none', backgroundColor: 'white', border: "1px solid #CFCFCF", color: "#707070" }} key={3} onClick={() => { setEditModal(false); }} >DISCARD</div>
              </Form.Item>
              <Form.Item>
                <button className="button" key={2} type="submit" style={{ border: 'none', }}>UPDATE</button>
              </Form.Item>
            </div>}
          {width < 700 &&
            <div style={{ display: 'flex', alignItems: 'center', }}>
              <Form.Item style={{ width: '55vw', display: 'flex', marginBottom: '1px', }}>
                <button className="button" key={2} type="submit" style={{ padding: '2px', backgroundColor: '#0086FF', color: 'white', borderRadius: '3px', width: '30vw', marginRight: '5px', border: 'none' }}>Update</button>
              </Form.Item>
              <Form.Item style={{ width: '55vw', display: 'flex', marginBottom: '1px', alignItems: 'center', }}>
                <div className="button" style={{ border: 'none', backgroundColor: 'white', border: "1px solid #CFCFCF", color: "#707070" }} key={3} onClick={() => { setEditModal(false); }} >Discard</div>
              </Form.Item>
            </div>}
        </Form>
      </Modal>}

      {width < 700 && editModal && (
        <div className="custom-modal-container" style={{ padding: '0px 20px 0px 20px', width: '90%', height: '80vh', zIndex: '1000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', justifyContent: 'center', alignItems: 'center', background: 'white', display: 'flex', boxShadow: 'rgb(22 23 23 / 77%) 5px 0px 48px 137px', borderRadius: '4px' }}>
          <div className="modal-content" style={{ width: '100%' }}>
            <div style={{ borderBottom: '1px solid #CCCCCC', marginTop: '-10px', marginBottom: '12px', height: '5vh', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div className="headlineM">Title & Image</div>
              <img src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={() => { setEditModal(false) }} />
            </div>
            <Form style={{ overflow: 'auto', maxHeight: 'calc(80vh - 2 * 20px - 45px)' }} layout='vertical' onFinish={EditHandler} initialValues={{ title: blogData?.blogTitle, tag: blogData?.tags, image: blogData?.coverImage }}>
              <Form.Item label=<div className="heading">Title</div> name="title" rules={[{
                whitespace: true,
                validator: (_, value) => {
                  if (value && value.trim() !== "") {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Title cannot be empty"));
                },
              },]}>
                <Input placeholder="enter title "></Input>
              </Form.Item>
              <Form.Item label=<div className="heading" >Tags for the blog</div> name="tag" rules={[{ required: true, message: "Please enter tags ", },]}>
                <Select mode="tags" tokenSeparators={[',']} size={'default'} dropdownStyle={{ display: 'none' }} style={{ width: '100%' }} onChange={(val) => { console.log(val) }}> </Select>
              </Form.Item>
              <Form.Item label=<div className="heading">Image</div> name="image"  >
                <Upload.Dragger id="dragger"
                  beforeUpload={(file) => {
                    const isImage = (file.type === 'image/png') || (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/svg');
                    if (!isImage) {
                      Notification.error("error", `upload image only`);
                    }
                    return isImage || Upload.LIST_IGNORE;
                  }}
                  accept=".jpg, .png" action="https://www.mocky.io/v2/5cc8019d300000980a055e76" listType="picture" maxCount={1} defaultFileList={[{ url: blogData?.coverImage },]}>
                  <div className="galleryUploadDrager" >
                    <CloudUploadOutlined style={{ width: "40px", height: "40px", fontSize: "34px" }} />
                    <div style={{ color: '#0086FF' }}>Click or drag file to this area to upload</div>
                  </div>
                </Upload.Dragger>
              </Form.Item>
              <div style={{ display: 'flex', alignItems: 'center', }}>
                <Form.Item style={{ width: '55vw', display: 'flex', marginBottom: '1px', }}>
                  <button className="button" key={2} type="submit" style={{ padding: '2px', backgroundColor: '#0086FF', color: 'white', borderRadius: '3px', width: '30vw', marginRight: '5px', border: 'none' }}>Update</button>
                </Form.Item>
                <Form.Item style={{ width: '55vw', display: 'flex', marginBottom: '1px', alignItems: 'center', }}>
                  <div className="button" style={{ border: 'none', backgroundColor: 'white', border: "1px solid #CFCFCF", color: "#707070" }} key={3} onClick={() => { setEditModal(false); }} >Discard</div>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      )}


      <Modal open={deleteModal} footer={false} closable={true} width={page === "reject" ? "440px" : "624px"} onCancel={() => { setDeleteModal(false); setPage("reject") }} bodyStyle={{ padding: "0px", width: widthValue, }} >
        {page === "reject" ?
          <div style={{ 'display': 'flex', 'flexDirection': 'column', 'gap': '10px', padding: '30px 40px', alignItems: 'center', marginTop: '3vh', height: '220px' }}>
            <div style={{ fontSize: '22px', fontFamily: 'poppins', fontWeight: '500' }}>Are you sure ?</div>
            <div style={{ fontSize: '16px', fontFamily: 'poppins', fontWeight: '500' }}>{section === "Pending" ? "This blog will be rejected." : "This blog will be unpublished."}</div>
            <div className="modelBtn">
              <div className="modelCancelBtn" onClick={() => { setDeleteModal(false) }}>Cancel</div>
              <div className="modelDeleteBtn" onClick={() => { setPage("comment") }}>{section === "Pending" ? "Reject" : "Unpublish"}</div>
            </div>
          </div> :
          page === "comment" ?
            <div style={{ 'display': 'flex', 'flexDirection': 'column', 'gap': '10px', padding: '40px', width: "600px" }}>
              <div style={{ font: "normal normal bold 24px/33px Open Sans" }}>Add Comment</div>
              <Form onFinish={(val) => {
                if (val?.comment === undefined || val?.comment?.trim().length === 0) {
                  Notification.error("erroe", "Please write the reason");
                  return;
                }
                rejectHandler(val)
              }}>
                <Form.Item name="comment">
                  <Input.TextArea rows={7} placeholder="Comments"></Input.TextArea>
                </Form.Item>
                <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button type="submit" className="addbtn" style={{ width: "10vw", height: "5vh", border: 'none', font: "normal normal bold 16px/20px Open Sans !important" }}>Post Comment</button>
                </Form.Item>
              </Form>
            </div> : ""}
      </Modal>
      {width > 700 &&
        <Modal open={rejectionModal} footer={false} closable={true} onCancel={() => { setRejectionModal(false); setReasonEdit(false); }} bodyStyle={{ padding: "0px", width: '600px', }}>
          {reasonEdit ? (<div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '40px', width: "90%" }}>
            <div style={{ font: "normal normal bold 24px/33px Open Sans" }}>Add Comment</div> <Form onFinish={(val) => { if (val?.comment === undefined || val?.comment?.trim().length === 0) { Notification.error("error", "Please write the reason"); return; } rejectHandler(val); }}>
              <Form.Item name="comment" initialValue={commentValue}>
                <Input.TextArea rows={7} placeholder="Comments" onChange={(e) => setCommentValue(e.target.value)} />
              </Form.Item>
              <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button type="submit" className="addbtn" style={{ width: "fit-content", height: "5vh", border: 'none', font: "normal normal bold 16px/22px Open Sans !important" }}>Post Comment</button>
              </Form.Item>
            </Form>


          </div>
          ) : (
            <div style={{ padding: '40px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <div style={{ display: 'flex', gap: "16px", alignItems: 'center' }}>
                <div style={{ font: "normal normal bold 24px/33px Open Sans" }}>Reason For Rejection</div>
                {(user?.privilege === "Admin" || user?.privilege === "SuperAdmin") ? (<div style={{ display: 'flex', font: "normal normal bold 16px/22px Open Sans", display: 'flex', gap: "8px", alignItems: 'center', cursor: 'pointer', color: "#b3b3b3" }} onClick={() => { setReasonEdit(true); setCommentValue(blogData?.comment || ""); }}><EditOutlined /> Edit  </div>) : ("")}
              </div>
              <div style={{ minHeight: '140px', border: '1px solid #CFCFCF', width: '85%' }}>
                <div style={{ padding: '16px' }}> {blogData?.comment}</div>
              </div>
            </div>
          )}
        </Modal >
      }

      {width < 700 &&
        <Modal open={rejectionModal} footer={false} closable={true} onCancel={() => { setRejectionModal(false); setReasonEdit(false); }} bodyStyle={{ padding: "0px", width: window.innerWidth < 350 ? `${window.innerWidth - 20}px` : '100%', }}>
          {reasonEdit ? (<div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '40px', width: "90%" }}>
            <div style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'poppins' }}>Add Comment</div>
            <Form onFinish={(val) => { if (val?.comment === undefined || val?.comment?.trim().length === 0) { Notification.error("error", "Please write the reason"); return; } rejectHandler(val); }}>
              <Form.Item name="comment" initialValue={commentValue} style={{ width: '100%' }}>
                <Input.TextArea rows={7} placeholder="Comments" onChange={(e) => setCommentValue(e.target.value)} />
              </Form.Item>
              <Form.Item style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', }}>
                <button type="submit" className="addbtn" style={{ width: "fit-content", height: "5vh", border: 'none', fontSize: '14px', fontWeight: '500', fontFamily: 'poppins' }}>Post Comment</button>
              </Form.Item>
            </Form>
          </div>
          ) : (
            <div style={{ padding: '40px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <div style={{ display: 'flex', gap: "16px", alignItems: 'center' }}>
                <div style={{ fontSize: '18px', fontWeight: '700', fontFamily: 'poppins' }}>Reason For Rejection</div>
                {(user?.privilege === "Admin" || user?.privilege === "SuperAdmin") ? (<div style={{ display: 'flex', font: "normal normal bold 16px/22px Open Sans", display: 'flex', gap: "8px", alignItems: 'center', cursor: 'pointer', color: "#b3b3b3" }} onClick={() => { setReasonEdit(true); setCommentValue(blogData?.comment || ""); }}><EditOutlined /> Edit  </div>) : ("")}
              </div>
              <div style={{ minHeight: '140px', border: '1px solid #CFCFCF' }}>
                <div style={{ padding: '16px' }}> {blogData?.comment}</div>
              </div>
            </div>
          )}
        </Modal >
      }

      <Modal open={reviewModal} footer={false} closable={true} onCancel={() => { setReviewModal(false) }} bodyStyle={{ width: window.innerWidth < 700 ? 'fit-content' : '600px', }} >
        <div style={{ 'display': 'flex', 'flexDirection': 'column', 'gap': '10px', }}>
          <div style={{ display: 'flex', justifyContent: 'center', fontSize: '22px', fontFamily: 'poppins', fontWeight: '500', color: '#0086FF' }}>Are you sure ?</div>
          <div className="modelInfo" style={{ fontSize: '16px', fontFamily: 'poppins', fontWeight: '500' }}>The blog will be submitted for review.</div>
          <div className="modelBtn">
            <div className="modelCancelBtn" onClick={DraftHandler}>Save As Draft</div>
            <div className="modelSubmitBtn" onClick={submitHandler}>Submit</div>
          </div>
        </div>
      </Modal>
      {
        width > 700 &&
        <Modal open={approveModal} footer={false} closable={true} onCancel={() => { setApproveModal(false) }} bodyStyle={{ padding: "0px", width: window.innerWidth < 700 ? 'fit-content' : '600px', }} >
          <div style={{ 'display': 'flex', 'flexDirection': 'column', 'gap': '10px', padding: '30px 40px', alignItems: 'center', marginTop: '3vh', width: "520px", height: '220px' }}>
            <div style={{ fontSize: '22px', fontFamily: 'poppins', fontWeight: '500', color: '#0086FF' }}>Are you sure ?</div>
            <div style={{ fontSize: '16px', fontFamily: 'poppins', fontWeight: '500' }}>This blog will be approved</div>
            <div className="modelBtn">
              <div className="modelCancelBtn" onClick={() => {
                setApproveModal(false);
                if (section === "Pending") {
                  submitHandler({ review: "completed" })
                }
                else {
                  DraftHandler();
                }

              }}>{section === "Pending" ? "Save" : "Save as draft"}</div>
              <div className="modelSubmitBtn" onClick={approveHandler}>Approve</div>
            </div>
          </div>
        </Modal>
      }
      {
        width < 700 &&
        <Modal open={approveModal} footer={false} closable={true} onCancel={() => { setApproveModal(false) }} bodyStyle={{ padding: "8px", width: window.innerWidth < 700 ? 'fit-content' : '600px', }} >
          <div style={{ 'display': 'flex', 'flexDirection': 'column', 'gap': '10px', }}>
            <div style={{ fontSize: '22px', fontFamily: 'poppins', fontWeight: '500', color: '#0086FF', justifyContent: 'center', display: 'flex' }}>Are you sure ?</div>
            <div className="modelInfo" style={{ fontSize: '16px', fontFamily: 'poppins', fontWeight: '500' }}>This blog will be approved</div>
            <div className="modalBtnblog">
              <div className="modelCancelBtn" onClick={() => {
                setApproveModal(false);
                if (section === "Pending") {
                  submitHandler({ review: "completed" })
                }
                else {
                  DraftHandler();
                }
              }}>{section === "Pending" ? "Save" : "Save as draft"}</div>
              <div className="modelSubmitBtn" onClick={approveHandler}>Approve</div>
            </div>
          </div>
        </Modal>
      }

    </>
  )
}
export default BlogDetails;