import React, { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
function Unauthorized() {
    const history = useHistory();
    useEffect(() => {
        history.goBack();
    })
    return (
        <div>
            <div>
                You don't have the access to view this page
            </div>
            <button onClick={() => history.goBack()}>Go Back</button>

        </div>
    )
}
export default Unauthorized;