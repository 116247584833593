
import { Button, Col, Form, Input, Modal, Row, Table, Upload, Typography, Dropdown, Menu, Pagination, Spin } from "antd";
import { EditOutlined, DeleteOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import Api from "services/Api";
import Notification from "services/Notification";
import Paragraph from "antd/lib/typography/Paragraph";
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { authActions } from "component/store";
import Gallery from "component/Gallery/Gallery";
import { useHistory } from "react-router-dom";
import addIcon from "../../Images/addIcon.svg";
import moreVertical from "../../Images/moreVertical.svg";
import trashnew from "../../Images/trashnew.svg";
import classes from '../Styles.module.css';
import { LoadingOutlined } from '@ant-design/icons';
import x from "../../Images/x.svg"

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}
function Event() {
    const { width } = useWindowDimensions();
    const history = useHistory();
    const dispatch = useDispatch();
    dispatch(authActions.updatePageHighlight(window.location.pathname))
    const [modal, setModal] = useState(false)
    const [edit, setEdit] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [editData, setEditData] = useState({})
    const [deleteData, setDeleteData] = useState({})
    const [eventData, setEventData] = useState([])
    const [loader, setLoader] = useState(false);
    const [toggle, setToggle] = useState(false)
    // const [count,setCount]=useState(0)
    const [deleteImg, setDeleteImg] = useState([]);
    const [btnLoader, setBtnLoader] = useState(false)
    const [fileList, setFileList] = useState([])
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => {
        setShowModal(!showModal);
    };
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );
    // const [currentPage, setCurrentPage] = useState(1);
    // const itemsPerPage = 10;

    // // Calculate the indexes of the items to be displayed on the current page
    // const indexOfLastItem = currentPage * itemsPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems = eventData.slice(indexOfFirstItem, indexOfLastItem);

    // const handlePageChange = (page) => {
    //     setCurrentPage(page);
    // };

    const getData = () => {
        setLoader(true)
        Api.get("/events/AllEvents").send((res, err) => {
            console.log("AllEvents", res, err)

            setEventData(res);
            setLoader(false)
        })
    }

    // const getData = () => {
    //     setLoader(true);
    //     Api.get("/events/AllEvents").send((res, err) => {
    //         console.log("AllEvents", res, err);
    //         setEventData(res);
    //         setLoader(false);

    //         const hasEmptyGallery = res.some((event) => event.galleryImages.length === 0);
    //         if (hasEmptyGallery) {
    //             console.log("No images", hasEmptyGallery);
    //             Notification.error("Please upload atleast one image")
    //             setEdit(true)
    //         }
    //     });
    // };

    const itemspop = (record) => (
        <Menu>
            <Menu.Item key="1">

                <div className="editBtn" onClick={() => { setEditData(record); setEdit(true); }}><EditOutlined />Edit</div>

            </Menu.Item>
            <Menu.Item key="2">
                <div className="deleteBtn" onClick={() => { setDeleteData(record); setDeleteModal(true); }}>
                    <img src={trashnew} style={{ width: '15px', height: '15px' }} /> Delete</div>
            </Menu.Item>
        </Menu>
    )


    useEffect(() => {
        getData()
    }, [toggle])

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [publish, setPublish] = useState(false);

    const handlePreview = (file) => {
        setPreviewImage(file.url || file.thumbUrl);
        setPreviewVisible(true);
    };

    const handleCancelPreview = () => {
        setPreviewVisible(false);
    };
    const props = {
        // action: '//jsonplaceholder.typicode.com/posts/',
        listType: 'picture',
        previewFile(file) {
            console.log('Your upload file:', file);
            return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
                method: 'POST',
                body: file,
            })
                .then(res => res.json())
                .then(({ thumbnail }) => thumbnail);
        },
    };

    // const handleUpload = ({ file, fileList }) => {
    //     console.log("No more", fileList)
    //     // Limit the number of files to 6
    //     if (fileList.length > 6) {
    //         console.log("No more", fileList)
    //         Notification.error('You can only upload up to 6 files.');
    //         return Upload.LIST_IGNORE;
    //     }
    // };

    const deleteHandler = (val) => {
        console.log("deleteHandler", val, deleteData)
        let id = deleteData?.id;
        Api.delete(`/events/deleteEvent?id=${id}`).send((res) => {
            // console.log(res);
            setToggle(!toggle)
            setDeleteModal(false);
        })

    }


    // const submitHandler = (val) => {
    //     setModal(false);

    //     if (val?.title.trim().length === 0) {
    //         Notification.error("error", "Please give a title");
    //         setModal(true);
    //         return;
    //     }

    //     if (val?.description.trim().length === 0) {
    //         Notification.error("error", "Please give a description");
    //         setModal(true);
    //         return;
    //     }

    //     if (val?.thumbnail?.fileList?.length === 0) {
    //         Notification.error("error", "Please upload a thumbnail image");
    //         setModal(true);
    //         return;
    //     }

    //     let type = val?.thumbnail?.fileList[0]?.type;

    //     if (val?.files?.fileList?.length > 6) {
    //         Notification.error("error", "You can upload only up to 6 images");
    //         setModal(true);
    //         return; // Add a return statement here to stop execution if there are too many images
    //     }

    //     let flag = true;
    //     val?.files?.fileList.forEach((val) => {
    //         if (!(val?.type === "image/jpeg" || val?.type === "image/png")) {
    //             flag = false;
    //             return;
    //         }
    //     });

    //     if (!flag) {
    //         setModal(true);
    //         return;
    //     }
    //     console.log("outside submit", flag)
    //     let files = [];
    //     val?.files?.fileList.forEach((val) => {
    //         return files.push(val?.originFileObj);
    //     }
    //     )
    //     let data = {
    //         ...val,
    //         thumbnail: val?.thumbnail?.file?.originFileObj,
    //         files: files

    //     }

    //     Api.post("/events/saveEvent").upload(data, (percentComplete, response) => {
    //         if (percentComplete === 100 && response !== undefined) {
    //             console.log("responseeeee1", response?.data?.show);
    //             if (response?.data?.show?.type === "error") {
    //                 console.log("responseeeee", response?.data?.show?.type);
    //                 setModal(true);
    //             } else {
    //                 setToggle(!toggle);
    //                 setModal(false);
    //             }
    //         }
    //     });
    // };


    const submitHandler = (val) => {
        // setModal(false);
        if (val?.title.trim().length === 0) {
            Notification.error("error", "please give a title ")
            setModal(true)
            return;
        }
        if (val?.description.trim().length === 0) {
            Notification.error("error", "please give a description ")
            setModal(true)
            return;
        }
        if (val?.thumbnail?.fileList?.length === 0) {
            Notification.error("error", "please upload thumbnail image")
            setModal(true)
            return;
        }
        let type = val?.thumbnail?.fileList[0]?.type;
        if (!(type === "image/jpeg" || type === "image/png")) {
            Notification.error("error", "please upload a jpg/png file  ");
            setModal(true)
            return;
        }
        if (val?.files?.fileList?.length > 6) {
            Notification.error("error", "You can upload only upto 6 images")
            setModal(true)
        }
        let flag = true;
        val?.files?.fileList.forEach((val) => {
            if (!(val?.type === "image/jpeg" || val?.type === "image/png")) {
                flag = false
                return;
            }

        })
        if (!flag) {
            // Notification.error("error", "please upload a jpg/png file  ");
            setModal(true);
            return;
        }
        console.log("outside submit", flag)
        let files = [];
        val?.files?.fileList.forEach((val) => {
            return files.push(val?.originFileObj);
        }
        )
        let data = {
            ...val,
            thumbnail: val?.thumbnail?.file?.originFileObj,
            files: files

        }
        // Api.post("/events/saveEvent").upload(data, (percentComplete, response) => {
        //     if (percentComplete === 100 && response !== undefined) {
        //         console.log("responseeeee1", response?.data?.show);
        //         if (response?.data?.show?.type === "error") {
        //             console.log("responseeeee", response?.data?.show?.type);
        //             Notification.error("error", "please upload a jpg/png file  ");
        //             setModal(true)
        //         }
        //         else {
        //             setToggle(!toggle);
        //             setModal(false)
        //         }

        //     }

        // })

        Api.post("/events/saveEvent").upload(data, (percentComplete, response) => {
            if (percentComplete === 100 && response !== undefined) {
                console.log("responseeeee1", response?.data?.show);
                if (response?.data?.show?.type === "error") {
                    console.log("responseeeee", response?.data?.show?.type);
                    Notification.error("error", "please upload a jpg/png file  ");
                    setModal(true)
                }
                else {
                    setToggle(!toggle);
                    setModal(false)
                    toggleModal()
                }

            }

        })

    }
    const editHandler = (val) => {
        //add images api call
        console.log("jjjjval", val?.moreImages?.length)
        // setLoader(true);

        let editedEvent = {}
        let id = editData?.id;

        if (val?.title.trim().length === 0) {
            Notification.error("error", "please give a title ")
            setEdit(true)
            return;
        }
        if (val?.description.trim().length === 0) {
            Notification.error("error", "please give a description ")
            setEdit(true)
            return;
        }
        if (val?.thumbnailPath?.fileList?.length === 0) {
            Notification.error("error", "please upload thumbnail image")
            setEdit(true)
            return;
        }

        if (val?.title !== undefined && val?.title !== editData.title) {
            editedEvent = { ...editedEvent, title: val?.title }
        }

        if (val?.description !== undefined && val?.description !== editData.description) {
            editedEvent = { ...editedEvent, description: val?.description }
        }
        if (val?.thumbnailPath !== undefined && val?.thumbnailPath !== editData.thumbnailPath) {
            let type = val?.thumbnailPath?.fileList[0]?.type;
            if (!(type === "image/jpeg" || type === "image/png")) {
                Notification.error("error", "please upload a jpg/png file  ");
                console.log("111111")
                setEdit(true)
                return;
            }
            editedEvent = { ...editedEvent, thumbnail: val?.thumbnailPath?.file?.originFileObj, thumbNailId: editData.thumnailId }
        }

        if ((val?.moreImages?.fileList?.length + editData?.galleryImages?.length - deleteImg?.length) > 6) {
            Notification.error("error", `only ${6 - (editData?.galleryImages?.length - deleteImg?.length)} images can be uploaded`, "Total images can be 6 only")
            setEdit(true);
            return;
        }
        let flag = true
        val?.moreImages?.fileList?.forEach((val) => {
            console.log(val?.type)
            if (!(val?.type === "image/jpeg" || val?.type === "image/png")) {
                flag = false;
                return;
            }
        })
        if (!flag) {
            Notification.error("error", "please upload a jpg/png file  ");
            console.log("111111")
            setEdit(true)
            return;
        }
        let files = [];
        val?.moreImages?.fileList?.forEach((val) => {
            return files.push(val?.originFileObj);
        })

        if (Object.keys(editedEvent).length === 0) {
            if (files.length === 0 && deleteImg.length === 0) {
                Notification.success("No change", "")
                setEdit(false)
                return;
            }
        }
        else {
            if (editedEvent.thumbnail !== undefined) {
                Api.patch(`/events/updateEvent?id=${id}`).uploadAndPatch(editedEvent, (p, r) => {
                    console.log("111thumbnail1", p, r);
                })
            }
            else {
                Api.patch(`/events/updateEvent?id=${id}`).params(editedEvent).send((res) => {
                    console.log("111thumbnail2", res);
                })
            }
        }
        if (files.length !== 0) {
            let data = {};
            data = { files: files };
            Api.patch(`/gallery/uploadImages?id=${id}`).uploadAndPatch(data, (p, r) => {
                console.log("111files", p, r);
                setEdit(false)
            })

        }

        if (deleteImg.length !== 0) {
            let deleteImgIds = {
                pics: deleteImg
            }


            if (files?.length - deleteImg?.length >= 0) {
                Api.delete(`/gallery/deleteImage?id=${id}`).params(deleteImgIds).send((res) => {
                    console.log("111deletimg", res);
                    setDeleteImg([])
                    setLoader(true);
                    Api.get("/events/AllEvents").send((res, err) => {
                        console.log("AllEvents", res, err);
                        setEventData(res);
                        setLoader(false);
                    });
                })
            }
            else {
                setDeleteImg([])
                setEdit(true)
                console.log("111deletimg");
                Notification.error("Minimum 6 images are mandatory")
                setEdit(true);
                return;
            }
        }
        setToggle(!toggle);
        setEdit(false);

    }


    return (
        <div className="container">
            {width > 700 && (
                <Row className="firstRowDash">
                    <Col style={{ width: '45%', }} >
                        <Row>
                            <Col><div style={{ fontSize: "24px", fontWeight: '600', marginBottom: '10px', fontFamily: 'inherit' }}> Events</div> </Col>
                        </Row>
                    </Col>
                    <Col style={{ width: '50%', display: 'flex', justifyContent: 'end' }}>
                        <Button className="addbtn" onClick={() => { setModal(true) }}>ADD EVENTS</Button>
                    </Col>
                </Row >)}
            {width > 700 && (
                <Row className={classes.DashboardListRowfit}>
                    <Row className="HeadingRow" style={{ backgroundColor: '#F8F8F8', width: '100%', display: 'flex', position: 'sticky', top: '0', zIndex: '1', }}>
                        <Col style={{ width: '20%', marginLeft: '15px', marginRight: '5px' }} className={classes.Heading}>
                            Title
                        </Col>
                        <Col style={{ width: '32%', }} className={classes.Heading}>
                            Description
                        </Col>
                        <Col style={{ width: '28%', }} className={classes.Heading}>
                            Images
                        </Col>
                        <Col style={{ width: '10%', }} className={classes.Heading}>Publish/Unpublish</Col>
                    </Row>
                    {loader ? (
                        <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (

                        <Row style={{ width: '100%', display: 'flex' }}>
                            {eventData?.map((data, index) => {
                                const isPublished = data?.publish;
                                console.log("record", data);
                                let dashobj = {};
                                const handleClick = () => {
                                    setEditData(data);
                                    const newPublishStatus = !isPublished;
                                    setPublish(newPublishStatus);
                                    { console.log("jjjj", data) }
                                    Api.patch(`/events/updateEvent?id=${data?.id}`).params({ publish: newPublishStatus }).send((res) => {
                                        console.log("0000res", res);
                                        getData()
                                    })
                                    getData();
                                };
                                return (
                                    <div className={classes.DashboardMapDiv} key={index}>
                                        <Row style={{ width: '16vw', height: '100%', display: 'flex', alignItems: 'center', gap: '6px', }} >
                                            <Col className={classes.ImageDivEvents}>
                                                <img
                                                    src={data?.thumbnailPath}
                                                    style={{ width: '100%', height: '100%', borderRadius: '5px' }}
                                                    alt="Profile"
                                                />
                                            </Col>
                                            <Col className={classes.contentDivEvent}>
                                                <Typography.Text ellipsis={{ tooltip: true }} style={{ fontFamily: 'inherit', fontWeight: '600', maxWidth: "90%", }}>{data?.title}</Typography.Text>
                                            </Col>
                                        </Row>
                                        <Col style={{ width: '32%', marginLeft: '10px', display: 'flex', marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                                            <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "90%", fontWeight: '500' }}>  {data?.description}</Typography.Text>
                                        </Col>
                                        <Row style={{ display: 'flex', width: '22%', gap: '3px', objectFit: 'cover' }}>
                                            {data?.galleryImages?.map((val, index) => {
                                                if (index < 3) {
                                                    return (
                                                        <img
                                                            src={val?.locationPath}
                                                            alt=""
                                                            style={{ width: "47px", height: '47px', borderRadius: "5px", marginRight: '5px' }}
                                                            key={index}
                                                        />
                                                    );
                                                } else if (index === 3 && data?.galleryImages?.length > 3) {
                                                    const remainingImages = data?.galleryImages?.length - 4;
                                                    return (
                                                        <div
                                                            style={{
                                                                position: "relative",
                                                                display: "inline-block",
                                                                width: "47px",
                                                                height: "47px",
                                                            }}
                                                            key={index}
                                                        >
                                                            <img src={val?.locationPath} alt="" style={{ width: "47px", height: '47px', borderRadius: "5px", filter: 'brightness(45%)' }} />
                                                            {remainingImages > 0 && (
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        bottom: "10px",
                                                                        right: "10px",
                                                                        backgroundColor: "#000000",
                                                                        color: "#FFFFFF",
                                                                        borderRadius: "5px",
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    +{remainingImages}
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </Row>
                                        <Col style={{ width: '10%', display: 'flex', gap: "5px", alignItems: 'center', marginLeft: '5vw' }}>
                                            {isPublished ? (
                                                <div
                                                    style={{
                                                        padding: '14px',
                                                        width: '7vw',
                                                        height: '5vh',
                                                        cursor: 'pointer',
                                                        background: '#0086FF',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        borderRadius: '4px',
                                                        opacity: '1',
                                                        color: 'white',
                                                    }}
                                                    onClick={() => handleClick()}
                                                >
                                                    UNPUBLISH
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        border: '1px solid #0086FF',
                                                        paddingLeft: '25px',
                                                        paddingRight: '25px',
                                                        width: '90%',
                                                        height: '5vh',
                                                        cursor: 'pointer',
                                                        background: 'white',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        borderRadius: '4px',
                                                        opacity: '1',
                                                        color: '#0086FF',
                                                    }}
                                                    onClick={() => handleClick()}
                                                >
                                                    PUBLISH
                                                </div>
                                            )}
                                            <Dropdown overlay={itemspop(data)} trigger={['click']}>
                                                <div style={{ color: '#707070' }}>
                                                    <img
                                                        src={moreVertical}
                                                        style={{ width: '15px', height: '15px', cursor: 'pointer', color: '#707070' }}
                                                        alt="More Vertical"
                                                    />
                                                </div>
                                            </Dropdown>
                                        </Col>
                                    </div >
                                );
                            })}
                        </Row>)}
                </Row >)}

            {width < 700 && (
                <Row style={{ width: '100%', gap: '10px', display: 'flex', alignItems: 'center', borderBottom: '1px solid #DBDBDB', justifyContent: 'space-between', marginTop: '5px', paddingBottom: '5px' }} >
                    <div style={{ fontSize: "20px", fontWeight: '500', fontFamily: 'poppins' }}> Events</div>
                    <Button className="addnewbtndash" onClick={toggleModal}> ADD EVENTS</Button>
                </Row>
            )}

            {width < 700 && (
                <Row className={classes.DashboardListRow}>
                    {loader ? (
                        <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (
                        <Row style={{ width: '100%', display: 'flex', height: '80vh', overflowY: 'scroll' }}>
                            {eventData?.map((data, index) => {
                                const isPublished = data?.publish;
                                console.log("record", data);
                                let dashobj = {};
                                const handleClick = () => {
                                    setEditData(data);
                                    const newPublishStatus = !isPublished;
                                    setPublish(newPublishStatus);
                                    { console.log("jjjj", data) }
                                    Api.patch(`/events/updateEvent?id=${data?.id}`).params({ publish: newPublishStatus }).send((res) => {
                                        console.log("0000res", res);
                                        getData()
                                    })
                                    getData();
                                };
                                return (
                                    <div className={classes.DashboardDivMobile} key={index}>
                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '15px', height: '45%', marginBottom: '5px' }}>
                                            <Col style={{ width: '20%', height: '100%' }}>
                                                <img src={data?.thumbnailPath} style={{ width: '60px', height: '60px', borderRadius: '5px' }} alt="Profile" />
                                            </Col>
                                            <Col style={{ width: '80%', height: '100%' }}>
                                                <Typography.Text ellipsis={{ tooltip: true }} style={{ fontFamily: 'poppins', fontWeight: '600', maxWidth: "90%", }}>{data?.title}</Typography.Text>
                                                <div style={{ display: 'flex', color: '#7D7D7D', fontFamily: 'poppins', fontWeight: '500', fontSize: '12px' }}>
                                                    Images:  {data?.galleryImages?.length}
                                                </div>
                                            </Col>
                                        </div>
                                        <div style={{ height: '35%' }}>
                                            <Paragraph style={{ fontWeight: '500', fontFamily: 'poppins', fontSize: '12px' }} ellipsis={true ? { rows: 2, symbol: '...', fontSize: 16, } : false}>{data?.description}</Paragraph>
                                        </div>
                                        <div style={{ width: '100%', height: '19%', display: 'flex', }} >
                                            <Col style={{ width: '85%', display: 'flex', justifyContent: 'space-between' }}>
                                                {isPublished ? (
                                                    <div style={{ height: '100%', border: '1px solid #0086FF', padding: '4vw 5vw', width: '40%', cursor: 'pointer', background: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: '#0086FF', }} onClick={() => handleClick()}> UNPUBLISH
                                                    </div>
                                                ) : (
                                                    <div style={{ height: '100%', padding: '4vw 5vw', width: '40%', cursor: 'pointer', background: '#0086FF', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px', opacity: '1', color: 'white', }} onClick={() => handleClick()}
                                                    > PUBLISH
                                                    </div>
                                                )}
                                            </Col>
                                            <Col style={{ width: '12%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} >
                                                <Dropdown overlay={itemspop(data)} trigger={['click']}>
                                                    <div style={{ color: '#707070', display: "flex", flexShrink: 0 }}> <img src={moreVertical} style={{ width: '20px', height: '20px', cursor: 'pointer', color: '#707070' }} alt="More Vertical" />
                                                    </div>
                                                </Dropdown>
                                            </Col>

                                        </div>
                                    </div >
                                );
                            })}
                        </Row>)}
                    {/* <Col style={{ width: '100vw', display: 'flex', justifyContent: 'center', height: '10%', }}>
                        <Button className="addnewbtn" onClick={toggleModal}><img style={{ width: '12px', height: '12px', marginRight: '8px' }} src={addIcon} /> ADD EVENTS</Button>
                    </Col> */}
                </Row >)}
            {width > 700 && <Modal visible={modal} onCancel={() => { setModal(false); }} footer={false} key={Math.random()} bodyStyle={{ height: '80vh', }}>
                <div className="headline">Add Event</div>
                <Form style={{ overflowY: 'scroll', height: '64vh', padding: '7px', }} layout='vertical' onFinish={submitHandler} >
                    <Form.Item label="Title" name="title" rules={[{ required: true, message: "Please enter title", },]}>
                        <Input placeholder="enter title "></Input>
                    </Form.Item>
                    <Form.Item label="Description" name="description" rules={[{ required: true, message: "Please enter description ", },]}>
                        <Input.TextArea label="enter description" rows={4}></Input.TextArea>
                    </Form.Item>

                    <Form.Item label="Thumbnail Images" name="thumbnail" rules={[{ required: true, message: "Please select an image ", },]}>
                        <Upload
                            accept=".jpg, .png"
                            action="https://testassociate.brigosha.com"
                            // {...props}
                            maxCount={1}
                            listType="picture"
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item name="files" label="You can upload only upto 6 images" rules={[{ required: true, message: 'Please upload an Image', },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            const fileList = getFieldValue('files');
                            if (fileList?.fileList?.length < 6) {
                                return Promise.reject(new Error('Please upload a minimum of 6 images'));
                            }
                            else if (fileList?.fileList?.length > 6) {
                                return Promise.reject(new Error('Only 6 images are allowed at a time. You have added' + fileList?.fileList?.length + ' images now',));
                            }
                            return Promise.resolve();
                        },
                    }),
                    ]} >
                        <Upload
                            action="https://testassociate.brigosha.com"
                            accept="image/jpeg,image/png"
                            multiple={true}
                            maxCount={7}
                            listType="picture"
                        // {...props}
                        >
                            <Button icon={<PlusOutlined />}>Add Images</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '-7px', }}>
                        <Button style={{ justifyContent: 'center', width: '25%', display: 'flex', alignItems: 'center', backgroundColor: '#0086FF', font: 'normal normal bold 14px/18px Open Sans', color: 'white', borderRadius: '5px', height: '2vh', cursor: 'pointer', padding: '18px', }} block htmlType="submit"  >Add</Button>
                    </Form.Item>
                </Form>
            </Modal >}
            {showModal && width < 700 && (
                <div className="custom-modal-container" style={{ padding: '0px 20px 0px 20px', width: '90%', height: '70vh', zIndex: '1000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', justifyContent: 'center', alignItems: 'center', background: 'white', display: 'flex', boxShadow: 'rgb(22 23 23 / 77%) 5px 0px 48px 137px', borderRadius: '4px' }}>
                    <div className="modal-content" style={{ width: '100%', padding: '18px 0px 0px 0px' }}>
                        <div style={{ borderBottom: ' 1px solid #CCCCCC', marginTop: '-15px', marginBottom: '12px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ fontFamily: 'poppins', fontSize: '18px', fontWeight: '600', }}>Add Event</div>
                            <img src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={toggleModal} />
                        </div>
                        <div className="modal-body" style={{ overflow: 'auto', maxHeight: 'calc((70vh - 15px) - 35px)' }}>
                            <Form layout='vertical' onFinish={submitHandler}>
                                <Form.Item label="Title" name="title" rules={[{ required: true, message: "Please enter title" }]}>
                                    <Input placeholder="Enter title" />
                                </Form.Item>
                                <Form.Item label="Description" name="description" rules={[{ required: true, message: "Please enter description" }]}>
                                    <Input.TextArea label="Enter description" rows={4} />
                                </Form.Item>

                                <Form.Item label="Thumbnail Images" name="thumbnail" rules={[{ required: true, message: "Please select an image" }]}>
                                    <Upload
                                        accept=".jpg, .png"
                                        action="https://testassociate.brigosha.com"
                                        maxCount={1}
                                        listType="picture"
                                    >
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </Form.Item>
                                <Form.Item name="files" label="You can upload only upto 6 images"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please upload an Image',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                const fileList = getFieldValue('files');
                                                if (fileList?.fileList?.length < 6) {
                                                    return Promise.reject(new Error('Please upload a minimum of 6 images'));
                                                }
                                                else if (fileList?.fileList?.length > 6) {
                                                    return Promise.reject(new Error('Only 6 images are allowed at a time. You have added ' + fileList?.fileList?.length + ' images now'));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}>
                                    <Upload
                                        action="https://testassociate.brigosha.com"
                                        accept="image/jpeg,image/png"
                                        multiple={true}
                                        maxCount={7}
                                        listType="picture"
                                    >
                                        <Button icon={<PlusOutlined />}>Add Images</Button>
                                    </Upload>
                                </Form.Item>
                                <Form.Item style={{ height: '36px' }}>
                                    <Button block htmlType="submit" style={{ padding: '2px', marginBottom: '10px', backgroundColor: '#0086FF', color: 'white', borderRadius: '3px', width: '30vw', marginRight: '5px' }}>Add</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            )}

            {width > 700 && < Modal visible={edit} onCancel={() => { setEdit(false); setEditData({}); }} footer={false} key={Math.random()} bodyStyle={{ height: '90vh', }}>
                <div className="headline">Edit Events</div>
                <Form style={{ overflowY: 'scroll', height: '92%', padding: '7px' }} layout='vertical' onFinish={editHandler} id="editForm" initialValues={editData}>
                    <Form.Item label="Title" name="title" rules={[{ required: true, message: "Please enter title", },]}>
                        <Input placeholder="enter title" ></Input>
                    </Form.Item>
                    <Form.Item label="Description" name="description" rules={[{ required: true, message: "Please enter description", },]}>
                        <Input.TextArea label="enter description" rows={2} ></Input.TextArea>
                    </Form.Item>
                    <Form.Item label="Thumbnail Image" name="thumbnailPath" rules={[{ required: true, message: "Please upload an image ", },]}>
                        <Upload
                            maxCount={1}
                            accept=".jpg, .png"
                            action="https://testassociate.brigosha.com"
                            listType="picture"
                            defaultFileList={[{ id: editData.id, url: editData.thumbnailPath }]}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item label="Images (Do not leave this field empty)" >
                        <div style={{ 'display': 'flex', flexWrap: 'wrap', gap: '20px', 'justifyContent': 'flex-start' }}>
                            {
                                editData?.galleryImages?.map((img, key) => {
                                    return deleteImg.includes(img.id) ? null : <div key={key} style={{ 'position': 'relative' }}>
                                        <img src={img.locationPath} alt="" width={40} height={50}>
                                        </img>
                                        <div id="deleteImgModelBtn" key={1} onClick={(e) => { deleteImg.push(img.id); e.target.parentNode.style.display = 'none' }} style={{ 'outline': 'none', 'background': 'none', 'border': 'none', 'position': 'absolute', 'top': '-10px', 'left': '33px', 'cursor': 'pointer', 'fontWeight': 600 }}>X</div>
                                    </div>
                                })
                            }
                        </div>
                    </Form.Item>
                    <Form.Item name="moreImages" initialValue={editData?.galleryImages}>
                        <Upload
                            multiple={true}
                            action="https://testassociate.brigosha.com"
                            listType="picture"
                            accept=".jpg, .png"
                        >
                            <u style={{ 'cursor': 'pointer' }}>Add More Images +</u>
                        </Upload>
                    </Form.Item>
                    {width > 700 &&
                        <Form.Item style={{ width: '22vw', display: 'flex', marginBottom: '1px' }}>
                            <Button block htmlType="submit" style={{ marginBottom: '10px', width: '9vw', backgroundColor: '#0086FF', color: 'white', borderRadius: '5px', width: 'fit-content', marginRight: '5px' }}> Save Changes</Button>
                            <Button style={{ width: 'fit-content' }} block key={3} onClick={() => {
                                setEdit(false);
                                document.getElementById('editForm').reset(); setToggle(!toggle); setDeleteImg([]);
                            }} >Discard</Button>
                        </Form.Item>}
                    {width <= 700 &&
                        <Form.Item style={{ width: '55vw', display: 'flex', marginBottom: '1px' }}>
                            <Button block htmlType="submit" style={{ padding: '2px', marginBottom: '10px', backgroundColor: '#0086FF', color: 'white', borderRadius: '3px', width: '30vw', marginRight: '5px' }}> Save Changes</Button>
                            <Button style={{ width: '20vw', padding: '2px', }} block key={3} onClick={() => {
                                setEdit(false);
                                document.getElementById('editForm').reset(); setToggle(!toggle); setDeleteImg([]);
                            }} >Discard</Button>
                        </Form.Item>}
                </Form>
            </Modal >}

            {width < 700 && edit && (
                <div className="custom-modal-container" style={{ padding: '0px 20px 0px 20px', width: '90%', height: '80vh', zIndex: '1000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', justifyContent: 'center', alignItems: 'center', background: 'white', display: 'flex', boxShadow: 'rgb(22 23 23 / 77%) 5px 0px 48px 137px', borderRadius: '4px' }}>
                    <div className="modal-content" style={{ width: '100%' }}>
                        <div style={{ borderBottom: ' 1px solid #CCCCCC', marginTop: '-15px', marginBottom: '12px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ fontFamily: 'poppins', fontSize: '18px', fontWeight: '600', }}>Edit Events</div>
                            <img src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={() => { setEdit(false); setEditData({}); }} />
                        </div>
                        <div className="modal-body" style={{ overflow: 'auto', maxHeight: 'calc(80vh - 2 * 20px - 45px)' }}>
                            <Form layout='vertical' onFinish={editHandler} id="editForm" initialValues={editData}>
                                <Form.Item label="Title" name="title" rules={[{ required: true, message: "Please enter title" }]}>
                                    <Input placeholder="Enter title" />
                                </Form.Item>
                                <Form.Item label="Description" name="description" rules={[{ required: true, message: "Please enter description" }]}>
                                    <Input.TextArea label="Enter description" rows={2} />
                                </Form.Item>
                                <Form.Item label="Thumbnail Image" name="thumbnailPath" rules={[{ required: true, message: "Please upload an image" }]}>
                                    <Upload
                                        maxCount={1}
                                        accept=".jpg, .png"
                                        action="https://testassociate.brigosha.com"
                                        listType="picture"
                                        defaultFileList={[{ id: editData.id, url: editData.thumbnailPath }]}
                                    >
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </Form.Item>
                                <Form.Item label="Images (Do not leave this field empty)">
                                    <div style={{ 'display': 'flex', flexWrap: 'wrap', gap: '20px', 'justifyContent': 'flex-start' }}>
                                        {editData?.galleryImages?.map((img, key) => {
                                            return deleteImg.includes(img.id) ? null : (
                                                <div key={key} style={{ 'position': 'relative' }}>
                                                    <img src={img.locationPath} alt="" width={40} height={50} />
                                                    <div
                                                        id="deleteImgModelBtn"
                                                        key={1}
                                                        onClick={(e) => {
                                                            deleteImg.push(img.id);
                                                            e.target.parentNode.style.display = 'none';
                                                        }}
                                                        style={{ 'outline': 'none', 'background': 'none', 'border': 'none', 'position': 'absolute', 'top': '-10px', 'left': '33px', 'cursor': 'pointer', 'fontWeight': 600 }}
                                                    >
                                                        X
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Form.Item>
                                <Form.Item name="moreImages" initialValue={editData?.galleryImages}>
                                    <Upload
                                        multiple={true}
                                        action="https://testassociate.brigosha.com"
                                        listType="picture"
                                        accept=".jpg, .png"
                                    >
                                        <u style={{ 'cursor': 'pointer' }}>Add More Images +</u>
                                    </Upload>
                                </Form.Item>
                                <Form.Item style={{ width: '55vw', display: 'flex', marginBottom: '1px' }}>
                                    <Button block htmlType="submit" style={{ padding: '2px', marginBottom: '10px', backgroundColor: '#0086FF', color: 'white', borderRadius: '3px', width: '30vw', marginRight: '5px' }}>
                                        Save Changes
                                    </Button>
                                    <Button style={{ width: '20vw', padding: '2px' }} block key={3} onClick={() => {
                                        setEdit(false);
                                        document.getElementById('editForm').reset();
                                        setToggle(!toggle);
                                        setDeleteImg([]);
                                    }}>
                                        Discard
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            )}
            <Modal visible={deleteModal} onCancel={() => { setDeleteModal(false) }} closable={false} footer={false} key={Math.random()} bodyStyle={{ width: window.innerWidth < 350 ? `${window.innerWidth - 20}px` : window.innerWidth < 700 ? 'fit-content' : '600px', backgroundColor: 'white', borderRadius: '5px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}><CloseOutlined size="large" onClick={() => { setDeleteModal(false) }} style={{ font: 20, cursor: "pointer", fontSize: 16, opacity: '0.7' }} /></div>
                <div style={{ 'display': 'flex', 'flexDirection': 'column', 'gap': '7px', }}>
                    <div className="modelTitle">Are you Sure ?</div>
                    <div className="modelInfo">Do you really want to delete the Event? This process cannot be undone </div>
                    <div className="modelInfo"> <b>All Images from the Gallery Page will also get deleted permanently.</b></div>

                    <div className="modelBtn">
                        <Button className="modelCancelBtn" onClick={() => { setDeleteModal(false) }}>Cancel</Button>
                        <Button onClick={() => { deleteHandler() }} className='modelDeleteBtn'>Delete</Button>
                    </div>
                </div>
            </Modal>

        </div >
    )
};
export default Event;