import { Input, Table, Select, Button, Row, Spin, Pagination, Skeleton, Typography, Col } from "antd";
import SearchCompo from "component/searchCompo";
import { authActions } from "component/store";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Api from "services/Api";
import userIcon from "../../Images/userIcon.svg";
// import qs from 'qs';
import classes from '../Styles.module.css'
import { LoadingOutlined } from '@ant-design/icons';
import mail from "../../Images/mail.svg";
import phone from "../../Images/phone.svg";
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}
function BlogUsers() {
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    dispatch(authActions.updatePageHighlight(window.location.pathname))
    const [userData, setUserData] = useState([]);
    const [status, setStatus] = useState("Active");
    const [search, setSearch] = useState("");
    const [toggle, setToggle] = useState(false);
    const [loader, setLoader] = useState(false);
    const [meta, setMeta] = useState();
    const [filterdata, setFilterData] = useState()

    const [activePagination, setActivePagination] = useState({
        total: meta,
        current: 1,
        pageSize: 10,
    });

    const [inactivePagination, setInactivePagination] = useState({
        total: meta,
        current: 1,
        pageSize: 10,
    });

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    // Update activePagination when meta changes for Active Users
    useEffect(() => {
        setActivePagination(prevPagination => ({
            ...prevPagination,
            total: meta
        }));
    }, [meta]);

    // Update inactivePagination when filterdata changes for Inactive Users
    useEffect(() => {
        setLoader(true)
        setInactivePagination(prevPagination => ({
            ...prevPagination,
            total: filterdata?.length
        }));
    }, [filterdata]);
    // const [tableParams, setTableParams] = useState({
    //     pagination: {
    //       current: 1,
    //       pageSize: 10,
    //     },
    //   });
    useEffect(() => {
        setLoader(true)
        // ?${qs.stringify(getRandomuserParams(tableParams))}
        Api.get(`/blog/activeBlogUser`).params({ status: status }).send((res) => {
            console.log("ress", res);
            // setTableParams({
            //     ...tableParams,
            //     pagination: {
            //       ...tableParams.pagination,
            //       total: res?.meta?.totalItems,  
            //     },
            //   });
            setUserData(res?.user);
            setMeta(res?.user?.length);
            setLoader(false)
        })
    }, [status, toggle,])
    // JSON.stringify(tableParams)
    useEffect(() => {
        console.log(userData)
        setLoader(true)
        let res = userData?.filter((item) => {
            console.log("jjjfilternew", item)
            return item?.name?.toLowerCase()?.includes(search.toLowerCase());
        })
        setMeta(res?.length);
        setFilterData(res);
        console.log("jjjfilterednew", filterdata)
        setTimeout(() => { setLoader(false) }, 300)

    }, [search, userData])

    // const getRandomuserParams = (params) => ({
    //     limit: params.pagination?.pageSize,
    //     page: params.pagination?.current,

    //   });
    // const handleTableChange = (pagination, ) => {
    //     setTableParams({
    //       pagination,         
    //     });
    //   };

    const dummy = [
        {
            ass: "nishant", email: "@.f.com", phone: "374598475", img: "https://ibelong-library.s3.ap-south-1.amazonaws.com/profileImage/Dileep_Kumar_Buruga-d0c15219-53c8-4f62-82af-d23774789ead.jpg", role: "Associate", platform: "Brigosha", privilege: "blogger"
        }
    ]
    const userColumn = [
        {
            title: () => <b style={{ color: 'black' }}>Associate</b>,
            dataIndex: 'Associate',
            key: 'Associate',
            width: '16vw',
            fontFamily: "inherit",
            render: (text, record) => {
                return (
                    <Row style={{ width: '16vw', display: "flex", flexDirection: "row", gap: "8px", alignItems: "center", marginRight: '10px' }}>
                        <Col> <img src={userIcon} alt="img" style={{ width: "3vw", height: "3vh", borderRadius: '50%' }}></img></Col>
                        <Col>
                            <div>{record?.name}</div>
                            <div>{record?.designation}</div>
                        </Col>
                    </Row>
                )
            }
        },
        {
            title: () => <b style={{ color: 'black' }}>Contact Details</b>,
            dataIndex: 'Contact Details',
            key: 'Contact Details',
            width: '26vw',
            fontFamily: "inherit",
            render: (text, record) => {
                return (
                    <Row style={{ width: '26vw', }}>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '12vw', }}>{record?.email}</Typography.Text>
                        <div>{record?.phone}</div>
                    </Row>
                )
            }
        },

        {
            title: () => <b style={{ color: 'black' }}>Platform</b>,
            dataIndex: 'Platform',
            key: 'Platform',
            width: '16vw',
            fontFamily: "inherit",
            render: (text, record) => {
                return (
                    <Row style={{ width: '16vw', marginRight: '5px' }}>
                        {record?.platform}
                    </Row>
                )
            }
        },
        {
            title: () => <b style={{ color: 'black' }}>Privileges</b>,
            dataIndex: 'Privileges',
            key: 'Privileges',
            width: '20vw',
            fontFamily: "inherit",
            render: (text, record) => {
                return (
                    <Select style={{ width: '10vw', }} value={record?.privilege} onChange={(val) => {
                        console.log("on change", val);
                        Api.patch("/blog/updatePrivilege").params({ email: record?.email, name: record?.name, privilege: val }).send((res) => {
                            console.log("resss", res);
                            setToggle(!toggle);
                        })
                    }} >
                        <Select.Option value="Blogger">Blogger</Select.Option>
                        <Select.Option value="Admin">Admin</Select.Option>
                    </Select>
                )
            }
        },

    ]

    return (
        <>
            {width > 700 && (
                <>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }} className="container">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ font: "normal normal bold 24px/33px Open Sans", fontFamily: "inherit" }}> Users</div>
                            {/* <div style={{ width: '20vw' }}><SearchCompo setSearch={setSearch} ></SearchCompo></div> */}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: "16px", font: "normal normal 600 18px/24px Open Sans", marginBottom: '5px', width: '100%', }}>
                                <div style={{ color: status === "Active" ? "#0086FF" : "#7E7E7E", borderBottom: status === "Active" ? "2px solid #0086FF" : "", cursor: 'pointer' }} onClick={() => { setStatus("Active"); }} >
                                    Active Users
                                </div>
                                <div style={{ color: status === "Inactive" ? "#0086FF" : "#7E7E7E", borderBottom: status === "Inactive" ? "2px solid #0086FF" : "", cursor: 'pointer' }} onClick={() => { setStatus("Inactive"); }} >
                                    Inactive Users
                                </div>
                            </div>
                            <div style={{ width: '40vw', marginLeft: '39vw', }}><SearchCompo setSearch={setSearch} ></SearchCompo></div>
                        </div>
                        {status === "Active" ? (
                            <Row style={{ display: 'flex', width: '100%', gap: '12px', maxHeight: '65vh', overflow: 'scroll' }}>
                                <Row className="HeadingRow" style={{ backgroundColor: '#F8F8F8', width: '100%', display: 'flex', position: 'sticky', top: '0', zIndex: '1', }}>
                                    <Col style={{ width: '27%', display: 'flex', marginLeft: '15px' }} className={classes.Heading}>
                                        Associate
                                    </Col>
                                    <Col style={{ width: '30%', display: 'flex', marginLeft: '6px' }} className={classes.Heading}>
                                        Contact Details
                                    </Col>
                                    <Col style={{ width: '17%', display: 'flex', justifyContent: 'center', marginLeft: '6px' }} className={classes.Heading}>Platform</Col>
                                    <Col style={{ width: '15%', display: 'flex', justifyContent: 'center', marginLeft: '6px' }} className={classes.Heading}>Privileges</Col>
                                </Row>
                                {loader ? (
                                    <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (
                                    <Row style={{ width: '100%', display: 'flex' }}>
                                        {filterdata?.map((data, index) => {
                                            return (
                                                <div className={classes.DashboardMapDiv} key={index}>
                                                    <Row style={{ width: '28%', height: '100%', display: 'flex', alignItems: 'center', gap: '6px', marginRight: '8px', }} >
                                                        <Col> <img src={userIcon} alt="img" style={{ width: "3vw", height: "3vh", borderRadius: '50%' }}></img></Col>
                                                        <Col>
                                                            <div style={{ fontFamily: 'inherit', fontWeight: '500', }}>{data?.name}</div>
                                                            <div style={{ fontFamily: 'inherit', fontWeight: '500', color: '#7D7D7D', }}>{data?.designation}</div>
                                                        </Col>
                                                    </Row>
                                                    <Col style={{ width: '35%', display: 'flex', marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                                                        <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '50%', fontFamily: 'inherit', fontWeight: '500', marginRight: '8px' }}>{data?.email}</Typography.Text>
                                                        <div style={{ width: '50%', }}>{data?.phone}</div>
                                                    </Col>
                                                    <Col style={{ width: '15%', marginLeft: '10px', display: 'flex', marginRight: '20px', alignItems: 'center', fontFamily: 'inherit', fontWeight: '500', }}>
                                                        {data?.platform}
                                                    </Col>
                                                    <Select style={{ width: '15%', }} value={data?.privilege} onChange={(val) => {
                                                        console.log("on change", val);
                                                        Api.patch("/blog/updatePrivilege").params({ email: data?.email, name: data?.name, privilege: val }).send((res) => {
                                                            console.log("resss", res);
                                                            setToggle(!toggle);
                                                        })
                                                    }} >
                                                        <Select.Option style={{ fontFamily: 'inherit', fontWeight: '500', }} value="Blogger">Blogger</Select.Option>
                                                        <Select.Option style={{ fontFamily: 'inherit', fontWeight: '500', }} value="Admin">Admin</Select.Option>
                                                    </Select>
                                                </div >
                                            );
                                        })}
                                    </Row>)}
                            </Row >

                        ) : (

                            <Row style={{ display: 'flex', width: '100%', gap: '12px', maxHeight: '65vh', overflow: 'scroll' }}>
                                <Row className="HeadingRow" style={{ backgroundColor: '#F8F8F8', width: '100%', display: 'flex', position: 'sticky', top: '0', zIndex: '1', }}>
                                    <Col style={{ width: '27%', display: 'flex', marginLeft: '15px' }} className={classes.Heading}>
                                        Associate
                                    </Col>
                                    <Col style={{ width: '30%', display: 'flex', marginLeft: '6px' }} className={classes.Heading}>
                                        Contact Details
                                    </Col>
                                    <Col style={{ width: '17%', display: 'flex', justifyContent: 'center', marginLeft: '6px' }} className={classes.Heading}>Platform</Col>
                                    <Col style={{ width: '15%', display: 'flex', justifyContent: 'center', marginLeft: '6px' }} className={classes.Heading}>Privileges</Col>
                                </Row>
                                {loader ? (
                                    <Row style={{ height: '60vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (
                                    <Row style={{ width: '100%', display: 'flex' }}>
                                        {filterdata?.map((data, index) => {
                                            return (
                                                <div className={classes.DashboardMapDiv} key={index}>
                                                    <Row style={{ width: '28%', height: '100%', display: 'flex', alignItems: 'center', gap: '6px', marginRight: '8px', }} >
                                                        <Col> <img src={userIcon} alt="img" style={{ width: "3vw", height: "3vh", borderRadius: '50%' }}></img></Col>
                                                        <Col>
                                                            <div style={{ fontFamily: 'inherit', fontWeight: '500', }}>{data?.name}</div>
                                                            <div style={{ fontFamily: 'inherit', fontWeight: '500', color: '#7D7D7D', }}>{data?.designation}</div>
                                                        </Col>
                                                    </Row>
                                                    <Col style={{ width: '35%', display: 'flex', marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                                                        <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '50%', fontFamily: 'inherit', fontWeight: '500', marginRight: '8px' }}>{data?.email}</Typography.Text>
                                                        <div style={{ width: '50%', }}>{data?.phone}</div>
                                                    </Col>
                                                    <Col style={{ width: '15%', marginLeft: '10px', display: 'flex', marginRight: '20px', alignItems: 'center', fontFamily: 'inherit', fontWeight: '500', }}>
                                                        {data?.platform}
                                                    </Col>
                                                    <Select style={{ width: '15%', }} value={data?.privilege} onChange={(val) => {
                                                        console.log("on change", val);
                                                        Api.patch("/blog/updatePrivilege").params({ email: data?.email, name: data?.name, privilege: val }).send((res) => {
                                                            console.log("resss", res);
                                                            setToggle(!toggle);
                                                        })
                                                    }} >
                                                        <Select.Option style={{ fontFamily: 'inherit', fontWeight: '500', }} value="Blogger">Blogger</Select.Option>
                                                        <Select.Option style={{ fontFamily: 'inherit', fontWeight: '500', }} value="Admin">Admin</Select.Option>
                                                    </Select>
                                                </div >
                                            );
                                        })}
                                    </Row>)}
                            </Row >

                        )}

                    </div>
                </>
            )}

            {width < 700 && (
                <>
                    <div className="containerusers">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ fontSize: '18px', fontFamily: "poppins", fontWeight: '600' }}> Users</div>
                        </div>
                        <div style={{ marginBottom: '6px', display: 'flex', justifyContent: 'flex-end', }}><SearchCompo style={{ width: '50%' }} setSearch={setSearch} ></SearchCompo></div>
                        <div style={{ display: "flex", flexDirection: "row", gap: "16px", fontSize: "14px", fontFamily: 'poppins', fontWeight: '600', marginBottom: '10px', width: '100%', }}>
                            <div style={{ color: status === "Active" ? "#0086FF" : "#7E7E7E", borderBottom: status === "Active" ? "2px solid #0086FF" : "", cursor: 'pointer' }} onClick={() => { setStatus("Active"); }} >
                                Active Users
                            </div>
                            <div style={{ color: status === "Inactive" ? "#0086FF" : "#7E7E7E", borderBottom: status === "Inactive" ? "2px solid #0086FF" : "", cursor: 'pointer' }} onClick={() => { setStatus("Inactive"); }} >
                                Inactive Users
                            </div>
                        </div>
                        {loader ? (
                            <Row style={{ height: '100vh', alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}> <Spin indicator={antIcon} /></Row>) : (
                            <>
                                {status === "Active" ? (
                                    <>
                                        <Row className={classes.Blog}>
                                            <Row style={{ width: '100%', display: 'flex' }}>
                                                <Row style={{ width: '100%', display: 'flex', height: '60vh', overflowY: 'scroll' }}>
                                                    {filterdata?.map((data, index) => {
                                                        return (
                                                            <div className={classes.DashboardDivMobileBlog} key={index}>
                                                                {console.log("deatilsss", data)}
                                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '15px', height: '25%', marginBottom: '5px' }}>
                                                                    <Col style={{ width: '20%', height: '100%' }}>
                                                                        <img src={userIcon} alt="img" style={{ width: "30px", height: "30px", borderRadius: '50%' }}></img>
                                                                    </Col>
                                                                    <Col style={{ width: '80%', height: '100%', }}>
                                                                        <div style={{ fontFamily: 'poppins', fontWeight: '600', display: 'flex' }}>{data?.name}</div>
                                                                        <div style={{ fontFamily: 'poppins', fontWeight: '600', color: '#7D7D7D', display: 'flex' }}>{data?.designation}</div>
                                                                    </Col>
                                                                </div>
                                                                <div style={{ height: '30%', width: '100%' }}>
                                                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '95%', fontFamily: 'poppins', fontWeight: '500', marginRight: '10px' }}><img src={mail} style={{ width: '14px', height: '14px', marginRight: '10px', color: '#7D7D7D' }} />{data?.email}</Typography.Text>
                                                                    <div style={{ display: 'flex', fontWeight: '500', fontFamily: 'poppins', alignItems: 'center' }}><img src={phone} style={{ width: '14px', height: '14px', marginRight: '10px' }} />{data?.phone}</div>
                                                                </div>
                                                                {/* Platform :  {data?.platform} */}
                                                                <div style={{ height: '15%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px', width: '100%', marginBottom: '5px' }}>
                                                                    <div style={{ fontWeight: '500', fontFamily: 'poppins', }}>Platform : </div><div style={{ fontFamily: 'poppins', color: '#7D7D7D', fontWeight: '500' }}>{data?.platform}</div>
                                                                </div>

                                                                <Select style={{ width: '100%', height: '15%', }} value={data?.privilege} onChange={(val) => {
                                                                    console.log("on change", val);
                                                                    Api.patch("/blog/updatePrivilege").params({ email: data?.email, name: data?.name, privilege: val }).send((res) => {
                                                                        console.log("resss", res);
                                                                        setToggle(!toggle);
                                                                    })
                                                                }} >
                                                                    <Select.Option style={{ fontFamily: 'inherit', fontWeight: '500', }} value="Blogger">Blogger</Select.Option>
                                                                    <Select.Option style={{ fontFamily: 'inherit', fontWeight: '500', }} value="Admin">Admin</Select.Option>
                                                                </Select>
                                                            </div >
                                                        );
                                                    })}
                                                </Row>
                                            </Row>
                                        </Row >
                                    </>

                                ) : (
                                    <>
                                        <Row className={classes.Blog}>
                                            <Row style={{ width: '100%', display: 'flex' }}>
                                                <Row style={{ width: '100%', display: 'flex', height: '60vh', overflowY: 'scroll' }}>
                                                    {filterdata?.map((data, index) => {
                                                        return (
                                                            <div className={classes.DashboardDivMobileBlog} key={index}>
                                                                {console.log("deatilsss", data)}
                                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '15px', height: '25%', marginBottom: '5px' }}>
                                                                    <Col style={{ width: '20%', height: '100%' }}>
                                                                        <img src={userIcon} alt="img" style={{ width: "30px", height: "30px", borderRadius: '50%' }}></img>
                                                                    </Col>
                                                                    <Col style={{ width: '80%', height: '100%', }}>
                                                                        <div style={{ fontFamily: 'poppins', fontWeight: '600', display: 'flex' }}>{data?.name}</div>
                                                                        <div style={{ fontFamily: 'poppins', fontWeight: '600', color: '#7D7D7D', display: 'flex' }}>{data?.designation}</div>
                                                                    </Col>
                                                                </div>
                                                                <div style={{ height: '30%', width: '100%' }}>
                                                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '95%', fontFamily: 'poppins', fontWeight: '500', marginRight: '10px' }}><img src={mail} style={{ width: '14px', height: '14px', marginRight: '10px', color: '#7D7D7D' }} />{data?.email}</Typography.Text>
                                                                    <div style={{ display: 'flex', fontWeight: '500', fontFamily: 'poppins', alignItems: 'center' }}><img src={phone} style={{ width: '14px', height: '14px', marginRight: '10px' }} />{data?.phone}</div>
                                                                </div>
                                                                {/* Platform :  {data?.platform} */}
                                                                <div style={{ height: '15%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px', width: '100%', marginBottom: '5px' }}>
                                                                    <div style={{ fontWeight: '500', fontFamily: 'poppins', }}>Platform : </div><div style={{ fontFamily: 'poppins', color: '#7D7D7D', fontWeight: '500' }}>{data?.platform}</div>
                                                                </div>

                                                                <Select style={{ width: '100%', height: '15%', fontFamily: 'poppins', }} value={data?.privilege} onChange={(val) => {
                                                                    console.log("on change", val);
                                                                    Api.patch("/blog/updatePrivilege").params({ email: data?.email, name: data?.name, privilege: val }).send((res) => {
                                                                        console.log("resss", res);
                                                                        setToggle(!toggle);
                                                                    })
                                                                }} >
                                                                    <Select.Option style={{ fontFamily: 'inherit', fontWeight: '500', }} value="Blogger">Blogger</Select.Option>
                                                                    <Select.Option style={{ fontFamily: 'inherit', fontWeight: '500', }} value="Admin">Admin</Select.Option>
                                                                </Select>
                                                            </div >
                                                        );
                                                    })}
                                                </Row>
                                            </Row>
                                        </Row >
                                    </>
                                )}
                            </>)}
                    </div>
                </>
            )}
        </>

    )
}
export default BlogUsers;