import React, { useState, useEffect } from "react";
import classes from "./Login.module.css";
import Logo from "../../Images/Logo.svg"
import ControlImage from "../../Images/ControlImage.svg"
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}
function Sider() {
    const { height, width } = useWindowDimensions();
    return (
        <>
            {
                width > 700 && (<>
                    <div className={classes.loginBlogMain}>
                        <img src={ControlImage}></img>
                        <div style={{ fontWeight: '600' }}>Access Brigosha Website</div>
                        <div className={classes.motivatioLine}>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', color: '#0086FF', fontSize: '13px' }}>Empowering users to effortlessly manage</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', color: '#0086FF', fontSize: '13px' }}> dynamic content and witness real-time updates  </div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', color: '#0086FF', fontSize: '13px' }}>on the website updates on the website</div>
                        </div>
                        <div className={classes.poweredBy}>
                            <div>Powered by</div>
                            <div>
                                <img src={Logo}></img>
                                <div>Brigosha Technologies</div>
                            </div>
                        </div>
                    </div>
                </>)}
        </>

    )
}
export default Sider