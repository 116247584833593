import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import jwt_decode from "jwt-decode";
import { Row, Col } from "antd";
import Header from "component/Sidebar/Header"
import Sidebar from "component/Sidebar/Sidebar"
import NotFound from "component/LoginPages/NotFound";
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}
function ProtectedRoute({ children, ...rest }) {
    const { width } = useWindowDimensions();
    const { token } = useSelector(state => state.auth);
    let decoded = token ? jwt_decode(token) : null;
    console.log("tokensss", decoded)
    const history = useHistory();
    let span = window?.innerWidth < 1445 ? 4 : 3;
    let bloggerURLs = ['/brigosha-blog']
    let AdminURLs = ['/blog-users']
    console.log("loginresponse", localStorage.getItem)
    const view = (children) => {
        return <div className="parent-row12ff" >
            {/* <Col span={span}><Sidebar /></Col> */}
            {/* {decoded?.privilege !== "Blogger" && ( */}
            <Col span={span}><Sidebar /></Col>
            {/* )
            } */}
            <>

                {width > 700 && (
                    <Col span={24 - span}>
                        <Row style={{ height: '88px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '2vw', }}>
                            <Header />
                        </Row>
                        <Row className="main-container" >
                            {children}
                        </Row>
                    </Col>
                )}

                {width <= 700 ? (
                    <>
                        <Col >
                            <Row className="main-container" >
                                {children}
                            </Row>
                        </Col>
                    </>
                ) : null}
            </>
        </div >
    }

    const checkPrivilege = (privilege, path) => {
        switch (privilege) {
            case "Admin":
                return AdminURLs.includes(path) ? false : true

            case "SuperAdmin": return true;
            case "Blogger": return bloggerURLs.includes(path) ? true : false

        }
    }
    const accesRoutes = (privilege) => {
        switch (privilege) {
            case "Admin": return '/';
            case "SuperAdmin": return '/';
            case "Blogger": return '/brigosha-blog'

        }
    }

    console.log("sssTokennn", token)
    return (
        <Route
            {...rest}
            render={({ location }) => {
                console.log('location', location)
                return !token ?
                    <Redirect to={{ pathname: "/brigosha-login", state: { from: location } }} ></Redirect>
                    :
                    checkPrivilege(decoded?.privilege, location.pathname) ?
                        view(children)
                        :
                        < Redirect to={{
                            pathname: accesRoutes(decoded?.privilege), state: { from: location }
                        }
                        } ></Redirect>

            }}
        ></Route >
    )
}
export default ProtectedRoute;